import { tap } from 'rxjs/operators';
import { RESTService } from '../../common/services/rest.service';
import { Observable } from 'rxjs';
import { Config } from 'protractor';
import * as _ from 'lodash-es';
import { Resource } from '../../models/resource.model';

export class ResourceService<T extends Resource> {
  public pagination: Config = {
    links: {},
    meta: {}
  };

  constructor(
    protected rest: RESTService,
    protected entity: string
  ) {}

  /**
   *
   * @param filters
   * @param reset
   */
  all(filters?: Config, reset?: Boolean): Observable<any> {
    const self = this;
    let url = self.entity;

    if (reset) {
      self.resetPagination();
    }

    if (!_.isEmpty(self.pagination.meta) && !_.isEmpty(self.pagination.links)) {
      const next = self.pagination.meta.current_page + 1;

      if (typeof filters !== 'undefined') {
        filters.page = next;
      } else {
        filters = {
          page: next
        };
      }
    }

    if (typeof filters !== 'undefined' && filters != null) {
      for (let key in filters) {
        if (url !== self.entity) {
          url += '&';
        } else {
          url += '?';
        }
        url += key + '=' + encodeURIComponent(filters[key]);
      }
    }

    return self.rest.get(url).pipe(
      tap((response) => {
        if (typeof response.links !== 'undefined' && typeof response.meta !== 'undefined') {
          self.pagination.links = response.links;
          self.pagination.meta = response.meta;
        }
        return response.data;
      })
    );
  }

  /**
   * Returns pagination
   */
  getPagination() {
    return this.pagination;
  }

  /**
   * Resets pagination
   */
  resetPagination() {
    this.pagination = {
      links: {},
      meta: {}
    };
  }

  /**
   * GET request
   * @param id
   */
  get(id: number): Observable<any> {
    return this.rest.get(this.entity + '/' + id).pipe(
      tap((response) => {
        return response.data;
      })
    );
  }

  /**
   * POST request
   * @param params
   */
  store(params) {
    return this.rest.post(this.entity, params).pipe(
      tap((response) => {
        return response;
      })
    );
  }

  /**
   * PUT request
   * @param id
   * @param params
   */
  update(id: number, params) {
    return this.rest.put(this.entity + '/' + id, params).pipe(
      tap((response) => {
        return response;
      })
    );
  }

  /**
   * DELETE request
   * @param id
   */
  delete(id: number) {
    return this.rest.delete(this.entity + '/' + id).pipe(
      tap((response) => {
        return response;
      })
    );
  }
}
