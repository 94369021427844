import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'og-route-change-notify',
  templateUrl: './route-change-notify.component.html',
  styleUrls: ['./route-change-notify.component.scss']
})
export class RouteChangeNotifyComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
