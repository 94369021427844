<mat-drawer-container class="api-container">
    <mat-drawer mode="side" opened class="api-container__sidenav">
        <mat-list role="list">
            <ng-container *ngFor="let item of sidebar; index as i">
                <mat-list-item *ngIf="item.type === 'single'" (click)="scrollTo(item, i)">{{
                    item.label
                }}</mat-list-item>
                <mat-list-item
                    *ngIf="item.type === 'multi'"
                    role="listitem"
                    (click)="scrollTo(item, i)"
                >
                    <a matLine>{{ item.label }}</a>
                    <span>
                        <mat-icon *ngIf="!toggle[i]">expand_more</mat-icon>
                        <mat-icon *ngIf="toggle[i]">expand_less</mat-icon>
                    </span>
                </mat-list-item>
                <div *ngIf="item.type === 'multi'" [hidden]="!toggle[i]">
                    <mat-list role="sublist">
                        <mat-list-item
                            *ngFor="let subitem of item.items; index as it"
                            role="sublistitem"
                            (click)="scrollTo(subitem, it)"
                        >
                            {{ subitem.label }}
                        </mat-list-item>
                    </mat-list>
                </div>
            </ng-container>
        </mat-list>
    </mat-drawer>

    <mat-drawer-content class="api-container__content">
        <div
            *ngFor="let item of sidebar; index as i"
            class="api-container__content__block"
            [id]="slugify(item.label)"
        >
            <div
                *ngIf="item.data !== null"
                [id]="slugify(item.label)"
                [innerHTML]="item.data"
            ></div>

            <div
                *ngFor="let subitem of item.items; index as it"
                class="api-container__content__sub-block"
                [id]="slugify(subitem.label)"
                [innerHTML]="subitem.data"
            ></div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
