import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JwtHelperService } from '@auth0/angular-jwt';

const helper = new JwtHelperService();

@Component({
  selector: 'og-refresh-token',
  templateUrl: 'refresh-token.component.html'
})
export class RefreshTokenComponent {
  checker: any = null;

  constructor(
    public dialogRef: MatDialogRef<RefreshTokenComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.checker = setInterval(() => {
      if (helper.isTokenExpired(data.token)) {
        clearInterval(this.checker);
        this.checker = null;
        this.dialogRef.close(false);
      }
    });
  }
}
