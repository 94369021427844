<div class="reset-password">
    <div class="reset-password__brand"></div>
    <div class="reset-password__form">
        <mat-card class="reset-password__card">
            <mat-card-content>
                <h2 class="title">RESET PASSWORD</h2>
                <form
                    class="flex-set flex--column flex--content-center flex--align-items-center"
                    [formGroup]="passwordForm"
                    (ngSubmit)="formSubmitted(passwordForm.value)"
                >
                    <mat-form-field class="col-80">
                        <input
                            matInput
                            autocomplete="new-password"
                            formControlName="email"
                            placeholder="E-mail"
                            required
                            email
                            type="email"
                        />
                        <mat-error *ngIf="passwordForm.controls['email']?.getError('required')"
                            >Field is required</mat-error
                        >
                        <mat-error *ngIf="passwordForm.controls['email']?.getError('email')"
                            >Email must be a valid email address</mat-error
                        >
                    </mat-form-field>
                    <mat-form-field class="col-80">
                        <input
                            matInput
                            autocomplete="new-password"
                            formControlName="password"
                            placeholder="Password"
                            required
                            type="password"
                        />
                        <mat-error *ngIf="passwordForm.controls['password']?.getError('required')"
                            >Field is required</mat-error
                        >
                    </mat-form-field>
                    <mat-form-field class="col-80">
                        <input
                            matInput
                            autocomplete="new-password"
                            formControlName="passwordConfirmation"
                            required
                            placeholder="Confirm Password"
                            type="password"
                        />
                        <mat-error
                            *ngIf="
                                passwordForm.controls['passwordConfirmation']?.getError('required')
                            "
                            >Field is required.</mat-error
                        >
                        <mat-error
                            *ngIf="
                                passwordForm.controls['passwordConfirmation']?.getError('missmatch')
                            "
                            >Passwords fields must match</mat-error
                        >
                    </mat-form-field>
                    <div class="actions col-100">
                        <button type="button" (click)="goTo()" mat-raised-button color="warn">
                            Cancel
                        </button>
                        <button [disabled]="passwordForm.invalid" mat-stroked-button type="submit">
                            Set Password
                        </button>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <div></div>
</div>
