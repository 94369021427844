import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'og-eg-d-banner',
  templateUrl: './banner.component.html'
})
export class EmbedBannerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
