<div class="login">
    <div class="login__brand"></div>
    <div class="login__form">
        <mat-card class="login__card">
            <mat-card-content>
                <form class="login__form__fields" novalidate [formGroup]="form">
                    <mat-form-field *ngIf="selection === 'origin'">
                        <input
                            type="text"
                            matInput
                            placeholder="Username"
                            formControlName="username"
                        />
                    </mat-form-field>

                    <mat-form-field *ngIf="selection === 'origin'">
                        <input
                            type="password"
                            matInput
                            placeholder="Password"
                            formControlName="password"
                        />
                    </mat-form-field>

                    <p *ngIf="errors?.length" class="login__error">
                        <span *ngFor="let error of errors">{{ messagePerErrorCode[error] }}</span>
                    </p>

                    <div class="login__buttons">
                        <div class="left" *ngIf="selection === 'origin'">
                            <a (click)="openModal()">Forgot Password?</a>
                        </div>
                        <div class="right">
                            <button
                                *ngIf="selection === 'origin'"
                                mat-button
                                type="button"
                                (click)="selection = ''"
                            >
                                Back
                            </button>
                            <button
                                *ngIf="selection === 'origin'"
                                type="submit"
                                [disabled]="waiting"
                                mat-button
                                (click)="login()"
                            >
                                Login
                            </button>
                            <button
                                *ngIf="selection === ''"
                                mat-button
                                (click)="selection = 'origin'"
                                matTooltip="No Evolve Media or GN Media Users"
                                type="button"
                            >
                                Login with Origin
                            </button>
                            <a
                                *ngIf="!serving && selection === ''"
                                mat-button
                                [href]="loginUrl"
                                matTooltip="Evolve Media and GN Media Users"
                                >Login with Google</a
                            >
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <div></div>
</div>
