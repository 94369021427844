import { LoggerFactory } from 'lines-logger';
import { CONFIG } from '../config';

/**
 * Logger Service
 * @param type
 * @param tag
 */
export const logger = (type: string = 'log', tag: string = '') => {
  const _isChrome: boolean =
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  const _isFirefox: boolean = /firefox/i.test(navigator.userAgent);
  const factory = new LoggerFactory();
  factory.setLogWarnings(3);
  let color = 'blue';

  if (!CONFIG.DEBUG || (!_isChrome && !_isFirefox)) {
    factory.setLogWarnings(8);
  }

  switch (type) {
    case 'debug':
      color = 'green';
      break;
    case 'warn':
      color = 'orange';
      break;
    case 'error':
      color = 'red';
      break;
    case 'info':
      color = 'gray';
      break;
    case 'log':
    default:
      color = 'blue';
      break;
  }

  return factory.getLoggerColor(' [ORIGIN PLATFORM] ' + tag, color);
};

/**
 * [HOW TO USE IT]
 *
 * logger({COLORTYPE}, {TAG}).{LOGTYPE}({DATA})();
 *
 * {COLORTYPE}: options can be: 'debug', 'warn', 'error', 'info', 'log'
 * see colors above
 *
 * {TAG}: right now the first thing that will be logged with background color
 * is the word [ORIGIN], but you can add something else to be added next to this
 *
 * {LOGTYPE}: there are multiple ways to log: using 'log', 'error', 'debug', 'trace', 'warn'
 *
 * {DATA}: is whatever you need in there: a string, an array, an object, a string + array, etc
 * it will be like doing something like this:
 * console.log('logging');
 * let arr = [1,2,3];
 * console.log(arr);
 * console.log('logging', arr);
 *
 * Reference: https://github.com/akoidan/lines-logger
 *
 * */
