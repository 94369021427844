import { Injectable, EventEmitter } from '@angular/core';
import { RESTService } from '../../common/services/rest.service';
import { ResourceService } from './resource.service';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Ad, AdUnit } from '../../models/ad.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AdService extends ResourceService<Ad> {
  addCreated = new EventEmitter<Ad>();
  adUpdated = new EventEmitter<AdUnit>();

  constructor(
    protected rest: RESTService,
    private http: HttpClient
  ) {
    super(rest, 'ads');
  }

  /**
   * duplicates ad
   * @param adId
   * @returns
   */
  duplicate(adId: number): Observable<any> {
    return this.rest.post('ads/' + adId + '/duplicate', {}).pipe(
      tap((response) => {
        return response.data;
      })
    );
  }

  /**
   * reactivates ad
   * @param adId
   * @param params
   * @returns
   */
  reactivate(adId: number, params): Observable<any> {
    return this.rest.put('ads/' + adId + '/reactivate', params).pipe(
      tap((response) => {
        return response.data;
      })
    );
  }

  /**
   * GET request
   * @param id
   */
  getAdInfo(id: number): Observable<any> {
    return this.rest.get(this.entity + '/' + id + '/info').pipe(
      tap((response) => {
        return response.data;
      })
    );
  }

  /**
   * GET request
   * @param id
   */
  getAdSize(id: number): Observable<any> {
    return this.rest.get(this.entity + '/' + id + '/size').pipe(
      tap((response) => {
        return response.data;
      })
    );
  }
}
