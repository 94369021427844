// Display
import { PreviewBannerComponent } from '../common/formats/display/banner/preview/banner.component';
import { PreviewExpandableBannerComponent } from '../common/formats/display/expandable-banner/preview/expandable-banner.component';
import { PreviewRevealComponent } from '../common/formats/display/reveal/preview/reveal.component';
import { PreviewCollapsableBannerComponent } from '../common/formats/display/collapsable-banner/preview/collapsable-banner.component';

// Rich Media
import { PreviewBannerComponent as PreviewRMBannerComponent } from '../common/formats/richmedia/banner/preview/banner.component';
import { PreviewExpandableBannerComponent as PreviewRMExpandableBannerComponent } from '../common/formats/richmedia/expandable-banner/preview/expandable-banner.component';
import { PreviewInterscrollerComponent } from '../common/formats/richmedia/interscroller/preview/interscroller.component';
import { PreviewInterstitialComponent } from '../common/formats/richmedia/interstitial/preview/interstitial.component';
import { PreviewRevealComponent as PreviewRMRevealComponent } from '../common/formats/richmedia/reveal/preview/reveal.component';
import { PreviewUniversalBannerComponent } from '../common/formats/richmedia/universal-banner/preview/universal-banner.component';
import { PreviewUniversalExpandableComponent } from '../common/formats/richmedia/universal-expandable/preview/universal-expandable.component';
import { PreviewUniversalMiniscrollerComponent } from '../common/formats/richmedia/universal-miniscroller/preview/universal-miniscroller.component';
import { PreviewUniversalRevealComponent } from '../common/formats/richmedia/universal-reveal/preview/universal-reveal.component';
import { PreviewOvertureComponent } from '../common/formats/richmedia/overture/preview/overture.component';
import { PreviewCollapsableBannerComponent as PreviewRMCollapsableBannerComponent } from '../common/formats/richmedia/collapsable-banner/preview/collapsable-banner.component';
import { PreviewNativeBannerComponent } from '../common/formats/richmedia/native-banner/preview/native-banner.component';

// Video
import { PreviewSquareInArticleComponent } from '../common/formats/video/square-in-article/preview/square-in-article.component';
import { PreviewVerticalInArticleComponent } from '../common/formats/video/vertical-in-article/preview/vertical-in-article.component';
import { PreviewLandscapeInArticleComponent } from '../common/formats/video/landscape-in-article/preview/landscape-in-article.component';
import { PreviewVideoInterscrollerComponent } from '../common/formats/video/video-interscroller/preview/video-interscroller.component';

const config = [
  // Simulate Context Menu
  {
    name: 'Contexts',
    active: true,
    data: [
      {
        name: 'Schedules',
        active: true,
        disabled: false
      },
      {
        name: 'Audience',
        active: true,
        disabled: true
      },
      {
        name: 'Time',
        active: true,
        disabled: true
      },
      {
        name: 'Location',
        active: true,
        disabled: true
      },
      {
        name: 'Weather',
        active: true,
        disabled: true
      },
      {
        name: 'Placement',
        active: true,
        disabled: false
      },
      {
        name: 'Platform',
        active: true,
        disabled: true
      },
      {
        name: 'A/B Tests',
        active: true,
        disabled: true
      },
      {
        name: 'Rule-Based Feeds',
        active: true,
        disabled: true
      }
    ]
  }
];

const components = {
  declarations: [
    // Display Formats
    PreviewBannerComponent,
    PreviewExpandableBannerComponent,
    PreviewRevealComponent,
    PreviewCollapsableBannerComponent,

    // Rich Media Formats
    PreviewRMBannerComponent,
    PreviewRMExpandableBannerComponent,
    PreviewInterscrollerComponent,
    PreviewInterstitialComponent,
    PreviewRMRevealComponent,
    PreviewUniversalBannerComponent,
    PreviewUniversalExpandableComponent,
    PreviewUniversalMiniscrollerComponent,
    PreviewUniversalRevealComponent,
    PreviewOvertureComponent,
    PreviewRMCollapsableBannerComponent,
    PreviewNativeBannerComponent,

    // Video Formats
    PreviewSquareInArticleComponent,
    PreviewVerticalInArticleComponent,
    PreviewLandscapeInArticleComponent,
    PreviewVideoInterscrollerComponent
  ],
  entryComponents: []
};

export const previewConfig = config;
export const previewComponents = components;
