import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import * as _ from 'lodash-es';
import { AdService } from '../../services/ad.service';
import { AdUnit } from '../../../models/ad.model';
import { EmbedGeneratorService } from './embed-generator.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../common/services/notification.service';

@Component({
  selector: 'og-embed-generator',
  templateUrl: './embed-generator.component.html'
})
export class EmbedGeneratorComponent implements OnInit, OnDestroy {
  id: number;
  private subs: Subscription[] = [];
  ready: boolean = false;
  ad: AdUnit = {
    active: null,
    id: null,
    version: null,
    brand: {
      id: null,
      name: null
    },
    category: {
      id: null,
      name: null
    },
    company: {
      id: null,
      name: null
    },
    product: {
      id: null,
      name: null
    },
    creative: {
      format: {
        id: null,
        name: null,
        type: {
          id: null,
          name: null
        }
      },
      json: null,
      sizing: {
        device_type: null
      }
    },
    jiraid: null,
    title: null,
    updated_at: null
  };
  form: FormGroup;
  environments: any = [
    {
      label: 'Web Browser',
      value: 'Web Browser'
    },
    {
      label: 'MRAID',
      value: 'MRAID'
    },
    {
      label: 'VAST',
      value: 'VAST'
    },
    {
      label: 'VPAID',
      value: 'VPAID'
    }
  ];
  adservers: any = [
    {
      label: 'Ad Manager',
      value: 'Ad Manager'
    }
  ];
  properties: any = {
    environment: 'Web Browser',
    adserver: 'Ad Manager'
  };
  extras: any = {};
  embedCode: string = '';
  private isEnvironmentDisabled: boolean = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private progressService: ProgressIndicatorService,
    private adService: AdService,
    private embedGeneratorService: EmbedGeneratorService,
    private ns: NotificationService
  ) {}

  /*
   * [PRIVATE METHODS]
   * */

  /**
   * Marks all controls in a form group as touched
   * @param formGroup - The form group to touch
   */
  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  /**
   * Gets the code from the service
   * @param v
   */
  private getCode(v: any = null) {
    this.progressService.showSpinner();

    this.properties.environment = this.form.value.environment;

    if (this.form.valid || this.form.status === 'DISABLED') {
      const code: any = this.embedGeneratorService.generate({
        ad: this.ad,
        properties: this.properties,
        extras: this.extras
      });
      if (code instanceof Observable) {
        code.subscribe((tag) => {
          this.embedCode = `\n${tag}`;
          this.progressService.hideSpinner();
        });
      } else {
        this.embedCode = code;
        this.progressService.hideSpinner();
      }
    } else {
      this.embedCode = '';
      this.progressService.hideSpinner();
    }
  }

  private filterRichMediaEnvironments() {
    this.environments = this.environments.slice(0, 2);
  }

  /**
   * After Initialization, the form is set
   * and the subscription to the changes is applied
   */
  private afterInit() {
    const self = this;
    self.ready = true;
    self.form = new FormGroup({
      environment: new FormControl(
        {
          value: self.properties.environment,
          disabled: self.isEnvironmentDisabled
        },
        [Validators.required]
      ),
      adserver: new FormControl(
        {
          value: self.properties.adserver,
          disabled: true
        },
        [Validators.required]
      )
    });
    self.form.statusChanges.subscribe((val) => self.getCode(val));
    self.markFormGroupTouched(self.form);
    self.getCode();
  }

  /**
   * [PUBLIC METHODS]
   */

  /**
   * On Component Initialization
   */
  ngOnInit() {
    const self = this;
    self.progressService.showBar();

    self.subs[0] = self.route.params.subscribe((params) => {
      self.id = +params['id'];

      self.subs[1] = self.adService.get(self.id).subscribe((data) => {
        self.ad = data.data;

        if (
          self.ad.creative.format.type.name === 'video' &&
          self.ad.creative.format.name === 'Video'
        ) {
          self.properties.environment = 'VAST';
        } else if (self.ad.creative.format.type.name === 'interactivevideo') {
          self.properties.environment = 'VPAID';
        } else if (
          self.ad.creative.format.type.name === 'richmedia' &&
          self.ad.creative.format.name === 'Interstitial'
        ) {
          self.filterRichMediaEnvironments();
          self.isEnvironmentDisabled = false;
        }

        self.progressService.hideBar();

        if (typeof data.data.creative === 'undefined') {
          self.router.navigate(['/not-found']);
        } else {
          self.afterInit();
        }
      });
    });
  }

  /**
   * Downloads the embed code
   * @return File
   */
  download() {
    this.embedGeneratorService.download({
      ad: this.ad,
      properties: this.properties,
      extras: this.extras
    });
  }

  /**
   * Opens a new tab with a form to validate our vast/vpaid embed.
   */
  validateEmbed() {
    const tag = encodeURIComponent(this.embedCode);
    window.open('https://googleads.github.io/googleads-ima-html5/vsi/?tag=' + tag, '_blank');
    // window.open('https://developers.google.com/interactive-media-ads/docs/sdks/html5/vastinspector?tag=' + tag, '_blank');
  }

  /**
   * On Sub Form Changed
   * @param data
   */
  onSubFormChanged(data = {}) {
    this.extras = data;
    this.getCode();
  }

  /**
   * Fallback copy fn
   * @param text
   */
  _fallbackCopy(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      this.ns.notify('Copying to clipboard was ' + msg + '!', true, 'success');
    } catch (err) {
      this.ns.notify('Could not copy text!', true, 'error');
    }

    document.body.removeChild(textArea);
  }

  /**
   * Copy to clipboard fn
   */
  copy() {
    const text = this.embedCode;

    if (!window.navigator.clipboard) {
      this._fallbackCopy(text);
      return;
    }

    window.navigator.clipboard.writeText(text).then(
      () => {
        this.ns.notify('Copying to clipboard was successful!', true, 'success');
      },
      () => {
        this.ns.notify('Could not copy text!', true, 'error');
      }
    );
  }

  /**
   * Checks if ad unit is a video ad unit
   * @param ad
   * @returns
   */
  isVideoAd(ad) {
    if (
      (ad.creative.format.name === 'Video' && ad.creative.format.type.name === 'video') ||
      ad.creative.format.type.name === 'interactivevideo'
    ) {
      return true;
    }

    return false;
  }

  /**
   * Executes On Destroy
   */
  ngOnDestroy() {
    _.each(this.subs, (s) => {
      if (typeof s !== 'undefined') {
        s.unsubscribe();
      }
    });
  }
}
