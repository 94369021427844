import { Component, OnInit, Output, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NotificationService } from '../../../common/services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { PurgeService } from '../../resources/purge.service';
import { Subscription } from 'rxjs';
import * as _ from 'lodash-es';

@Component({
  selector: 'og-purge-cdn-path',
  templateUrl: './purge-cdn-path.html'
})
export class PurgeCDNPathComponent implements OnInit, OnDestroy {
  @Output() closeModal;
  inputRequired = new FormControl('', [Validators.required]);
  private subs: Subscription[] = [];

  constructor(
    private ns: NotificationService,
    private dialog: MatDialog,
    private purgeService: PurgeService
  ) {}

  ngOnInit() {}

  getErrorMessage() {
    return this.inputRequired.hasError('required') ? 'Field is required' : false;
  }

  onPurgePath() {
    if (this.inputRequired.status === 'VALID') {
      this.subs[0] = this.purgeService.purgeUrl(this.inputRequired.value).subscribe(
        (res) => {
          this.ns.notify(res.message, true, 'success');
          this.dialog.closeAll();
        },
        (err) => {
          this.ns.notify(err.error.error, true, 'error');
          this.dialog.closeAll();
        }
      );
    }
  }

  ngOnDestroy() {
    _.each(this.subs, (s) => {
      if (typeof s !== 'undefined') {
        s.unsubscribe();
      }
    });
  }
}
