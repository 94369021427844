import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  OnChanges,
  Input,
  HostListener
} from '@angular/core';
import { Event } from '../../../../../models/event.model';
import * as _ from 'lodash-es';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'og-preview-monitor',
  templateUrl: './monitor.component.html'
})
export class MonitorComponent implements OnInit, OnChanges {
  @Output() closeMonitor = new EventEmitter<boolean>();
  @Input() data: any;
  @Input() ftype: any;
  @Input() format: any;
  private subs: Array<any> = [];
  private id: number = null;
  private storageKey: string = '';
  type: string = 'standard';
  displayedColumns: string[] = [
    'state',
    'page',
    'event',
    'attr',
    'attrLabel',
    'componentId',
    'attrValue',
    'isClick',
    'isInteraction'
  ];
  logs: Event[] = [];
  summary: any = {
    impression: 0,
    load_time: 0,
    interactions: 0,
    clicks: 0,
    errors: 0,
    timespent: -1
  };
  paused: boolean = false;
  private checker: any = null;
  private summaryData: any = [];
  private summaryTemp: any = [];
  private timespent: any = null;
  private jsonLength: number = -1;
  private retrievedJSON: any = null;
  imaEvents: boolean = false;
  googleLogs: any = [];
  googleColumns: string[] = ['name', 'type', 'value'];

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    if (event.target.innerWidth > 1024) {
      this.setDisplayedColumns(true);
    } else {
      this.setDisplayedColumns(false);
    }
  }

  /**
   * Constructor
   * @param route
   */
  constructor(private route: ActivatedRoute) {
    window.addEventListener('message', this.listenPM.bind(this), false);
  }

  ngOnInit() {
    this.subs[0] = this.route.params.subscribe((params) => {
      this.id = +params['id'];
      if (
        (this.ftype === 'video' && this.format === 'Video') ||
        this.ftype === 'interactivevideo'
      ) {
        this.type = 'video';
      } else {
        this.type = 'standard';
        this.storageKey = 'og-PreviewEvents_' + this.id;
        this.listen();
      }
    });
  }

  /**
   * Listen to Post Messages from Video Events Service
   * @param e
   */
  private listenPM(e: MessageEvent) {
    if (e.data.source === 'OG-PLATFORM' && e.data.type === 'command' && !this.paused) {
      this.addVideoEvents(e.data.data);
    }
  }

  /**
   * Add Video Events to logs
   * @param e
   */
  private addVideoEvents(e) {
    if (e.type === 'render') {
      if (e.data.event === 'Requested Impression') {
        this.summary.impression = 1;
      }

      if (e.data.event === 'load_time') {
        this.summary.load_time = e.data.attrLabel;
      }

      if (e.data.isClick) {
        this.summary.clicks++;
      }

      if (e.data.isInteraction) {
        this.summary.interactions++;
      }

      if (e.data.withError) {
        this.summary.errors++;
      }

      if (e.data.timespent !== 0 && typeof e.data.timespent !== 'undefined') {
        this.summary.timespent = e.data.timespent;
      }

      this.summaryData.push(e.data);
      this.logs.unshift(e.data);
      this.logs = [...this.logs];
    } else if (e.type === 'google') {
      let value = '';

      if (e.data.type === 'adProgress') {
        value = e.data.data.currentTime.toFixed(2) + 's';
      } else if (e.data.type === 'remainingTime') {
        value = e.data.data.remainingTime.toFixed(2) + 's';
      } else if (e.data.type === 'adsLoaded') {
        value = e.data.data.adTagUrl;
      } else if (e.data.type === 'loaded') {
        value = 'Ad Id [' + e.data.data.adData.adId + ']';
      } else if (e.data.type === 'setVolume') {
        value = e.data.data.volume;
      }

      const trackingObj = {
        name: e.data.name,
        type: e.data.type,
        value
      };
      this.googleLogs.unshift(trackingObj);
      this.googleLogs = [...this.googleLogs];
    }
  }

  /**
   * Updates variable storing the local storage data
   */
  private listen() {
    this.checker = setInterval(this.checkStorage.bind(this), 1000);
  }

  /**
   * Checks the local storage variable
   */
  private checkStorage() {
    let retrievedObject = null;

    if (typeof Storage === 'undefined') {
      return;
    }

    retrievedObject = localStorage.getItem(this.storageKey);

    if (retrievedObject !== null && retrievedObject.length !== this.jsonLength) {
      this.jsonLength = retrievedObject.length;
      this.retrievedJSON = JSON.parse(retrievedObject);
      this.logs = this.retrievedJSON.events.reverse();
      this.timespent = this.retrievedJSON.timespent.reverse();

      this.updateSummary();
    }

    if (retrievedObject === null) {
      this.logs = [];
      this.timespent = 0;
    }
  }

  /**
   * Updates the summary of events
   */
  private updateSummary() {
    this.summary.interactions = 0;
    this.summary.clicks = 0;
    this.summary.errors = 0;
    this.summary.timespent = -1;

    _.each(this.logs, (value, key) => {
      if (value.event === 'Requested Impression') {
        this.summary.impression = 1;
      }

      if (value.isClick) {
        this.summary.clicks++;
      }

      if (value.isInteraction) {
        this.summary.interactions++;
      }

      if (value.withError) {
        this.summary.errors++;
      }

      if (value.event === 'load_time') {
        this.summary.load_time = value.attrLabel;
      }

      if (typeof this.summaryTemp[value.event] === 'undefined') {
        this.summaryTemp[value.event] = {
          title: value.event,
          total: 1,
          order: Object.keys(this.summaryTemp).length
        };
      } else {
        this.summaryTemp[value.event].total++;
      }
    });

    this.summaryData = _.toArray(this.summaryTemp);

    _.each(this.timespent, (value, key) => {
      this.summary.timespent += parseInt(value.attr);
    });
  }

  /**
   * Depending on media query, shows the necessary columns
   * @param s
   */
  setDisplayedColumns(s) {
    if (s) {
      this.displayedColumns = [
        'state',
        'page',
        'event',
        'attr',
        'attrLabel',
        'componentId',
        'attrValue',
        'isClick',
        'isInteraction'
      ];
    } else {
      this.displayedColumns = ['event', 'attr', 'componentId', 'isClick', 'isInteraction'];
    }
  }

  /**
   * Listen to changes
   * @param changes
   */
  ngOnChanges(changes) {
    if (window.innerWidth > 1024) {
      this.setDisplayedColumns(true);
    } else {
      this.setDisplayedColumns(false);
    }
  }

  /**
   * Toggles pause for listening to changes
   */
  toggle() {
    if (this.paused) {
      if (this.type === 'standard') {
        this.listen();
      }
      this.paused = false;
    } else {
      if (this.type === 'standard') {
        clearInterval(this.checker);
        this.checker = null;
      }
      this.paused = true;
    }
  }

  /**
   * Clears data
   */
  clear() {
    if (localStorage.getItem(this.storageKey)) {
      localStorage.removeItem(this.storageKey);
    }
    this.summary = {
      impression: 0,
      load_time: 0,
      interactions: 0,
      clicks: 0,
      errors: 0,
      timespent: -1
    };
    this.logs = [];
    this.timespent = [];
    this.summaryData = [];
    this.googleLogs = [];
  }

  /**
   * Closes panel
   */
  close() {
    this.closeMonitor.emit();
  }

  /**
   * On Destroy
   */
  ngOnDestroy(): void {
    window.removeEventListener('message', this.listenPM.bind(this), false);

    _.each(this.subs, (s) => {
      if (typeof s !== 'undefined') {
        s.unsubscribe();
      }
    });
  }
}
