import { Component, OnInit, Output } from '@angular/core';
import { RESTService } from '../../../common/services/rest.service';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import { NotificationService } from '../../../common/services/notification.service';
import { EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'og-reset-password',
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {
  @Output() onPasswordRecovery = new EventEmitter<void>();
  private requestSubscription: Subscription;
  passwordRecoveryForm: FormGroup;

  constructor(
    private rest: RESTService,
    private progressService: ProgressIndicatorService,
    private ns: NotificationService
  ) {}

  ngOnInit() {
    this.passwordRecoveryForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  /**
   * When submitig the form, reach the endpoint to reset the password.
   * @params fomrValue: Object
   */
  formSubmitted(formValue) {
    this.progressService.showSpinner();
    const requestParams = new FormData();
    requestParams.append('email', formValue.email);
    this.requestSubscription = this.rest.post('auth/forgot-password', requestParams).subscribe(
      (data) => {},
      (errObj) => {
        const errorMessage = errObj.error.error.email;
        this.ns.notify(errorMessage, true, 'error');
        this.progressService.hideSpinner();
        this.onPasswordRecovery.emit();
      },
      () => {
        this.ns.notify('Email sent with password recovery.', true, 'success');
        this.progressService.hideSpinner();
        this.onPasswordRecovery.emit();
      }
    );
  }

  /**
   * On modal closed/component destroyed
   */
  ngOnDestroy(): void {
    if (typeof this.requestSubscription !== 'undefined') {
      this.requestSubscription.unsubscribe();
    }
  }
}
