import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'og-about-privacy',
  templateUrl: './about-privacy.component.html'
})
export class AboutPrivacyComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
