import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'og-p-rm-interscroller',
  templateUrl: './interscroller.component.html'
})
export class PreviewInterscrollerComponent implements OnInit {
  @Output() formChange = new EventEmitter();
  @Input('creative') creative: any;
  @Input('context') context: any;
  form: FormGroup;
  textColor = '#FFFFFF';
  barColor = '#000000';
  bgColor = '#000000';

  constructor(private _formBuilder: FormBuilder) {}

  ngOnInit() {
    const self = this;
    const bgImage = typeof self.context.bgImage !== 'undefined' ? self.context.bgImage : '';
    self.form = self._formBuilder.group({
      textColor: ['#FFFFFF', Validators.required],
      barColor: ['#000000', Validators.required],
      topMessage: ['Advertisement'.toUpperCase(), Validators.required],
      bgColor: ['#000000', Validators.required],
      bgImage: [bgImage, [Validators.pattern('^(http|https):.*.(jpg|JPG|png|PNG|gif|GIF)$')]],
      bottomMessage: ['Scroll to continue with content'.toUpperCase(), Validators.required],
      positionOffsetTop: [
        'auto',
        [Validators.required, Validators.pattern('^(auto|-?[0-9]{0,10})$')]
      ],
      positionOffsetBottom: [
        'auto',
        [Validators.required, Validators.pattern('^(auto|-?[0-9]{0,10})$')]
      ],
      widthBehavior: ['fullscreen', Validators.required]
    });
    self.form.statusChanges.subscribe((val) => self.sendFormData(val));
    self.sendFormData();
  }

  /**
   * Sends form data
   * @param val
   */
  sendFormData(val?) {
    if (this.form.valid) {
      this.formChange.emit({
        data: this.form.value
      });
    }
  }

  /**
   * Returns error
   * @param controlName
   * @param errorMsg
   */
  private getControlError(controlName, errorMsg?) {
    return this.form.get(controlName).errors['required'] ? 'Field Required' : errorMsg;
  }
}
