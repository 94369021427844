import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'og-preview-options',
  templateUrl: './options.component.html'
})
export class OptionsComponent implements OnInit {
  @Output() closeOptions = new EventEmitter<boolean>();
  @Output() onPositionChange = new EventEmitter<string>();
  @Input('position') position: string = 'top';
  placement: string = 'top';
  dismiss: boolean = false;

  constructor() {}

  ngOnInit() {}

  /**
   * Closes panel
   */
  close() {
    this.closeOptions.emit();
  }

  /**
   * Updates ad position.
   */
  onPositionUpdate(evt) {
    this.onPositionChange.emit(evt.value);
  }
}
