import { Component, OnInit } from '@angular/core';
import { ProgressIndicatorService } from '../../services/progress-indicator.service';

@Component({
  selector: 'og-loading-indicator',
  templateUrl: './loading-indicator.component.html'
})
export class LoadingIndicatorComponent implements OnInit {
  constructor(public progressIndicator: ProgressIndicatorService) {}

  ngOnInit() {}
}
