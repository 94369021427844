import { Injectable } from '@angular/core';

@Injectable()
export class HelperService {
  constructor() {}

  /**
   * This function will return a stringified string of an JSON object.
   */
  jsonToStr = JSON.stringify;

  /**
   * This function will return a JSON object from a string
   */
  strToJson = JSON.parse;
}
