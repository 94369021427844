import { Component, OnInit, Output, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'og-log-date',
  templateUrl: './log-date.html'
})
export class LogDateComponent implements OnInit {
  @Output() closeModal;
  date = moment();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {}

  /**
   * Open Link with Date
   */
  openLink() {
    const date = this.date.format('YYYY-MM-DD');
    const url = this.data.url.replace('[DATE]', date);
    window.open(url, '_blank');
  }
}
