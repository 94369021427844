<!-- This is too slow -->
<div
    id="standard-unit"
    *ngIf="ready && adtype === 'Standard'"
    class="ad-unit"
    [innerHtml]="embedCode | sanitizeHtml"
></div>

<div id="amp-unit" *ngIf="ready && adtype === 'AMP'" class="ad-unit"></div>

<div id="video-unit" *ngIf="ready && adtype === 'Video'" class="video-unit">
    <og-video-ad [xml]="embedCode" [video]="video"></og-video-ad>
</div>
