import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShowcaseService } from './showcase.service';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as _ from 'lodash-es';
import { EnvironmentService } from '../../../common/services/environment.service';
import { HttpClient } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { NotificationService } from '../../../common/services/notification.service';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import { EmbedGeneratorService } from '../../../public/components/embed-generator/embed-generator.service';
import { AuthService } from '../../../auth/auth.service';
import { RESTService } from '../../../common/services/rest.service';
import { logger } from '../../../common/services/logger.service';

@Component({
  selector: 'og-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.scss']
})
export class ShowcaseComponent implements OnInit, OnDestroy {
  loaded: boolean = false;
  error: boolean = false;
  private subs: Subscription[] = [];
  campaigns: Array<{
    id: any;
    name: string;
  }> = [
    {
      id: null,
      name: 'Create Campaign'
    }
  ];
  sites: Array<{
    id: any;
    domain: string;
    name: string;
  }> = [];
  filteredCampaigns: Observable<any>;
  filteredSites: Observable<any>;
  campaign = new FormControl();
  site = new FormControl();
  campaignName = new FormControl();
  brand: string = '';
  showcaseService: ShowcaseService;
  createCampaign: boolean = false;
  campaignCreated: boolean = false;
  creativeCreated: boolean = false;
  creativeExists: boolean = false;
  embedCode: string = '';
  user: any = null;
  demoUrl: string = '';
  creativeHash: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private environmentService: EnvironmentService,
    private http: HttpClient,
    private ns: NotificationService,
    private progressService: ProgressIndicatorService,
    private embedGeneratorService: EmbedGeneratorService,
    private authService: AuthService,
    private rest: RESTService
  ) {
    this.user = authService.user();
    this.brand = this.data.ad.brand.name.trim().replace(' ', ' - ');
    this.embedCode = embedGeneratorService.generate({
      ad: this.data.ad,
      debug: true,
      properties: {
        environment: 'Web Browser',
        adserver: 'Ad Manager'
      },
      overrides: {
        schedule: ''
      }
    });
  }

  ngOnInit() {
    this.showcaseService = new ShowcaseService(this.environmentService, this.http, this.rest);
    this.demoUrl = this.showcaseService.getDemoUrl();
    this.subs[1] = this.showcaseService.tokenReady.subscribe((data) => {
      if (!data) {
        this.error = true;
      } else {
        this.subs[2] = this.showcaseService.getCreative(this.data.ad.id).subscribe(
          (creative) => {
            this.creativeExists = true;
            this.creativeHash = creative['data']['hash'];
            logger('info', 'SHOWCASE').log(
              'Creative already exists with hash: ',
              creative['data']['hash']
            )();
          },
          (errorResponse) => {
            this.creativeExists = false;
            logger('info', 'SHOWCASE').log('Creative does not exist, please create it.')();
          }
        );
        this.subs[3] = this.showcaseService.getCampaigns().subscribe((campaigns) => {
          _.each(campaigns['data'], (campaign) => {
            this.campaigns.push(campaign);
          });
          logger('info', 'SHOWCASE').log('Campaigns received!', campaigns['data'])();
        });
        this.subs[4] = this.showcaseService.getSites().subscribe((sites) => {
          this.sites = sites['data'];
          logger('info', 'SHOWCASE').log('Sites received!', sites['data'])();
        });
      }
      this.loaded = true;
    });
    this.filteredCampaigns = this.campaign.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(this.campaigns, value))
    );
    this.filteredSites = this.site.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(this.sites, value))
    );
    this.subs[5] = this.campaign.valueChanges.subscribe((val) => {
      this.createCampaign = val === 'Create Campaign';
      if (this.createCampaign) {
        this.campaignCreated = false;
      }
    });
  }

  /**
   * Filters campaigns/sites to return only what matches selection
   * @param data
   * @param value
   * @private
   */
  private _filter(data: Array<any> = [], value: string = '') {
    const filterValue = value.toLowerCase();

    return _.filter(data, (option) => {
      return option.name.toLowerCase().indexOf(filterValue) >= 0;
    });
  }

  /**
   * Clears campaign value and resets to select autocomplete
   */
  clear() {
    this.campaign.setValue('');
    this.createCampaign = false;
  }

  /**
   * Saves creative to the requested campaign
   * @param campaignId
   */
  saveCreative(campaignId = null) {
    this.progressService.showBar();

    if (campaignId === null) {
      const campaign = _.find(this.campaigns, { name: this.campaign.value });

      if (_.isUndefined(campaign)) {
        return;
      }

      campaignId = campaign.id;
    }

    this.showcaseService
      .saveCreative({
        adId: this.data.ad.id,
        brand: this.data.ad.brand.name,
        campaign_id: campaignId,
        category: this.data.ad.category.name,
        data: this.embedCode,
        jira: this.data.ad.jiraid,
        platform: !_.isUndefined(this.data.ad.creative.sizing.device_type)
          ? _.capitalize(this.data.ad.creative.sizing.device_type)
          : 'Cross-Device',
        preview: 'http://placehold.it/768x768?text=Embed+Origin+Preview',
        product: this.data.ad.product.name,
        title: this.data.ad.title,
        user: this.user !== null ? this.user.email : 'origintech@evolvemediallc.com'
      })
      .subscribe(
        (response) => {
          if (!_.isUndefined(response['status']) && response['status'] === 'success') {
            this.creativeCreated = true;
            this.creativeExists = true;
            this.creativeHash = response['hash'];
            this.progressService.hideBar();
            logger('success', 'SHOWCASE').error(response['message'])();
            this.ns.notify(response['message'], true, 'success');
          }
        },
        (errorResponse) => {
          this.progressService.hideBar();

          if (errorResponse.status === 422) {
            this.ns.notify(
              'There is an error on the form, please review and try again.',
              true,
              'error'
            );
            logger('error', 'SHOWCASE').error('Validation Error: ', errorResponse['error'])();
          } else {
            this.ns.notify(
              'There was an error trying to save the creative, please try again later or contact support at: origintech@evolvemediallc.com',
              true,
              'error'
            );
            logger('error', 'SHOWCASE').error('Error: ', errorResponse)();
          }
        }
      );
  }

  /**
   * Saves the campaign and then proceeds to
   * save creative
   */
  saveCampaign() {
    this.progressService.showBar();
    this.showcaseService.saveCampaign(this.campaignName.value, this.brand).subscribe(
      (response) => {
        if (!_.isUndefined(response['status']) && response['status'] === 'success') {
          this.campaignCreated = true;
          this.progressService.hideBar();
          logger('success', 'SHOWCASE').error(response['message'])();
          this.ns.notify(response['message'], true, 'success');
          this.saveCreative(response['id']);
        }
      },
      (errorResponse) => {
        this.progressService.hideBar();

        if (errorResponse.status === 422) {
          this.ns.notify(
            'There is an error on the form, please review and try again.',
            true,
            'error'
          );
        } else {
          this.ns.notify(
            'There was an error trying to save the campaign, please try again.',
            true,
            'error'
          );
        }
      }
    );
  }

  /**
   * Returns demo link
   */
  getDemoLink() {
    const site = _.find(this.sites, { name: this.site.value });

    if (this.site.value === null || this.creativeHash === '' || _.isUndefined(site)) {
      return false;
    }

    return this.demoUrl
      .replace('[DOMAIN]', site.domain)
      .replace('[CREATIVE_HASH]', this.creativeHash)
      .replace('[SITE_HASH]', site.id);
  }

  ngOnDestroy() {
    _.each(this.subs, (s) => {
      if (!_.isUndefined(s)) {
        s.unsubscribe();
      }
    });
  }
}
