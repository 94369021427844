<section class="preview" [hidden]="!ready">
    <div
        class="preview__content"
        [ngClass]="{
            phone: device === 'phone',
            tablet: device === 'tablet',
            portrait: (device === 'phone' || device === 'tablet') && !orientation,
            landscape: (device === 'phone' || device === 'tablet') && orientation,
            desktop: device === 'desktop'
        }"
    >
        <div class="device-container">
            <div
                #deviceContainer
                [ngClass]="{
                    device: true,
                    large: phoneSize === 'large' && device !== 'desktop',
                    medium: phoneSize === 'medium' && device !== 'desktop',
                    small: phoneSize === 'small' && device !== 'desktop'
                }"
            >
                <iframe
                    #iframe
                    [ngClass]="{
                        'device-container__iframe': true,
                        'has-bars': (device === 'phone' || device === 'tablet') && browserBars
                    }"
                    [src]="getIframeUrl() | safe"
                    allowfullscreen
                    [height]="iframeHeight"
                    (load)="onIframeLoaded()"
                ></iframe>
            </div>
        </div>

        <div class="control-panel">
            <!-- Since we already have a device panel. These are no longer needed. We can toggle size there.-->
            <div class="sizes" *ngIf="(device === 'phone' || device === 'tablet') && !devicePanel">
                <button
                    (click)="changePhoneSize('large')"
                    [ngClass]="{
                        'control-panel__btn sizes__btn large': true,
                        active: phoneSize === 'large'
                    }"
                    matTooltip="Large Phone Size"
                ></button>
                <button
                    (click)="changePhoneSize('medium')"
                    [ngClass]="{
                        'control-panel__btn  sizes__btn medium': true,
                        active: phoneSize === 'medium'
                    }"
                    matTooltip="Medium Phone Size"
                ></button>
                <button
                    (click)="changePhoneSize('small')"
                    [ngClass]="{
                        'control-panel__btn  sizes__btn small': true,
                        active: phoneSize === 'small'
                    }"
                    matTooltip="Small Phone Size"
                ></button>
            </div>
            <div
                [ngClass]="{
                    actions: true,
                    'top-separator': device === 'phone'
                }"
            >
                <button
                    *ngIf="device === 'phone' || device === 'tablet'"
                    (click)="toggleBrowserBars()"
                    [ngClass]="{
                        'control-panel__btn actions__btn browser-bars': true,
                        active: browserBars
                    }"
                    matTooltip="Browser Bars"
                ></button>
                <button
                    *ngIf="!isVideoAd(ad)"
                    (click)="devicePanel = !devicePanel"
                    [ngClass]="{
                        'control-panel__btn actions__btn device-panel': true,
                        active: devicePanel
                    }"
                    matTooltip="Device Options"
                ></button>
                <button
                    (click)="contextPanel = !contextPanel"
                    [ngClass]="{
                        'control-panel__btn actions__btn context-panel': true,
                        active: contextPanel
                    }"
                    matTooltip="Simulate Contexts"
                ></button>

                <button
                    (click)="previewOptionsPanel = !previewOptionsPanel"
                    [ngClass]="{
                        'control-panel__btn actions__btn preview-options-panel': true,
                        active: previewOptionsPanel
                    }"
                    matTooltip="Preview Options"
                ></button>

                <button
                    *ngIf="ad.creative.format.type.name !== 'amp'"
                    (click)="eventMonitor = !eventMonitor"
                    [ngClass]="{
                        'control-panel__btn actions__btn event-monitor': true,
                        active: eventMonitor
                    }"
                    matTooltip="Events Monitor"
                ></button>
                <button
                    *ngIf="device === 'phone' || device === 'tablet'"
                    (click)="toggleOrientation()"
                    [ngClass]="{
                        'control-panel__btn actions__btn orientation': true,
                        active: orientation
                    }"
                    matTooltip="Orientation"
                ></button>
                <button
                    *ngIf="
                        ad.creative.format.name !== 'Video' &&
                        ad.creative.format.type.name !== 'amp'
                    "
                    (click)="debug = !debug"
                    [ngClass]="{
                        'control-panel__btn actions__btn debug': true,
                        active: debug
                    }"
                    matTooltip="Debug"
                >
                    <i class="material-icons"> bug_report </i>
                </button>
            </div>
        </div>
    </div>

    <!-- Draggable panels -->
    <div *ngIf="devicePanel" ngDraggable class="preview__panel device-panel drag-block">
        <og-preview-device
            [currentDevice]="device"
            [deviceSize]="phoneSize"
            [isPortrait]="orientation"
            (closeDevice)="devicePanel = false"
            (onDeviceChange)="setDevice($event)"
            (onSizeChange)="phoneSize = $event.size"
        ></og-preview-device>
    </div>
    <div *ngIf="contextPanel" ngDraggable class="preview__panel context-panel drag-block">
        <og-preview-context
            (closeContext)="contextPanel = false"
            (scheduleChanged)="setSchedule($event)"
            (placementChanged)="setExtras($event)"
            [creative]="ad.creative"
            [context]="context"
        ></og-preview-context>
    </div>
    <div *ngIf="previewOptionsPanel" ngDraggable class="preview__panel preview-options-panel">
        <og-preview-options
            (closeOptions)="previewOptionsPanel = false"
            (onPositionChange)="adPosition = $event"
            [position]="adPosition"
        ></og-preview-options>
    </div>
    <div
        *ngIf="ready"
        [hidden]="!eventMonitor"
        [ngDraggable]="true"
        (rzResizing)="onResizing($event)"
        (mousedown)="$event.stopPropagation()"
        class="preview__panel event-monitor"
    >
        <og-preview-monitor
            [data]="resizingData"
            [ftype]="ad.creative.format.type.name"
            [format]="ad.creative.format.name"
            (closeMonitor)="eventMonitor = false"
        ></og-preview-monitor>
    </div>
    <div
        [ngClass]="{
            preview__logo: true,
            'preview__logo--em': ad.company.name === 'Evolve Media',
            'preview__logo--mm': ad.company.name === 'Martini Media'
        }"
    ></div>
</section>
