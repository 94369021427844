<div class="forgot-password__content flex-set flex--column flex--align-items-center">
    <p>An e-mail will be sent with an url to reset the password</p>
    <form
        [formGroup]="passwordRecoveryForm"
        class="forgot-password__content__form flex-set flex--column flex--content-around flex--align-items-center col-100"
        (ngSubmit)="formSubmitted(passwordRecoveryForm.value)"
    >
        <mat-form-field class="col-80">
            <input
                matInput
                formControlName="email"
                required
                email
                placeholder="E-mail to get password reset"
                type="email"
            />
            <mat-error *ngIf="passwordRecoveryForm.controls['email']?.getError('required')"
                >Field Required</mat-error
            >
            <mat-error *ngIf="passwordRecoveryForm.controls['email']?.getError('email')"
                >Email must be a valid email address</mat-error
            >
        </mat-form-field>
        <mat-dialog-actions class="forgot-password__content__actions col-100">
            <button mat-raised-button color="warn" mat-dialog-close>Cancel</button>
            <button type="submit" mat-stroked-button [disabled]="passwordRecoveryForm.invalid">
                Reset Password
            </button>
        </mat-dialog-actions>
    </form>
</div>
