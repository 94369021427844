import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'og-eg-rm-universal-miniscroller',
  templateUrl: './universal-miniscroller.component.html'
})
export class EmbedUniversalMiniscrollerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
