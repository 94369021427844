import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class GroupGuardService implements CanActivate {
  constructor(
    public auth: AuthService,
    public router: Router,
    private snackBar: MatSnackBar
  ) {}

  /**
   * Reviews if user has the permission to access
   * to the requested route
   * @param route
   */
  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!this.auth.can(route.data.permission)) {
      this.router.navigateByUrl('/dashboard').then(() => {
        this.snackBar.dismiss();
        this.snackBar.open('Unauthorized', 'Dismiss', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      });
      return false;
    }

    return true;
  }
}
