import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash-es';

@Component({
  selector: 'og-standalone',
  templateUrl: './standalone.component.html'
})
export class StandaloneComponent implements OnInit, OnDestroy {
  id: number = null;
  debug: boolean = true;
  header: string = 'fixed';
  video: string = 'autoplay';
  schedule: string = '';
  position: string = '';
  ready: boolean = false;
  private sub: Array<any> = [];

  constructor(
    private route: ActivatedRoute,
    private renderer: Renderer2
  ) {
    this.renderer.addClass(document.body, 'og-standalone');
  }

  ngOnInit() {
    this.sub[0] = this.route.params.subscribe((params) => {
      this.id = +params['id'];
    });
    this.sub[1] = this.route.queryParams.subscribe((params) => {
      if (!_.isUndefined(params.schedule)) {
        this.schedule = params.schedule;
      }
      if (!_.isUndefined(params.position)) {
        this.position = params.position;
      }
      this.debug = params.debug;
      this.header = params.header;
      this.video = params.video;
    });
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'og-standalone');

    _.each(this.sub, (s) => {
      if (!_.isUndefined(s)) {
        s.unsubscribe();
      }
    });
  }
}
