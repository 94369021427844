import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'og-eg-rm-collapsable-banner',
  templateUrl: './collapsable-banner.component.html'
})
export class EmbedCollapsableBannerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
