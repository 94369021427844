import {
  ViewChild,
  TemplateRef,
  Component,
  OnInit,
  AfterContentChecked,
  OnDestroy
} from '@angular/core';
import { AuthService } from '../../../auth/auth.service';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../../../models/user.model';
import { NotificationService } from '../../services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { PurgeCDNPathComponent } from '../../../private/components/purge-cdn-path/purge-cdn-path.component';
import { ProfileComponent } from '../../../private/components/profile/profile.component';
import { ShowcaseComponent } from '../../../private/components/showcase/showcase.component';
import { PurgeService } from '../../../private/resources/purge.service';
import { AdService } from '../../../public/services/ad.service';
import { DatePipe } from '@angular/common';
import { ProgressIndicatorService } from '../../services/progress-indicator.service';
import { RESTService } from '../../services/rest.service';
import { EnvironmentService } from '../../services/environment.service';
import { HttpClient } from '@angular/common/http';
import { AboutVersionComponent } from '../../../private/components/about-version/about-version.component';
import { LogDateComponent } from '../../../private/components/log-date/log-date.component';
import * as _ from 'lodash-es';
import { PreviewService } from '../../../public/components/preview/preview.service';

@Component({
  selector: 'og-header',
  templateUrl: './header.component.html',
  providers: [DatePipe]
})
export class HeaderComponent implements OnInit, AfterContentChecked, OnDestroy {
  @ViewChild('qrCodeDialog', { static: true }) qrCodeDialog: TemplateRef<any>;
  id: number;
  isLoggedIn$: Observable<boolean>;
  isLoggedOut$: Observable<boolean>;
  user$: Observable<User>;
  isPreview: boolean = false;
  isLogin: boolean;
  ad: any = null;
  qrCode: string = '';
  isMobile: boolean = false;
  startDate = '';
  endDate = '';
  menuActive: boolean = false;
  private subs: Subscription[] = [];
  private about: any = {
    platform: null,
    render: null
  };

  constructor(
    private authService: AuthService,
    private purgeService: PurgeService,
    private datePipe: DatePipe,
    private router: Router,
    private ns: NotificationService,
    private dialog: MatDialog,
    private adService: AdService,
    private progressService: ProgressIndicatorService,
    private REST: RESTService,
    private envService: EnvironmentService,
    private http: HttpClient,
    private previewService: PreviewService
  ) {}

  ngAfterContentChecked() {
    this.isPreview = this.router.url.indexOf('preview') > -1 ? true : false;
    this.isLogin = this.router.url.indexOf('login') > -1 ? true : false;
    // added this for fixing issues with public pages
    this.authService.isAuthenticated();
  }

  ngOnInit() {
    const self = this;
    self.isLoggedIn$ = self.authService.isLoggedIn$;
    self.isLoggedOut$ = self.authService.isLoggedOut$;
    self.user$ = self.authService.user$;
    const dateObj = new Date();
    self.startDate = this.datePipe.transform(
      new Date(dateObj.getFullYear(), dateObj.getMonth(), 1),
      'MM-dd-yyyy'
    );
    self.endDate = this.datePipe.transform(
      new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, 0),
      'MM-dd-yyyy'
    );
    self.adService.adLoaded.subscribe((ad) => {
      self.ad = ad.data;
      self.id = ad.data.id;

      if (
        typeof self.ad.creative.sizing.device_type !== 'undefined' &&
        self.ad.creative.sizing.device_type === 'phone'
      ) {
        self.isMobile = true;
      }
    });
    self.previewService.linkGenerated.subscribe(
      (res) => self.onLinkReceived(res),
      (err) => {
        self.ns.notify('Could not copy link!', true, 'error');
      }
    );
  }

  /**
   * Returns if the action is allowed for the user
   * @param p
   */
  can(p) {
    return this.authService.can(p);
  }

  /**
   * Toggles a floating menu open/closed when on mobile devices.
   */
  toggleMenu() {
    this.menuActive = !this.menuActive;
  }

  /**
   * Navigate to a specific route triggered by the floating menu.
   * @param payload
   */
  navigateByFloatingMenu(payload: { route; hasDates; newWindow }) {
    this.toggleMenu();
    this.goTo(payload.route, payload.hasDates, payload.newWindow);
  }

  /**
   * Navigates to login
   */
  login() {
    this.router.navigate(['/login']);
  }

  /**
   * Logout
   */
  logout() {
    this.authService.logout().subscribe(() => {
      if (this.router.url !== '/preview') {
        this.login();
      }
    });
  }

  /**
   * Executes purge for all render static assets
   */
  purgeByRender() {
    this.progressService.showBar();
    this.purgeService.purgeRender().subscribe(
      (res) => {
        this.ns.notify(res.message, true, 'success');
        this.progressService.hideBar();
      },
      (err) => {
        this.ns.notify(err.error.error, true, 'error');
        this.progressService.hideBar();
      }
    );
  }

  /**
   * Executes purge by hostname
   */
  purgeByHostname() {
    this.progressService.showBar();
    this.purgeService.purgeHostname().subscribe(
      (res) => {
        this.ns.notify(res.message, true, 'success');
        this.progressService.hideBar();
      },
      (err) => {
        this.ns.notify(err.error.error, true, 'error');
        this.progressService.hideBar();
      }
    );
  }

  /**
   * Opens Purge Modal
   */
  purgeByPath() {
    this.dialog.open(PurgeCDNPathComponent, {
      minWidth: '800px'
    });
  }

  /**
   * Opens Profile modal
   */
  profile() {
    this.dialog.open(ProfileComponent, {
      height: '250px',
      width: '300px'
    });
  }

  /**
   * Go to log reports
   * @param to
   */
  goToCurrentDate(to) {
    const date = new Date();
    const today = this.datePipe.transform(date, 'MM-dd-yyyy');
    this.router.navigate(['/' + to + '/' + today + '/' + today]);
  }

  /**
   * Navigates to the requested url
   * @param to
   * @param hasDates
   */
  goTo(to, hasDates?, newWindow?) {
    if (typeof hasDates !== 'undefined' && hasDates) {
      const dateObj = new Date();
      const startDate = this.datePipe.transform(
        new Date(dateObj.getFullYear(), dateObj.getMonth(), 1),
        'MM-dd-yyyy'
      );
      const endDate = this.datePipe.transform(
        new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, 0),
        'MM-dd-yyyy'
      );

      this.router.navigate([to + '/' + startDate + '/' + endDate]);
    } else {
      if (newWindow) {
        window.open('#' + to, '_blank');
      } else {
        if (!this.authService.isAuthenticated()) {
          this.router.navigateByUrl('/login');
        } else {
          this.router.navigateByUrl(to);
        }
      }
    }
  }

  /**
   * Returns Ad name
   */
  getAdName() {
    if (this.ad === null) {
      return null;
    }

    return '[' + this.ad.jiraid + '] ' + this.ad.brand.name + ' | ' + this.ad.title;
  }

  /**
   * Opens modal with requested QR code
   */
  getQrCode() {
    const self = this;
    const link = encodeURIComponent(window.location.href);
    self.qrCode = 'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=' + link;
    self.dialog.open(self.qrCodeDialog);
  }

  /**
   * Opens Showcase Modal
   */
  openShowcaseModal() {
    let dialog = this.dialog.open(ShowcaseComponent, {
      data: {
        ad: this.ad
      },
      width: '500px'
    });
    dialog.afterClosed().subscribe(() => {
      dialog = null;
    });
  }

  /**
   * Opens a tab with endpoint
   * @param endpoint
   * @param from
   */
  linkTo(endpoint, from = 'platform') {
    let url = this.REST.baseUri + 'api/dev/' + endpoint + '?token=' + this.authService.getToken();

    if (from === 'render') {
      url = this.envService.getRenderUrl() + 'api/v1/dev/' + endpoint;
    }

    window.open(url, '_blank');
  }

  /**
   * Opens a tab with heavy-ads.glitch.me to test chrome heavy ad intervention
   */
  testHeavyAd() {
    const insightsUrl =
      this.ad.creative.format.type.name === 'amp'
        ? this.envService.getRenderUrl() + 'amphtml/' + this.ad.id + '?debug=true'
        : this.envService.getPlatformUrl() + 'insights/' + this.ad.id;
    const url = 'http://heavy-ads.glitch.me/?site=' + encodeURIComponent(insightsUrl);
    window.open(url, '_blank');
  }

  /**
   * Opens Version Modal with provided data
   * @param type
   * @param deploy
   */
  private openModal(type, deploy) {
    this.dialog.open(AboutVersionComponent, {
      data: {
        type: _.capitalize(type),
        deploy
      },
      height: type === 'platform' ? '350px' : '480px',
      width: type === 'platform' ? '300px' : '540px'
    });
  }

  /**
   * Requests data and opens modal
   * @param type
   */
  openVersion(type) {
    if (type === 'platform') {
      if (this.about.platform === null) {
        this.subs[0] = this.http
          .get(this.envService.getPlatformUrl() + 'api/dev/deploy')
          .subscribe((response) => {
            this.about.platform = response['data']['deploy'];
            this.openModal(type, this.about.platform);
          });
      } else {
        this.openModal(type, this.about.platform);
      }
    } else {
      if (this.about.render === null) {
        this.subs[1] = this.http
          .get(this.envService.getRenderUrl() + 'api/v1/dev/deploy')
          .subscribe((response) => {
            this.about.render = response['data']['deploy'];
            this.openModal(type, this.about.render);
          });
      } else {
        this.openModal(type, this.about.render);
      }
    }
  }

  /**
   * Fallback copy fn
   * @param text
   */
  _fallbackCopy(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      this.ns.notify('Copying link clipboard was ' + msg + '!', true, 'success');
    } catch (err) {
      this.ns.notify('Could not copy link!', true, 'error');
    }

    document.body.removeChild(textArea);
  }

  /**
   * On Link Received, copies link to share
   * @param link
   * @returns
   */
  onLinkReceived(link) {
    if (!window.navigator.clipboard) {
      this._fallbackCopy(link);
      return;
    }

    window.navigator.clipboard.writeText(link).then(
      () => {
        this.ns.notify('Copying link clipboard was successful!', true, 'success');
      },
      () => {
        this.ns.notify('Could not copy link!', true, 'error');
      }
    );
  }

  /**
   * Requests link to Preview Service
   */
  share() {
    this.previewService.linkRequested.emit(true);
  }

  /**
   * Opens Logs Modal to select date
   * @param type
   * @param deploy
   */
  openLogModal(type) {
    const endpoint = 'logs/[DATE]';
    let url = this.REST.baseUri + 'api/dev/' + endpoint + '?token=' + this.authService.getToken();

    if (type === 'render') {
      url = this.envService.getRenderUrl() + 'api/v1/dev/' + endpoint;
    }
    this.dialog.open(LogDateComponent, {
      data: {
        type,
        url
      },
      height: '350px',
      width: '300px'
    });
  }

  ngOnDestroy() {
    _.each(this.subs, (s) => {
      if (typeof s !== 'undefined') {
        s.unsubscribe();
      }
    });
  }
}
