<div class="header-report">
    <div class="header-report__content flex-set flex--content-between flex--align-items-center">
        <h3>Features Help</h3>
        <mat-form-field>
            <mat-select [(ngModel)]="adVersion" placeholder="Ad Version">
                <mat-option value="v2">Version 2</mat-option>
                <mat-option value="v3">Version 3</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

<div class="plugins-help help help-container">
    <div #text class="text" [innerHTML]="data[adVersion]"></div>

    <div class="nav-container">
        <ul class="nav bs-docs-sidenav">
            <li *ngFor="let link of links[adVersion]">
                <a (click)="scrollTo(link)">
                    <span class="name">{{ link }}</span>
                </a>
            </li>
        </ul>
    </div>
</div>

<button (click)="go2Top()" id="go2Top" class="back-to-top" title="Go to top">
    <span class="fa fa-arrow-up"></span><span> Go to top</span>
</button>

<link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css"
/>
