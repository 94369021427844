import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'og-p-rm-universal-expandable',
  templateUrl: './universal-expandable.component.html'
})
export class PreviewUniversalExpandableComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
