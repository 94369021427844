import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { previewConfig } from '../../preview.config';
import * as _ from 'lodash-es';

@Component({
  selector: 'og-preview-context',
  templateUrl: './context.component.html'
})
export class ContextComponent implements OnInit {
  @Output() closeContext = new EventEmitter<boolean>();
  @Output() scheduleChanged = new EventEmitter<any>();
  @Output() placementChanged = new EventEmitter<any>();
  @Input() creative: any;
  @Input() context: any;
  contexts: Array<any> = [];
  schedule: any = new Date();

  constructor() {}

  ngOnInit() {
    const tempContexts = _.find(previewConfig, {
      name: 'Contexts'
    });
    this.contexts = this.getActiveItems(tempContexts.data);
  }

  /**
   * Return all active elements
   * @param cs
   */
  getActiveItems(cs) {
    return _.filter(cs, {
      active: true
    });
  }

  /**
   * Closes panel
   */
  close() {
    this.closeContext.emit();
  }

  /**
   * On date change, change the schedule of the ad unit
   * @param d
   */
  onScheduleChange(d) {
    this.scheduleChanged.emit(d);
  }

  /**
   * On Sub Form Changed
   * @param data
   */
  onSubFormChanged(data) {
    this.placementChanged.emit(data);
  }
}
