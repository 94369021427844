import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    public auth: AuthService,
    public router: Router,
    private snackBar: MatSnackBar
  ) {}

  /**
   * Has access to route?
   * @param route
   * @param state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.auth.isAuthenticated()) {
      if (
        this.router.url.includes('preview') &&
        this.router.url !== '/help/metrics' &&
        this.router.url !== '/help/plugins'
      ) {
        this.router.navigateByUrl('/login').then(() => {
          this.snackBar.dismiss();
          this.snackBar.open('Unauthorized', 'Dismiss', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
        });
      }
      return false;
    }

    return true;
  }
}
