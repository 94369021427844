// [Modules]
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { RouterModule } from '@angular/router';
import { AngularDraggableModule } from 'angular2-draggable';
import { OriginCommonModule } from '../common/common.module';
import { ColorPickerModule } from 'ngx-color-picker';

// [Components]
import { LoginComponent } from './components/login/login.component';
import { PreviewComponent } from './components/preview/preview.component';
import { previewComponents } from './components/preview/preview.config';
import { AboutPrivacyComponent } from './components/about-privacy/about-privacy.component';
import { EmbedGeneratorComponent } from './components/embed-generator/embed-generator.component';
import {
  embedGeneratorFormatsComponents,
  embedGeneratorAdServersComponents
} from './components/embed-generator/embed-generator.config';
import { HelpMetricsComponent } from './components/help-metrics/help-metrics.component';
import { HelpFeaturesComponent } from './components/help-features/help-features.component';
import { HelpApiComponent } from './components/help-api/help-api.component';
import { DeviceComponent } from './components/preview/control-panel/device/device.component';
import { ContextComponent } from './components/preview/control-panel/context/context.component';
import { OptionsComponent } from './components/preview/control-panel/options/options.component';
import { MonitorComponent } from './components/preview/control-panel/monitor/monitor.component';
import { StandaloneComponent } from './components/standalone/standalone.component';
import { HelpProductsComponent } from './components/help-products/help-products.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

// [Services]
import { AdService } from './services/ad.service';
import { EmbedGeneratorService } from './components/embed-generator/embed-generator.service';
import { PreviewService } from './components/preview/preview.service';

const declarations = [
  LoginComponent,
  PreviewComponent,
  AboutPrivacyComponent,
  EmbedGeneratorComponent,
  HelpMetricsComponent,
  HelpFeaturesComponent,
  HelpApiComponent,
  HelpProductsComponent,
  ResetPasswordComponent,
  DeviceComponent,
  ContextComponent,
  OptionsComponent,
  MonitorComponent,
  StandaloneComponent
];

@NgModule({
  declarations: [
    ...declarations,
    ...embedGeneratorFormatsComponents.declarations,
    ...embedGeneratorAdServersComponents.declarations,
    ...previewComponents.declarations
  ],

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule,
    AngularDraggableModule,
    OriginCommonModule,
    ColorPickerModule
  ],

  providers: [AdService, EmbedGeneratorService, PreviewService],

  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PublicModule {}
