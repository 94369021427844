import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './public/components/login/login.component';
import { AboutPrivacyComponent } from './public/components/about-privacy/about-privacy.component';
import { StandaloneComponent } from './public/components/standalone/standalone.component';
import { NotFoundComponent } from './common/components/not-found/not-found.component';
import { HelpFeaturesComponent } from './public/components/help-features/help-features.component';
import { HelpMetricsComponent } from './public/components/help-metrics/help-metrics.component';
import { HelpApiComponent } from './public/components/help-api/help-api.component';
import { PreviewComponent } from './public/components/preview/preview.component';
import { EmbedGeneratorComponent } from './public/components/embed-generator/embed-generator.component';
import { HelpProductsComponent } from './public/components/help-products/help-products.component';
import { ResetPasswordComponent } from './public/components/reset-password/reset-password.component';

const publicRoutes: Routes = [
  // Public routes
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'about/privacy',
    component: AboutPrivacyComponent
  },
  {
    path: 'standalone/:id',
    component: StandaloneComponent
  },
  {
    path: 'help/features',
    component: HelpFeaturesComponent
  },
  {
    path: 'help/metrics',
    component: HelpMetricsComponent
  },
  {
    path: 'help/products',
    component: HelpProductsComponent
  },
  {
    path: 'help/api',
    component: HelpApiComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'preview/:id',
    component: PreviewComponent
  },
  {
    path: 'embed-generator/:id',
    component: EmbedGeneratorComponent
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },

  // When route not found, redirect.
  // This has to be at the end or any route below this, won't load
  {
    path: '**',
    redirectTo: 'not-found'
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(publicRoutes, {
      useHash: true,
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class PublicRouting {}
