import { Component, Input, OnInit } from '@angular/core';
import { VideoEventsService } from '../../services/video-events.service';

@Component({
  selector: 'og-ad-unit',
  templateUrl: './ad-unit.component.html'
})
export class AdUnitComponent implements OnInit {
  @Input('embedCode') embedCode: string;
  @Input('format') format: object;
  @Input('sizing') sizing: object;
  @Input('adtype') adtype: string;
  @Input('video') video: string;
  ready: boolean = false;

  /**
   * constructor
   */
  constructor() {
    const overture = document.getElementsByClassName('og-overture-wrapper');

    if (overture.length > 0) {
      overture[0].remove();
    }
  }

  /**
   * ng on init
   */
  ngOnInit(): void {
    if (this.adtype === 'AMP') {
      let onReady = setInterval(() => {
        const parent = document.getElementById('amp-unit');
        if (parent !== null) {
          clearInterval(onReady);
          onReady = null;
          this.createIframe(parent);
        }
      });
    }
    this.ready = true;
  }

  /**
   * Creates AMP Iframe
   * @param parent
   */
  createIframe(parent) {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('id', 'amp-iframe');
    iframe.setAttribute('name', 'amp-iframe');
    iframe.setAttribute('width', this.sizing['unit_sizes'].default.fixed_sizes.i.w);
    iframe.setAttribute('height', this.sizing['unit_sizes'].default.fixed_sizes.i.h);
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('scrolling', 'no');
    iframe.setAttribute('allowfullscreen', '');
    iframe.setAttribute(
      'allow',
      'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; camera; fullscreen; geolocation;'
    );
    parent.appendChild(iframe);
    iframe.contentDocument.open();
    iframe.contentDocument.write(this.embedCode);
    iframe.contentDocument.close();
  }
}
