<mat-toolbar
    [ngClass]="{
        header: true,
        header__preview: isPreview
    }"
    color="primary"
    *ngIf="!isLogin"
>
    <div class="header__brand-logo" (click)="goTo('/dashboard')"></div>

    <span *ngIf="!isPreview" style="flex: 1 1 auto" class="fill-remaining-space"></span>

    <ng-container *ngIf="!isPreview">
        <og-floating-menu
            [canAll]="can('all')"
            [canReports]="can('manage.reports')"
            [canProducts]="can('manage.products')"
            [canUsers]="can('manage.users')"
            [canAds]="can('manage.ads')"
            [canGroups]="can('manage.groups')"
            [isVisible]="menuActive"
            (onMenuClosed)="toggleMenu()"
            (navigateTo)="navigateByFloatingMenu($event)"
            [purgeCallbacks]="[
                purgeByRender.bind(this),
                purgeByHostname.bind(this),
                purgeByPath.bind(this)
            ]"
            [accountCallbacks]="[profile.bind(this), logout.bind(this)]"
            [aboutCallbacks]="openVersion.bind(this)"
            [linkTo]="linkTo.bind(this)"
        ></og-floating-menu>

        <ng-container *ngIf="user$ | async as user">
            <div *ngIf="isLoggedIn$ | async" class="header__right-content">
                <button class="header__action-burger" mat-icon-button (click)="toggleMenu()">
                    <mat-icon>menu</mat-icon>
                </button>
                <button
                    class="header__action-btn"
                    *ngIf="can('manage.reports')"
                    mat-button
                    [matMenuTriggerFor]="reportsMenu"
                >
                    Reports<mat-icon>arrow_drop_down</mat-icon>
                </button>
                <button
                    class="header__action-btn"
                    *ngIf="can('manage.products') || can('manage.users') || can('manage.groups')"
                    mat-button
                    [matMenuTriggerFor]="manageMenu"
                >
                    Manage<mat-icon>arrow_drop_down</mat-icon>
                </button>
                <button
                    class="header__action-btn"
                    *ngIf="can('manage.ads')"
                    mat-button
                    [matMenuTriggerFor]="purgeMenu"
                >
                    Purge<mat-icon>arrow_drop_down</mat-icon>
                </button>
                <button
                    class="header__action-btn"
                    *ngIf="can('all')"
                    mat-button
                    [matMenuTriggerFor]="testMenu"
                >
                    Debug<mat-icon>arrow_drop_down</mat-icon>
                </button>
                <button class="header__action-btn" mat-button [matMenuTriggerFor]="helpMenu">
                    Help<mat-icon>arrow_drop_down</mat-icon>
                </button>
                <button class="header__action-btn" mat-button [matMenuTriggerFor]="aboutMenu">
                    About<mat-icon>arrow_drop_down</mat-icon>
                </button>
                <button
                    class="header__action-btn"
                    mat-icon-button
                    [matMenuTriggerFor]="settingsMenu"
                >
                    <mat-icon>settings</mat-icon>
                </button>
            </div>
            <a *ngIf="isLoggedOut$ | async" (click)="login()">Login</a>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="isPreview && ad !== null">
        <span [matTooltip]="getAdName()" class="header__title">
            {{ getAdName() | maxlength: 64 }}
        </span>
        <ng-container *ngIf="isLoggedIn$ | async">
            <button class="header__share" mat-button (click)="share()" title="Click to copy link">
                <mat-icon>share</mat-icon>
            </button>
            <button
                class="header__showcase"
                mat-button
                (click)="openShowcaseModal()"
                title="Click to generate and showcase demo link"
            >
                <mat-icon>important_devices</mat-icon>
            </button>
            <a
                class="header__analytics"
                [routerLink]="'/analytics/' + id + '/' + startDate + '/' + endDate"
                target="_blank"
                title="Show analytics"
                ><mat-icon>show_chart</mat-icon></a
            >
            <a class="header__edit" [routerLink]="'/ad-maker/' + id" title="Edit creative"
                ><mat-icon>edit</mat-icon></a
            >
            <a class="header__tag-test" (click)="testHeavyAd()" title="Chrome Heavy Ad Intervention"
                ><mat-icon>integration_instructions</mat-icon></a
            >
            <a
                class="header__performance"
                [routerLink]="'/performance/' + id"
                title="Performance Insights"
                ><mat-icon>leaderboard</mat-icon></a
            >
        </ng-container>
        <a
            class="header__code"
            [routerLink]="'/embed-generator/' + id"
            target="_blank"
            title="Click to generate embed code for this unit"
            ><mat-icon>code</mat-icon></a
        >
        <button
            class="header__qr-code"
            (click)="getQrCode()"
            title="Click to generate qr-code"
        ></button>
        <a
            class="header__open-in-new"
            [routerLink]="'/standalone/' + id"
            target="_blank"
            title="Click to open in isolation"
            ><mat-icon>open_in_new</mat-icon></a
        >
    </ng-container>
</mat-toolbar>

<!-- Our Reports Menu-->
<mat-menu [overlapTrigger]="false" #reportsMenu="matMenu" class="menuSelect">
    <button class="menuSelect__item" mat-menu-item (click)="goTo('/impressions', true)">
        <mat-icon>trending_up</mat-icon>Impressions
    </button>
    <button class="menuSelect__item" mat-menu-item (click)="goTo('/benchmarks', true)">
        <mat-icon>insights</mat-icon>Benchmark
    </button>
    <button
        *ngIf="can('all')"
        class="menuSelect__item"
        mat-menu-item
        (click)="goToCurrentDate('audit')"
    >
        <mat-icon>stacked_bar_chart</mat-icon>Audit
    </button>
</mat-menu>

<!-- Our Manage Menu-->
<mat-menu [overlapTrigger]="false" #manageMenu="matMenu" class="menuSelect">
    <button
        *ngIf="can('manage.products')"
        (click)="goTo('/products')"
        class="menuSelect__item"
        mat-menu-item
    >
        <mat-icon>apps</mat-icon>Products
    </button>
    <button
        *ngIf="can('manage.products')"
        (click)="goTo('/formats')"
        class="menuSelect__item"
        mat-menu-item
    >
        <mat-icon>widgets</mat-icon>
        <span>Formats</span>
    </button>
    <button
        *ngIf="can('manage.users')"
        (click)="goTo('/users')"
        class="menuSelect__item"
        mat-menu-item
    >
        <mat-icon>person</mat-icon>Users
    </button>
    <mat-divider *ngIf="can('manage.groups')"></mat-divider>
    <button *ngIf="can('all')" (click)="goTo('/companies')" class="menuSelect__item" mat-menu-item>
        <mat-icon>business</mat-icon>Companies
    </button>
    <button
        *ngIf="can('manage.groups')"
        (click)="goTo('/groups')"
        class="menuSelect__item"
        mat-menu-item
    >
        <mat-icon>group</mat-icon>Groups
    </button>
</mat-menu>

<!-- Our Manage Menu-->
<mat-menu [overlapTrigger]="false" #purgeMenu="matMenu" class="menuSelect">
    <button *ngIf="can('all')" (click)="purgeByRender()" class="menuSelect__item" mat-menu-item>
        <mat-icon>sync</mat-icon>
        Render Static Assets
    </button>
    <button *ngIf="can('all')" (click)="purgeByHostname()" class="menuSelect__item" mat-menu-item>
        <mat-icon>sync</mat-icon>
        Hostname
    </button>
    <button
        *ngIf="can('manage.ads')"
        (click)="purgeByPath()"
        class="menuSelect__item"
        mat-menu-item
    >
        <mat-icon>sync</mat-icon>
        URL
    </button>
</mat-menu>

<!-- Our Help Menu -->
<mat-menu [overlapTrigger]="false" #helpMenu="matMenu" class="menuSelect">
    <button class="menuSelect__item" mat-menu-item (click)="goTo('/help/metrics')">Metrics</button>
    <button class="menuSelect__item" mat-menu-item (click)="goTo('/help/products')">
        Products
    </button>
    <button class="menuSelect__item" mat-menu-item (click)="goTo('/help/features')">
        Features
    </button>
    <button class="menuSelect__item" mat-menu-item (click)="goTo('/help/api')">API</button>
</mat-menu>

<!-- Our About Menu -->
<mat-menu [overlapTrigger]="false" #aboutMenu="matMenu" class="menuSelect">
    <button class="menuSelect__item" mat-menu-item (click)="goTo('/about/privacy', false, true)">
        Privacy Policy
    </button>
    <button mat-menu-item (click)="openVersion('platform')">Platform Version</button>
    <button mat-menu-item (click)="openVersion('render')">Render Version</button>
</mat-menu>

<!-- Our Cog Menu -->
<mat-menu [overlapTrigger]="false" #settingsMenu="matMenu" class="menuSelect">
    <button class="menuSelect__item" mat-menu-item (click)="profile()">
        <mat-icon>account_circle</mat-icon>My Profile
    </button>
    <button class="menuSelect__item" mat-menu-item (click)="logout()">
        <mat-icon>reply</mat-icon>Logout
    </button>
</mat-menu>

<ng-template #qrCodeDialog>
    <h2 matDialogTitle>QR Code</h2>
    <mat-dialog-content>
        <p>
            <img [src]="qrCode" alt="" />
        </p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose color="primary">Close</button>
    </mat-dialog-actions>
</ng-template>

<!-- Our Test Menu -->
<mat-menu [overlapTrigger]="false" #testMenu="matMenu" class="menuSelect">
    <button class="menuSelect__item" mat-menu-item (click)="linkTo('env')">Environment</button>
    <button mat-menu-item [matMenuTriggerFor]="logs">Logs</button>
    <button class="menuSelect__item" mat-menu-item (click)="linkTo('routes')">Routes</button>
    <button class="menuSelect__item" mat-menu-item (click)="linkTo('phpinfo')">PHPInfo</button>
</mat-menu>

<!-- Logs -->
<mat-menu #logs="matMenu">
    <button mat-menu-item [matMenuTriggerFor]="platform">Platform</button>
    <button mat-menu-item [matMenuTriggerFor]="render">Render</button>
</mat-menu>

<!-- Logs Platform -->
<mat-menu #platform="matMenu">
    <button class="menuSelect__item" mat-menu-item (click)="linkTo('logs/today')">
        Today Logs
    </button>
    <button mat-menu-item (click)="openLogModal('platform')">Specific Date</button>
</mat-menu>

<!-- Logs Render -->
<mat-menu #render="matMenu">
    <button class="menuSelect__item" mat-menu-item (click)="linkTo('logs/today', 'render')">
        Today Logs
    </button>
    <button mat-menu-item (click)="openLogModal('render')">Specific Date</button>
</mat-menu>
