<div class="panel panel--options">
    <div class="panel__title">
        <span>Preview Options</span>
        <mat-icon (click)="close()">minimize</mat-icon>
    </div>
    <div class="panel__container">
        <p class="title">Creative Position</p>
        <mat-button-toggle-group
            [(ngModel)]="position"
            name="creativePosition"
            (change)="onPositionUpdate($event)"
            aria-label="Creative Position"
        >
            <mat-button-toggle [value]="'top'">Top</mat-button-toggle>
            <mat-button-toggle [value]="'middle'">Middle</mat-button-toggle>
            <mat-button-toggle [value]="'bottom'">Bottom</mat-button-toggle>
        </mat-button-toggle-group>
        <!-- <mat-tab-group [selectedIndex]="0">
            <mat-tab label="Inline">
                <h1>Creative Position</h1>
                <mat-button-toggle-group
                    [(ngModel)]="position"
                    name="creativePosition"
                    aria-label="Creative Position">
                    <mat-button-toggle [value]="'above'">Above the fold</mat-button-toggle>
                    <mat-button-toggle [value]="'below'">Below the fold</mat-button-toggle>
                </mat-button-toggle-group>
            </mat-tab>
            <mat-tab label="Sticky">
                <h1>Placement Options</h1>
                <mat-radio-group [(ngModel)]="placement">
                    <mat-radio-button [value]="'top'">Top</mat-radio-button>
                    <mat-radio-button [value]="'bottom'">Bottom</mat-radio-button>
                </mat-radio-group>
                <br>
                <mat-checkbox [(ngModel)]="dismiss">Show dismiss button</mat-checkbox>
            </mat-tab>
        </mat-tab-group> -->
    </div>
</div>
