import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'og-p-rm-native-banner',
  templateUrl: './native-banner.component.html'
})
export class PreviewNativeBannerComponent implements OnInit {
  @Output() formChange = new EventEmitter();
  form: FormGroup;
  name = '[NAME]';

  constructor(private _formBuilder: FormBuilder) {}

  ngOnInit() {
    this.form = this._formBuilder.group({
      name: ['[NAME]', Validators.required]
    });
    this.form.statusChanges.subscribe((val) => this.sendFormData(val));
    this.sendFormData();
  }

  /**
   * Sends form data
   * @param val
   */
  sendFormData(val?) {
    if (this.form.valid) {
      this.formChange.emit({
        data: this.form.value
      });
    }
  }

  /**
   * Returns error
   * @param controlName
   * @param errorMsg
   */
  private getControlError(controlName, errorMsg?) {
    return this.form.get(controlName).errors['required'] ? 'Field Required' : errorMsg;
  }
}
