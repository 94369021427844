import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'og-p-rm-universal-reveal',
  templateUrl: './universal-reveal.component.html'
})
export class PreviewUniversalRevealComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
