<div [hidden]="!ready.init && !ready.loaded" class="video-container">
    <video
        *ngIf="ready.player"
        id="video-adunit"
        class="video-js vjs-default-skin vjs-16-9 vjs-big-play-centered"
        controls
        preload="auto"
        playsinline
        width="736"
        height="414"
        poster="https://vjs.zencdn.net/v/oceans.png"
        [attr.autoplay]="video === 'autoplay' ? true : null"
        [attr.muted]="video === 'autoplay' ? true : null"
    >
        <source src="https://vjs.zencdn.net/v/oceans.mp4" type="video/mp4" />
        <source src="https://vjs.zencdn.net/v/oceans.webm" type="video/webm" />
        <source src="https://vjs.zencdn.net/v/oceans.ogv" type="video/ogg" />
        <p class="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading to a web browser
            that
            <a href="http://videojs.com/html5-video-support/" target="_blank"
                >supports HTML5 video</a
            >
        </p>
    </video>
</div>
