<div class="purge-cdn-path">
    <div class="purge-cdn-path__input">
        <h2 mat-dialog-title>PURGE CDN PATH</h2>
        <mat-divider></mat-divider>
        <br />
        <mat-form-field class="purge-cdn-path__input-field">
            <input [formControl]="inputRequired" matInput placeholder="Path" required />
            <mat-error *ngIf="inputRequired.invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <mat-dialog-actions class="purge-cdn-path__actions">
        <button mat-raised-button color="warn" mat-dialog-close>Cancel</button>
        <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
        <button (click)="onPurgePath()" mat-raised-button color="primary">Send</button>
    </mat-dialog-actions>
</div>
