import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { saveAs } from 'file-saver';
import { EnvironmentService } from '../../../common/services/environment.service';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class EmbedGeneratorService {
  /**
   * [Private Variables]
   */
  private _tagVersion = 3;
  private _baseUrl = '';
  private _env = 'prd';

  /**
   * [Constructor]
   */
  constructor(
    private envService: EnvironmentService,
    private http: HttpClient
  ) {
    this._baseUrl = envService.getRenderUrl();
    this._env = envService.getEnvironment();
  }

  /**
   * [Private Methods]
   */

  /**
   * Generates embed code
   * @param params
   */
  generate(params: any): any {
    let adServerProperties = null;
    const supportedEnvironments = ['Web Browser', 'VAST', 'VPAID', 'MRAID'];

    if (params.ad.id === null) {
      return '';
    }

    if (!supportedEnvironments.includes(params.properties.environment)) {
      throw Error(params.properties.environment + ' Environment is not supported!');
    }

    switch (params.properties.adserver) {
      case 'Ad Manager':
      default:
        adServerProperties = this._getAdManagerProperties(params);
        break;
    }

    switch (params.properties.environment) {
      case 'Web Browser':
      default:
        if (params.ad.creative.format.type.name === 'amp') {
          return this._getAMP(adServerProperties);
        }
        return this._generateCode(adServerProperties);
      case 'VAST':
      case 'VPAID':
        return this._generateTag(params.properties.environment, adServerProperties);
    }
  }

  /**
   * Executes the download file fn according to the generated data
   * @param params
   */
  download(params: any) {
    const code = this.generate(params);

    if (code instanceof Observable) {
      code.subscribe((tag) => {
        this._downloadFile(tag, params);
      });
    } else {
      this._downloadFile(code, params);
    }
  }

  /*
   * Removed this from embed code
   * params.clientTimestamp = new Date / 1000;
   * params.clientTimeZoneOffsetInMinutes = new Date().getTimezoneOffset();
   * params.hostPageLoadId = window.originHostPageLoadId = window.originHostPageLoadId || (Math.random() + '').slice(2);
   * */

  /**
   * Generates the necessary properties for
   * the Ad Manager
   * @param params
   * @private
   * @return object
   */
  private _getAdManagerProperties(params) {
    const properties = {
      id: params.ad.id,
      debug: !_.isUndefined(params.debug) ? params.debug : false,
      adserver: 'DFP',
      overrides: {},
      environment: params.properties.environment
    };

    if (!_.isUndefined(params.extras) && !_.isEmpty(params.extras)) {
      properties['extras'] = params.extras.data;
    }

    if (!_.isUndefined(params.overrides) && !_.isEmpty(params.overrides)) {
      properties['overrides'] = params.overrides;
    }

    return properties;
  }

  /**
   * Generates the embed code
   * according to the provided props
   * @param props
   * @private
   * @return string
   */
  private _generateCode(props) {
    const adId = props.id;
    const adServer = props.adserver;
    const environment = props.environment;
    const script = props.environment === 'MRAID' ? 'mraid/mraid' : 'loader/loader';
    const tagVersion = this._tagVersion;
    const debug = window.location.href.indexOf('/embed-generator/') <= -1 ? props.debug : false;
    let extended = ``;
    const schedule =
      !_.isUndefined(props.overrides.schedule) &&
      props.overrides.schedule !== null &&
      props.overrides.schedule !== ''
        ? `'schedule': '${props.overrides.schedule}',`
        : '';

    if (!_.isUndefined(props.extras)) {
      let counter = 0;
      _.each(props.extras, (v, k) => {
        if (counter > 0) {
          extended += `\t\t\  `;
        }
        extended += `'${k}': '${v}',`;
        if (counter + 1 < _.size(props.extras)) {
          extended += `\n`;
        }
        counter++;
      });
    }

    // depending on the format
    // we can add more properties
    // to the "params" variable
    // in the following code:

    return `
    <!-- Begin - Origin v3 Tag -->
    ${environment === 'MRAID' ? '<script src="mraid.js"></script>' : ''}
    <div class="origin-ad-v3">
      <img
          src="data:image/png,origin"
          style="display: none;"
          onerror="
            (function(img) {
              var params = {
                  ${schedule}
                  ${extended}
                  'adId': ${adId},
                  'externalAdServer': '${adServer}',
                  'tagVersion': ${tagVersion},
                  'debug': ${debug}
              };
              [].slice.apply(img.parentNode.childNodes).forEach(function(n) {
                var decfs = {
                  urldecode: decodeURIComponent,
                  htmldecode: function(v) {
                    var d = document.createElement('div');
                    d.innerHTML = v;
                    return d.textContent;
                  },
                  eval: function(v) {
                    return eval(v);
                  },
                  raw: function(v) {
                    return v;
                  }
                };
                var m;
                if (n.nodeType == 8 &amp;&amp; (m = n.textContent.match(/^&#92;s+([&#92;w.]+)(&#92;[.+&#92;])?&#92;s+=&#92;s+(&#92;w+)&#92;s+(.*)$/i))) {
                    try {
                        params[m[1]+(m[2] || '')] = decfs[m[3]](m[4].replace(/^&#92;s+|&#92;s+$/g, ''));
                    } catch (e) {}
                }
              });
              var req = document.createElement('script');
              req.id = params.scriptId = 'origin-script-' + (window.originScriptIndex = (window.originScriptIndex || 0) + 1);
              var qs = '';
              for (var k in params) {
                  qs += '&amp;' + encodeURIComponent(k) + '=' + encodeURIComponent(params[k]);
              }
              var src = '${this._baseUrl}build/${script}.min.js?' + qs;
              req.src = src;
              img.parentNode.insertBefore(req, img.nextSibling);
          })(this);
      "/>
  </div>
  <!-- End - Origin v3 Tag -->
    `;
  }

  /**
   * Generates tag
   * according to the provided props
   * @param env
   * @param props
   * @private
   */
  private _generateTag(env, props) {
    const debug = window.location.href.indexOf('/embed-generator/') <= -1 ? props.debug : false;
    const schedule =
      !_.isUndefined(props.overrides.schedule) &&
      props.overrides.schedule !== null &&
      props.overrides.schedule !== ''
        ? props.overrides.schedule
        : '';
    const videoUrl = new URL(`${this._baseUrl}video/${env.toLowerCase()}/${props.id}`);

    if (window.location.href.indexOf('/embed-generator/') <= -1) {
      videoUrl.searchParams.set('debug', debug);

      if (schedule !== '') {
        videoUrl.searchParams.set('schedule', schedule);
      }
    }

    return videoUrl.toString();
  }

  /**
   * Return AMP Html
   * @param props
   * @private
   */
  private _getAMP(props): Observable<any> {
    const debug = window.location.href.indexOf('/embed-generator/') <= -1 ? props.debug : false;
    const schedule =
      !_.isUndefined(props.overrides.schedule) &&
      props.overrides.schedule !== null &&
      props.overrides.schedule !== ''
        ? props.overrides.schedule
        : '';
    const ampUrl = new URL(`${this._baseUrl}amphtml/${props.id}`);
    ampUrl.searchParams.set('debug', debug);

    if (schedule !== '') {
      ampUrl.searchParams.set('schedule', schedule);
    }

    return this.http.get(ampUrl.toString(), { responseType: 'text' }).pipe(
      tap((response) => {
        return response;
      })
    );
  }

  /**
   * [Public Methods]
   */

  /**
   * Converts string to slug
   * @param text
   */
  private slugify(text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  }

  /**
   * Generates txt file with embed code and downloads i
   * @param embedCode
   * @param params
   * @private
   */
  private _downloadFile(embedCode, params) {
    const filename = this.slugify(
      params.ad.jiraid + '_' + params.ad.brand.name + '_' + params.ad.title
    );
    const file = new File([embedCode], filename + '.txt', {
      type: 'text/plain;charset=utf-8'
    });

    saveAs(file);
  }
}
