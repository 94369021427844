<div class="panel">
    <div class="panel__title">
        <span>Device Options</span>
        <mat-icon (click)="close()">minimize</mat-icon>
    </div>
    <div class="panel__container">
        <mat-tab-group
            [@.disabled]="true"
            [selectedIndex]="getSelectedIndex()"
            (selectedIndexChange)="onDeviceSelected($event)"
        >
            <mat-tab label="Phone">
                <div class="size-toggler">
                    <mat-button-toggle-group
                        name="previewSize"
                        (change)="onSizeSelected($event)"
                        [value]="deviceSize"
                    >
                        <mat-button-toggle [disableRipple]="true" value="small"
                            >S</mat-button-toggle
                        >
                        <mat-button-toggle [disableRipple]="true" value="medium"
                            >M</mat-button-toggle
                        >
                        <mat-button-toggle [disableRipple]="true" value="large"
                            >L</mat-button-toggle
                        >
                    </mat-button-toggle-group>
                </div>
            </mat-tab>
            <mat-tab label="Tablet">
                <div class="size-toggler">
                    <mat-button-toggle-group
                        name="previewSize"
                        (change)="onSizeSelected($event)"
                        [value]="deviceSize"
                    >
                        <mat-button-toggle [disableRipple]="true" value="small"
                            >S</mat-button-toggle
                        >
                        <mat-button-toggle [disableRipple]="true" value="medium"
                            >M</mat-button-toggle
                        >
                        <mat-button-toggle [disableRipple]="true" value="large"
                            >L</mat-button-toggle
                        >
                    </mat-button-toggle-group>
                </div>
            </mat-tab>
            <mat-tab label="Desktop"> </mat-tab>
        </mat-tab-group>
        <div class="description">
            <p>View Size:</p>
            <p>{{ getCurrentSize() }}</p>
        </div>
    </div>
</div>
