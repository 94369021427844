import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'og-floating-menu',
  templateUrl: './floating-menu.component.html'
})
export class FloatingMenuComponent implements OnInit {
  @Input() canAll: boolean;
  @Input() canReports: boolean;
  @Input() canProducts: boolean;
  @Input() canUsers: boolean;
  @Input() canAds: boolean;
  @Input() canGroups: boolean;
  @Input() isVisible: boolean;
  @Input() purgeCallbacks: any;
  @Input() accountCallbacks: any;
  @Input() aboutCallbacks: any;
  @Input() linkTo: any;
  @Output() navigateTo: EventEmitter<any> = new EventEmitter();
  @Output() onMenuClosed: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  /**
   * Emit an event to tell our parent to navigate to another route.
   * @param route
   * @param hasDates
   * @param newWindow
   */
  goTo(route, hasDates?, newWindow?) {
    this.navigateTo.emit({
      route: route,
      hasDates: hasDates,
      newWindow: newWindow
    });
  }

  /**
   * Close the menu
   */
  closeMenu() {
    this.onMenuClosed.emit();
  }
}
