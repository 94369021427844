import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material.module';

// Services
import { AdService } from '../private/resources/ad.service';
import { RESTService } from './services/rest.service';
import { EnvironmentService } from './services/environment.service';
import { PurgeService } from '../private/resources/purge.service';
import { HelperService } from './services/helper.service';
import { ShowcaseService } from '../private/components/showcase/showcase.service';
import { VideoEventsService } from './services/video-events.service';

// Components
import { NotFoundComponent } from './components/not-found/not-found.component';
import { HeaderComponent } from './components/header/header.component';
import { ShowcaseComponent } from '../private/components/showcase/showcase.component';
import { PurgeCDNPathComponent } from '../private/components/purge-cdn-path/purge-cdn-path.component';
import { ProfileComponent } from '../private/components/profile/profile.component';
import { ForgotPasswordComponent } from '../public/components/forgot-password/forgot-password.component';
import { JsonEditorComponent } from './components/json-editor/json-editor.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { SiteFrameComponent } from './components/site-frame/site-frame.component';
import { AdUnitComponent } from './components/ad-unit/ad-unit.component';
import { AdCloneComponent } from '../private/components/dashboard/ad-clone/ad-clone.component';
import { FloatingMenuComponent } from './components/floating-menu/floating-menu.component';
import { RouteChangeNotifyComponent } from '../public/components/route-change-notify/route-change-notify.component';
import { VideoAdComponent } from './components/video-ad/video-ad.component';
import { AboutVersionComponent } from '../private/components/about-version/about-version.component';
import { LogDateComponent } from '../private/components/log-date/log-date.component';

// Pipes
import { MaxLengthPipe } from './pipes/max-length.pipe';
import { GetUppercaseLettersPipe } from './pipes/get-uppercase-letters.pipe';
import { GetTimeSpentPipe } from './pipes/get-timespent.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { FilterUniquePipe } from './pipes/filter-unique.pipe';
import { OrderByPipe } from './pipes/orderBy.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { PrettyjsonPipe } from './pipes/prettyjson.pipe';

@NgModule({
  declarations: [
    NotFoundComponent,
    HeaderComponent,
    ShowcaseComponent,
    PurgeCDNPathComponent,
    ProfileComponent,
    ForgotPasswordComponent,
    JsonEditorComponent,
    MaxLengthPipe,
    GetTimeSpentPipe,
    GetUppercaseLettersPipe,
    SafePipe,
    SanitizeHtmlPipe,
    LoadingIndicatorComponent,
    SiteFrameComponent,
    AdUnitComponent,
    FileSizePipe,
    FilterUniquePipe,
    FloatingMenuComponent,
    RouteChangeNotifyComponent,
    OrderByPipe,
    AdCloneComponent,
    VideoAdComponent,
    PrettyjsonPipe,
    AboutVersionComponent,
    LogDateComponent
  ],
  imports: [
    MaterialModule,
    CommonModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule
  ],
  providers: [
    RESTService,
    PurgeService,
    EnvironmentService,
    AdService,
    HelperService,
    ShowcaseService,
    VideoEventsService
  ],
  entryComponents: [
    ShowcaseComponent,
    PurgeCDNPathComponent,
    ProfileComponent,
    ForgotPasswordComponent,
    FloatingMenuComponent,
    RouteChangeNotifyComponent,
    LoadingIndicatorComponent,
    AdCloneComponent,
    AboutVersionComponent,
    LogDateComponent
  ],
  exports: [
    NotFoundComponent,
    HeaderComponent,
    MaterialModule,
    JsonEditorComponent,
    MaxLengthPipe,
    GetTimeSpentPipe,
    GetUppercaseLettersPipe,
    SafePipe,
    SanitizeHtmlPipe,
    LoadingIndicatorComponent,
    SiteFrameComponent,
    AdUnitComponent,
    FloatingMenuComponent,
    RouteChangeNotifyComponent,
    FileSizePipe,
    FilterUniquePipe,
    OrderByPipe,
    PrettyjsonPipe
  ]
})
export class OriginCommonModule {}
