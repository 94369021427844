<ng-container *ngIf="id !== null && ad.creative.json !== null">
    <section
        [ngClass]="{
            site: true,
            phone: device === 'phone',
            desktop: device === 'desktop'
        }"
    >
        <header
            [ngClass]="{
                fixed: header === 'fixed'
            }"
        >
            <div class="brand-logo"></div>
            <div class="brand-name"></div>
            <div class="mobile-menu-button">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </div>
        </header>
        <div class="header-offset" *ngIf="header === 'fixed'"></div>

        <!-- Begin - Embed Code -->
        <div
            class="ad-placeholder-wrapper show-overflow"
            *ngIf="ready && pos === 'top'; else other_leaderboard"
        >
            <og-ad-unit
                [format]="ad.creative.format"
                [sizing]="ad.creative.sizing"
                [embedCode]="embedCode"
                [adtype]="getUnitType(ad.creative.format)"
                [video]="video"
            ></og-ad-unit>
        </div>
        <ng-template #other_leaderboard>
            <div
                [ngClass]="{
                    'ad-placeholder-wrapper': true,
                    'ad-placeholder-wrapper--320x50': device === 'phone',
                    'ad-placeholder-wrapper--728x90': device === 'desktop'
                }"
            >
                <div class="placeholder">[LEADERBOARD] placeholder</div>
            </div>
        </ng-template>
        <!-- End - Embed Code -->

        <div class="content clearfix">
            <main>
                <article class="first">
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-5"></div>
                        <div class="text-line width-8"></div>
                        <div class="text-line width-8"></div>
                        <div class="text-line width-5"></div>
                        <div class="text-line width-5"></div>
                        <div class="text-line width-9"></div>
                        <div class="text-line width-5"></div>
                        <div class="text-line width-2"></div>
                    </div>
                </article>
                <article class="second">
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-8"></div>
                        <div class="text-line width-8"></div>
                        <div class="text-line width-5"></div>
                        <div class="text-line width-2"></div>
                    </div>
                </article>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-7"></div>
                        <div class="text-line width-5"></div>
                        <div class="text-line width-1"></div>
                    </div>
                </article>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-5"></div>
                        <div class="text-line width-7"></div>
                        <div class="text-line width-2"></div>
                    </div>
                </article>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-8"></div>
                        <div class="text-line width-8"></div>
                        <div class="text-line width-3"></div>
                    </div>
                </article>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-8"></div>
                        <div class="text-line width-7"></div>
                        <div class="text-line width-2"></div>
                    </div>
                </article>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-5"></div>
                        <div class="text-line width-9"></div>
                        <div class="text-line width-1"></div>
                    </div>
                </article>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-8"></div>
                        <div class="text-line width-5"></div>
                        <div class="text-line width-1"></div>
                    </div>
                </article>

                <!-- Begin - Embed Code -->
                <div
                    class="ad-placeholder-wrapper show-overflow with-margin"
                    *ngIf="ready && pos === 'middle'; else other_inarticle"
                >
                    <og-ad-unit
                        [format]="ad.creative.format"
                        [sizing]="ad.creative.sizing"
                        [embedCode]="embedCode"
                        [adtype]="getUnitType(ad.creative.format)"
                        [video]="video"
                    ></og-ad-unit>
                </div>
                <ng-template #other_inarticle>
                    <div class="ad-placeholder-wrapper ad-placeholder-wrapper--300x600">
                        <div class="placeholder">[IN ARTICLE] placeholder</div>
                    </div>
                </ng-template>
                <!-- End - Embed Code -->

                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-6"></div>
                        <div class="text-line width-8"></div>
                        <div class="text-line width-4"></div>
                    </div>
                </article>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-5"></div>
                        <div class="text-line width-7"></div>
                        <div class="text-line width-1"></div>
                    </div>
                </article>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-7"></div>
                        <div class="text-line width-6"></div>
                        <div class="text-line width-2"></div>
                    </div>
                </article>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-9"></div>
                        <div class="text-line width-9"></div>
                        <div class="text-line width-3"></div>
                    </div>
                </article>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-6"></div>
                        <div class="text-line width-6"></div>
                        <div class="text-line width-3"></div>
                    </div>
                </article>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-7"></div>
                        <div class="text-line width-5"></div>
                        <div class="text-line width-3"></div>
                    </div>
                </article>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-7"></div>
                        <div class="text-line width-9"></div>
                        <div class="text-line width-1"></div>
                    </div>
                </article>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-8"></div>
                        <div class="text-line width-7"></div>
                        <div class="text-line width-4"></div>
                    </div>
                </article>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-7"></div>
                        <div class="text-line width-7"></div>
                        <div class="text-line width-1"></div>
                    </div>
                </article>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-6"></div>
                        <div class="text-line width-9"></div>
                        <div class="text-line width-2"></div>
                    </div>
                </article>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-5"></div>
                        <div class="text-line width-5"></div>
                        <div class="text-line width-2"></div>
                    </div>
                </article>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-5"></div>
                        <div class="text-line width-6"></div>
                        <div class="text-line width-2"></div>
                    </div>
                </article>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-9"></div>
                        <div class="text-line width-6"></div>
                        <div class="text-line width-3"></div>
                    </div>
                </article>
            </main>
            <aside>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-9"></div>
                        <div class="text-line width-8"></div>
                        <div class="text-line width-1"></div>
                    </div>
                </article>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-9"></div>
                        <div class="text-line width-6"></div>
                        <div class="text-line width-1"></div>
                    </div>
                </article>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-8"></div>
                        <div class="text-line width-7"></div>
                        <div class="text-line width-4"></div>
                    </div>
                </article>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-5"></div>
                        <div class="text-line width-6"></div>
                        <div class="text-line width-2"></div>
                    </div>
                </article>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-9"></div>
                        <div class="text-line width-7"></div>
                        <div class="text-line width-2"></div>
                    </div>
                </article>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-5"></div>
                        <div class="text-line width-6"></div>
                        <div class="text-line width-3"></div>
                    </div>
                </article>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-6"></div>
                        <div class="text-line width-8"></div>
                        <div class="text-line width-4"></div>
                    </div>
                </article>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-7"></div>
                        <div class="text-line width-5"></div>
                        <div class="text-line width-4"></div>
                    </div>
                </article>
                <article>
                    <div class="image-placeholder"></div>
                    <div class="text-placeholder">
                        <div class="text-line width-10"></div>
                        <div class="text-line width-10"></div>
                        <div class="text-line width-9"></div>
                        <div class="text-line width-6"></div>
                        <div class="text-line width-4"></div>
                    </div>
                </article>
            </aside>

            <!-- Begin - Embed Code -->
            <div
                class="ad-placeholder-wrapper show-overflow"
                *ngIf="ready && pos === 'bottom'; else other_bottomad"
            >
                <og-ad-unit
                    [format]="ad.creative.format"
                    [sizing]="ad.creative.sizing"
                    [embedCode]="embedCode"
                    [adtype]="getUnitType(ad.creative.format)"
                    [video]="video"
                ></og-ad-unit>
            </div>
            <ng-template #other_bottomad>
                <div
                    [ngClass]="{
                        'ad-placeholder-wrapper': true,
                        'ad-placeholder-wrapper--320x50': device === 'phone',
                        'ad-placeholder-wrapper--970x250': device === 'desktop'
                    }"
                >
                    <div class="placeholder">[LEADERBOARD] placeholder</div>
                </div>
            </ng-template>
            <!-- End - Embed Code -->
        </div>

        <div class="notification-box">
            <div class="wrapper">
                <p>Scroll down to reveal creative.</p>
            </div>
        </div>
    </section>
</ng-container>
