<div class="panel">
    <div class="panel__title">
        <span>Simulate Contexts</span>
        <mat-icon (click)="close()">minimize</mat-icon>
    </div>
    <div class="panel__container">
        <mat-accordion>
            <mat-expansion-panel *ngFor="let ctx of contexts" [disabled]="ctx.disabled">
                <mat-expansion-panel-header [collapsedHeight]="'20px'" [expandedHeight]="'20px'">
                    <mat-panel-title>
                        {{ ctx.name | uppercase }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-expansion-panel-content>
                    <ng-container [ngSwitch]="ctx.name">
                        <div *ngSwitchCase="'Schedules'">
                            <mat-form-field floatLabel="never">
                                <input
                                    matInput
                                    [(ngModel)]="schedule"
                                    [matDatepicker]="dateTo"
                                    (ngModelChange)="onScheduleChange($event)"
                                />
                                <mat-datepicker-toggle
                                    matSuffix
                                    [for]="dateTo"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #dateTo></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div *ngSwitchCase="'Placement'">
                            <div class="flex--row" *ngIf="creative.format.type.name === 'display'">
                                <div [ngSwitch]="creative.format.name">
                                    <og-p-d-banner
                                        *ngSwitchCase="'Banner'"
                                        [creative]="creative"
                                        [context]="context"
                                    ></og-p-d-banner>
                                    <og-p-d-expandable-banner
                                        *ngSwitchCase="'Expandable Banner'"
                                        [creative]="creative"
                                        [context]="context"
                                    ></og-p-d-expandable-banner>
                                    <og-p-d-reveal
                                        *ngSwitchCase="'Reveal'"
                                        [creative]="creative"
                                        [context]="context"
                                    ></og-p-d-reveal>
                                    <og-p-d-collapsable-banner
                                        *ngSwitchCase="'Collapsable Banner'"
                                        [creative]="creative"
                                        [context]="context"
                                    ></og-p-d-collapsable-banner>
                                </div>
                            </div>

                            <div
                                class="flex--row"
                                *ngIf="creative.format.type.name === 'richmedia'"
                            >
                                <div [ngSwitch]="creative.format.name">
                                    <og-p-rm-banner
                                        *ngSwitchCase="'Banner'"
                                        [creative]="creative"
                                        [context]="context"
                                    ></og-p-rm-banner>
                                    <og-p-rm-expandable-banner
                                        *ngSwitchCase="'Expandable Banner'"
                                        [creative]="creative"
                                        [context]="context"
                                    ></og-p-rm-expandable-banner>
                                    <og-p-rm-interscroller
                                        *ngSwitchCase="'Interscroller'"
                                        (formChange)="onSubFormChanged($event)"
                                        [creative]="creative"
                                        [context]="context"
                                    ></og-p-rm-interscroller>
                                    <og-p-rm-interstitial
                                        *ngSwitchCase="'Interstitial'"
                                        [creative]="creative"
                                        [context]="context"
                                    ></og-p-rm-interstitial>
                                    <og-p-rm-reveal
                                        *ngSwitchCase="'Reveal'"
                                        [creative]="creative"
                                        [context]="context"
                                    ></og-p-rm-reveal>
                                    <og-p-rm-universal-banner
                                        *ngSwitchCase="'Universal Banner'"
                                        [creative]="creative"
                                        [context]="context"
                                    ></og-p-rm-universal-banner>
                                    <og-p-rm-universal-expandable
                                        *ngSwitchCase="'Universal Expandable'"
                                        [creative]="creative"
                                        [context]="context"
                                    ></og-p-rm-universal-expandable>
                                    <og-p-rm-universal-miniscroller
                                        *ngSwitchCase="'Universal Miniscroller'"
                                        [creative]="creative"
                                        [context]="context"
                                    ></og-p-rm-universal-miniscroller>
                                    <og-p-rm-universal-reveal
                                        *ngSwitchCase="'Universal Reveal'"
                                        [creative]="creative"
                                        [context]="context"
                                    ></og-p-rm-universal-reveal>
                                    <og-p-rm-overture
                                        *ngSwitchCase="'Overture'"
                                        [creative]="creative"
                                        [context]="context"
                                    ></og-p-rm-overture>
                                    <og-p-rm-collapsable-banner
                                        *ngSwitchCase="'Collapsable Banner'"
                                        [creative]="creative"
                                        [context]="context"
                                    ></og-p-rm-collapsable-banner>
                                    <og-p-rm-native-banner
                                        *ngSwitchCase="'Native Banner'"
                                        [creative]="creative"
                                        (formChange)="onSubFormChanged($event)"
                                        [context]="context"
                                    ></og-p-rm-native-banner>
                                </div>
                            </div>

                            <div class="flex--row" *ngIf="creative.format.type.name === 'video'">
                                <div [ngSwitch]="creative.format.name">
                                    <og-p-v-interscroller
                                        *ngSwitchCase="'Video Interscroller'"
                                        [creative]="creative"
                                        (formChange)="onSubFormChanged($event)"
                                        [context]="context"
                                    ></og-p-v-interscroller>
                                    <og-p-v-landscape-in-article
                                        *ngSwitchCase="'Landscape In-article'"
                                        [creative]="creative"
                                        (formChange)="onSubFormChanged($event)"
                                        [context]="context"
                                    ></og-p-v-landscape-in-article>
                                    <og-p-v-square-in-article
                                        *ngSwitchCase="'Square In-article'"
                                        [creative]="creative"
                                        (formChange)="onSubFormChanged($event)"
                                        [context]="context"
                                    ></og-p-v-square-in-article>
                                    <og-p-v-vertical-in-article
                                        *ngSwitchCase="'Vertical In-article'"
                                        [creative]="creative"
                                        (formChange)="onSubFormChanged($event)"
                                        [context]="context"
                                    ></og-p-v-vertical-in-article>
                                </div>
                            </div>
                        </div>
                        <span *ngSwitchDefault> Dummy Content </span>
                    </ng-container>
                </mat-expansion-panel-content>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
