<div class="header-report">
    <div class="header-report__content flex-set flex--content-between flex--align-items-center">
        <h3>Products Help</h3>
        <mat-form-field>
            <mat-select [(ngModel)]="adVersion" placeholder="Ad Version">
                <mat-option value="v2">Version 2</mat-option>
                <mat-option value="v3">Version 3</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

<div class="help help-container products-help">
    <mat-card>
        <mat-card-content>
            For more information about Evolve Media products, visit our
            <a href="https://adgallery.evolvemediallc.com/#/" target="_blank">Ad Gallery</a> and
            <a href="https://contentgallery.evolvemediallc.com/#/" target="_blank"
                >Content Gallery</a
            >.
        </mat-card-content>
    </mat-card>

    <mat-expansion-panel *ngFor="let type of getTypes(types)" class="primary-panel">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ type.label }}
            </mat-panel-title>
            <mat-panel-description>
                {{ getProducts(type.products).length > 0 ? 'Products' : 'Formats' }}
            </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-container *ngFor="let companyProducts of getProducts(type.products)">
            <mat-expansion-panel *ngIf="companyProducts.length > 0" class="company-panel">
                <mat-expansion-panel-header class="company-panel__header">
                    {{ companyProducts[0].company.name }}
                </mat-expansion-panel-header>

                <ng-container>
                    <mat-expansion-panel *ngFor="let prod of companyProducts">
                        <mat-expansion-panel-header>
                            {{ prod.name }}
                        </mat-expansion-panel-header>

                        {{ prod.description }}
                        <mat-divider class="company-panel__divider"></mat-divider>

                        <mat-list>
                            <h3 mat-subheader>States</h3>
                            <mat-list-item *ngFor="let st of prod.states">
                                <mat-icon mat-list-icon>picture_in_picture</mat-icon>
                                <h4 mat-line>
                                    {{ st.width + st.width_unit }} x
                                    {{ st.height + st.height_unit }} {{ st.placement }}
                                </h4>
                                <p mat-line class="state-note" *ngIf="st.trigger_time_spent === 1">
                                    Timer starts when the state loads and stops when the state is
                                    removed.
                                </p>
                            </mat-list-item>
                        </mat-list>
                    </mat-expansion-panel>
                </ng-container>
            </mat-expansion-panel>
        </ng-container>

        <ng-container *ngFor="let format of type.formats">
            <mat-expansion-panel class="format-panel">
                <mat-expansion-panel-header>
                    {{ format.name }}
                </mat-expansion-panel-header>

                {{ format.description }}
            </mat-expansion-panel>
        </ng-container>
    </mat-expansion-panel>

    <!--
    <div class="nav-container">
      <ul class="nav bs-docs-sidenav">
        <li *ngFor="let prod of products">
          <a (click)="scrollTo(parseToIdName(prod.name))">{{prod.name}}</a>
        </li>
      </ul>
    </div>
    -->
</div>
