import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'og-eg-v-square-in-article',
  templateUrl: './square-in-article.component.html'
})
export class EmbedSquareInArticleComponent implements OnInit {
  @Output() formChange = new EventEmitter();
  @Input('creative') creative: any;
  form: FormGroup;
  constructor(private _formBuilder: FormBuilder) {}

  ngOnInit() {
    this.form = this._formBuilder.group({
      widthBehavior: ['fullscreen', Validators.required]
    });
    this.form.statusChanges.subscribe((val) => this.sendFormData(val));
    this.sendFormData();
  }

  /**
   * Sends form data
   * @param val
   */
  sendFormData(val?) {
    if (this.form.valid) {
      this.formChange.emit({
        data: this.form.value
      });
    }
  }

  /**
   * Returns error
   * @param controlName
   * @param errorMsg
   */
  private getControlError(controlName, errorMsg?) {
    return this.form.get(controlName).errors['required'] ? 'Field Required' : errorMsg;
  }
}
