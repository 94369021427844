<h3 class="embed-generator__trafficking__title">DFP Premium Trafficking Instructions:</h3>
<ol class="embed-generator__trafficking__list">
    <li>On the Delivery tab, click Creatives in the left-hand navigation.</li>
    <li>
        <p>Click Add creatives.</p>
        <br />
        <ul>
            <li>
                a. Begin typing to search for the advertiser whose creative you're adding. Each
                creative is associated with one advertiser.
            </li>
            <li>b. When you've found the right advertiser, click Continue.</li>
        </ul>
    </li>
    <li>
        <p>Click Third-party.</p>
    </li>
    <li>Enter the name for your creative.</li>
    <li>Paste the Origin tag (below) into the Code snippet field.</li>
    <li>Select the Target ad unit size where the creative should deliver.</li>
    <li>
        <span style="font-weight: bold">IMPORTANT</span> - unless SafeFrame is available under the
        Origin Embed Generator, ensure that
        <span style="font-weight: bold">Serve into a SafeFrame</span> is unchecked. DFP now
        automatically selects this option by default and it must be manually disabled for mobile web
        tags.
    </li>
</ol>
