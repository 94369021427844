import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { HelpService } from '../../services/help.service';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as showdown from 'showdown';
import { forkJoin, Subscription } from 'rxjs';
import * as _ from 'lodash-es';

@Component({
  selector: 'og-help-api',
  templateUrl: './help-api.component.html',
  styleUrls: ['./help-api.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HelpApiComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];
  sidebar: any = [
    {
      label: 'Introduction',
      type: 'single',
      data: null
    }
  ];
  toggle = [];

  constructor(
    private helpService: HelpService,
    private progressService: ProgressIndicatorService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    const observers: any[] = [];
    const converter = new showdown.Converter();
    converter.setOption('tables', true);
    this.progressService.showBar();
    observers.push(this.helpService.getMainAPI());
    observers.push(this.helpService.getToolsAPI());

    this.subs[0] = forkJoin(observers).subscribe(
      (responses) => {
        _.each(responses[0]['data'], (docData, docKey) => {
          const data = converter.makeHtml(
            '<div class="document-item" markdown="1">' + docData + '</div>'
          );

          this.sidebar.push({
            label: docKey,
            type: 'single',
            data: this.sanitizer.bypassSecurityTrustHtml(data)
          });
        });
        this.sidebar.push({
          label: 'Tools',
          type: 'multi',
          data: null,
          items: []
        });
        _.each(responses[1]['data'], (toolData, toolKey) => {
          const data = converter.makeHtml(
            '<div class="document-item" markdown="1">' + toolData + '</div>'
          );

          this.sidebar[this.sidebar.length - 1]['items'].push({
            label: this.toLabel(toolKey),
            type: 'single',
            data: this.sanitizer.bypassSecurityTrustHtml(data)
          });
        });
      },
      // on error
      () => {},
      // on request complete
      () => {
        this.progressService.hideBar();
      }
    );
  }

  /**
   * slugifies string
   * @param str
   * @returns
   */
  slugify(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
    // remove accents, swap ñ for n, etc
    const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    const to = 'aaaaeeeeiiiioooouuuunc------';

    for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return str;
  }

  /**
   * Converts string to label
   * @param str
   * @returns
   */
  private toLabel(str) {
    const label = str.replace('-', ' ');
    return _.capitalize(label);
  }

  /**
   * Scrolls To view
   * @param item
   * @param i
   */
  scrollTo(item, i) {
    if (item.type === 'multi') {
      this.toggle[i] = !this.toggle[i];
    } else {
      const link = this.slugify(item.label);

      try {
        document.querySelector('#' + link).scrollIntoView();
      } catch (e) {}
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy() {
    _.each(this.subs, (s) => {
      if (!_.isUndefined(s)) {
        s.unsubscribe();
      }
    });
  }
}
