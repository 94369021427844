import { Pipe, PipeTransform } from '@angular/core';
/*
 * Substring the value
 * Remove extra chars and adds dots at the end.
 * Usage:
 *   'string' | maxlength:integer
 * Example:
 *   {{ 'This is a long long text' | maxlength:10 }}
 *   formats to: 'This is a ...'
 */
@Pipe({
  name: 'maxlength'
})
export class MaxLengthPipe implements PipeTransform {
  transform(value: string, max: number): string {
    try {
      if (value.length <= max) {
        return value;
      } else {
        return value.substr(0, max) + '...';
      }
    } catch (e) {
      return value === '' ? 'N/A' : value;
    }
  }
}
