import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'og-p-rm-overture',
  templateUrl: './overture.component.html'
})
export class PreviewOvertureComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
