<div class="modal-loading" *ngIf="!loaded || campaigns.length === 1 || sites.length === 0">
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
</div>

<ng-container *ngIf="loaded">
    <h2 mat-dialog-title>Demo on Showcase</h2>

    <mat-dialog-content *ngIf="error">
        <p>
            There was an error trying to connect to showcase, please try again later or contact
            support at: origintech@evolvemediallc.com
        </p>
    </mat-dialog-content>

    <mat-dialog-content *ngIf="!error && campaigns.length > 1 && !creativeExists">
        <form name="showcaseForm" #form="ngForm">
            <div class="flex-set flex--column">
                <ng-container *ngIf="!createCampaign">
                    <div class="flex--row">
                        <mat-form-field class="col-80">
                            <input
                                type="text"
                                placeholder="Select a Campaign"
                                matInput
                                [formControl]="campaign"
                                [matAutocomplete]="autocampaign"
                                required
                            />
                            <mat-autocomplete #autocampaign="matAutocomplete">
                                <mat-option
                                    *ngFor="let option of filteredCampaigns | async"
                                    [value]="option.name"
                                    [title]="option.name"
                                    >{{ option.name }}</mat-option
                                >
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="flex--row">
                        <button
                            mat-raised-button
                            color="primary"
                            [disabled]="campaign.invalid"
                            (click)="saveCreative()"
                        >
                            Save
                        </button>
                    </div>
                </ng-container>

                <ng-container *ngIf="createCampaign && !campaignCreated">
                    <div class="flex--row">
                        <mat-form-field class="col-80">
                            <span matPrefix>{{ brand }} &nbsp;</span>
                            <input
                                type="text"
                                placeholder="Campaign Name"
                                matInput
                                [formControl]="campaignName"
                                required
                            />
                            <mat-icon matSuffix>mode_edit</mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="flex-set flex--align-content-between">
                        <button
                            class="flexed-item"
                            mat-raised-button
                            color="primary"
                            [disabled]="campaignName.invalid"
                            (click)="saveCampaign()"
                        >
                            Save
                        </button>
                        <button
                            class="flexed-item"
                            mat-raised-button
                            color="accent"
                            (click)="clear()"
                        >
                            Cancel
                        </button>
                    </div>
                </ng-container>

                <ng-container *ngIf="createCampaign && campaignCreated">
                    <p *ngIf="creativeCreated" class="loading">Saving Creative</p>
                </ng-container>
            </div>
        </form>
    </mat-dialog-content>

    <mat-dialog-content *ngIf="!error && sites.length > 0 && creativeExists">
        <p>Creative exists. Please edit it on Showcase.</p>
        <br />
        <div class="flex--row">
            <mat-form-field class="col-80">
                <input
                    type="text"
                    placeholder="Select a Site"
                    matInput
                    [formControl]="site"
                    [matAutocomplete]="autosite"
                    required
                />
                <mat-autocomplete #autosite="matAutocomplete">
                    <mat-option
                        *ngFor="let option of filteredSites | async"
                        [value]="option.name"
                        [title]="option.name"
                        >{{ option.name }}</mat-option
                    >
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="flex--row">
            <a
                mat-raised-button
                [disabled]="site.invalid || !getDemoLink()"
                [href]="getDemoLink()"
                target="_blank"
                color="primary"
                >Go To Demo</a
            >
        </div>
    </mat-dialog-content>

    <div mat-dialog-actions>
        <div class="flex-grow-3">
            <button mat-stroked-button mat-dialog-close>
                <i class="material-icons">close</i>Close
            </button>
        </div>
    </div>
</ng-container>
