import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../common/services/notification.service';
import { RESTService } from '../../../common/services/rest.service';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import * as _ from 'lodash-es';

@Component({
  selector: 'og-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {
  passwordForm: FormGroup;
  private subs = [];

  constructor(
    private _rest: RESTService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _ns: NotificationService,
    private _ps: ProgressIndicatorService
  ) {
    if (typeof this._route.snapshot.queryParams['token'] === 'undefined') {
      this._ns.notify('Cannot enter this route directly.', true, 'warning');
      this._router.navigate(['/login']);
    }
  }

  ngOnInit() {
    this.passwordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      passwordConfirmation: new FormControl('', [Validators.required])
    });

    this.subs[0] = this.passwordForm.get('passwordConfirmation').valueChanges.subscribe((val) => {
      if (val !== this.passwordForm.get('password').value) {
        this.passwordForm.get('passwordConfirmation').setErrors({ missmatch: true });
      }
      if (val === '') {
        this.passwordForm.get('passwordConfirmation').setErrors({ required: true });
      }
    });
  }

  /**
   * Submit form and then send the request for password change.
   * @param formValue
   */
  formSubmitted(formValue) {
    this._ps.showSpinner();
    const token = this._route.snapshot.queryParams['token'];
    const fd: FormData = new FormData();
    fd.append('token', token);
    fd.append('email', formValue.email);
    fd.append('password', formValue.password);
    fd.append('password_confirmation', formValue.passwordConfirmation);

    this.subs[1] = this._rest.post('auth/reset-password', fd).subscribe(
      (data: any) => {
        this._ns.notify(data.message + ' Please, login again.', true, 'success');
      },
      (errorObj) => {
        this._ps.hideSpinner();
        this._ns.notify(errorObj.error.error.email);
      },
      () => {
        this._ps.hideSpinner();
        this._router.navigate(['/login']);
      }
    );
  }

  /**
   * Return to login if desired
   */
  goTo() {
    this._router.navigate(['/login']);
  }

  ngOnDestroy() {
    _.each(this.subs, (s) => {
      if (typeof s !== 'undefined') {
        s.unsubscribe();
      }
    });
  }
}
