import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AdService } from '../../../public/services/ad.service';
import { ProgressIndicatorService } from '../../services/progress-indicator.service';
import { Size } from '../../../models/utils/size.model';
import { AdUnit } from '../../../models/ad.model';
import { EmbedGeneratorService } from '../../../public/components/embed-generator/embed-generator.service';
import * as _ from 'lodash-es';
import { Observable } from 'rxjs';
import { logger } from '../../services/logger.service';

@Component({
  selector: 'og-preview-site-frame',
  templateUrl: './site-frame.component.html',
  styleUrls: ['./site-frame.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class SiteFrameComponent implements OnInit, OnDestroy {
  @Input('id') id: number;
  @Input('pos') pos: string = 'top';
  @Input('header') header: string = 'fixed';
  @Input('video') video: string = 'autoplay';
  private sub: any;
  ready: boolean = false;
  // Ad Unit
  ad: AdUnit = {
    active: null,
    id: null,
    version: null,
    brand: {
      id: null,
      name: null
    },
    category: {
      id: null,
      name: null
    },
    company: {
      id: null,
      name: null
    },
    product: {
      id: null,
      name: null
    },
    creative: {
      format: {
        id: null,
        name: null,
        type: {
          id: null,
          name: null
        }
      },
      json: null,
      sizing: {
        device_type: null
      }
    },
    jiraid: null,
    title: null,
    updated_at: null
  };
  size: Size = {
    height: 0,
    width: 0
  };
  embedCode = null;
  device: string = 'desktop';
  _schedule: string = '';
  _debug: boolean = true;
  _extras: any = {};
  isMobile: any = {
    Android() {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry() {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera() {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows() {
      return navigator.userAgent.match(/IEMobile/i);
    },
    any() {
      return this.Android() || this.BlackBerry() || this.iOS() || this.Opera() || this.Windows();
    }
  };

  constructor(
    private adService: AdService,
    private progressService: ProgressIndicatorService,
    private embedGeneratorService: EmbedGeneratorService
  ) {
    window.addEventListener('message', this.onExtrasChanged.bind(this), false);
  }

  /**
   * Assigns extras data
   * @param e
   */
  private onExtrasChanged(e) {
    if (
      e.data.source === 'PLATFORM' &&
      e.data.type === 'command' &&
      e.data.command === 'ogChangeExtras'
    ) {
      this.extras = e.data.extras;
    }
  }

  /**
   * Returns position according to format
   * @param format
   */
  private getPosition(format) {
    if (this.pos === '') {
      this.pos = 'top';
    }
    if (
      format === 'Interscroller' ||
      format === 'Universal Miniscroller' ||
      format === 'Native Banner'
    ) {
      this.pos = 'middle';
    }
    return this.pos;
  }

  /**
   * Returns Environment according to format
   * @param format
   */
  private getEnvironment(format) {
    if (format === 'Video') {
      return 'VAST';
    } else if (format === 'Interactive Video') {
      return 'VPAID';
    }
    return 'Web Browser';
  }

  ngOnInit() {
    const self = this;
    self.ready = false;
    self.progressService.showSpinner();

    self.sub = self.adService.get(self.id).subscribe((data) => {
      self.ad = data.data;
      self.pos = self.getPosition(self.ad.creative.format.name);
      self.setDevice();
      const code: any = self.embedGeneratorService.generate({
        ad: self.ad,
        debug: _.isUndefined(self.debug) ? true : self.debug,
        properties: {
          environment: self.getEnvironment(self.ad.creative.format.name),
          adserver: 'Ad Manager'
        },
        overrides: {
          schedule: this.schedule
        },
        extras: {}
      });

      if (code instanceof Observable) {
        code.subscribe((tag) => {
          self.embedCode = tag;
          logger('info', '').log('Embed Code Ready!')();
          self.progressService.hideSpinner();
          self.ready = true;
        });
      } else {
        self.embedCode = code;
        logger('info', '').log('Embed Code Ready!')();

        setTimeout(() => {
          self.progressService.hideSpinner();
          self.ready = true;
        });
      }
    });
  }

  /**
   * Returns the unit type
   * @param format
   */
  getUnitType(format): string {
    if (format.type.name === 'amp') {
      return 'AMP';
    } else if (format.name === 'Video' || format.name === 'Interactive Video') {
      return 'Video';
    }

    return 'Standard';
  }

  /**
   * Assigns device according to ad data
   */
  setDevice() {
    this.ad.creative.sizing.device_type =
      typeof this.ad.creative.sizing.device_type !== 'undefined'
        ? this.ad.creative.sizing.device_type
        : 'desktop';
    this.device = this.ad.creative.sizing.device_type;
    if (this.isMobile.any() !== null || window.innerWidth <= 800) {
      this.device = 'phone';
    }
  }

  onResize(event) {
    if (event.target.innerWidth <= 800) {
      this.device = 'phone';
    } else {
      this.device = 'desktop';
    }
  }

  /**
   * Schedule Getter
   */
  get schedule(): string {
    return this._schedule;
  }

  /**
   * Schedule Setter
   * @param schedule
   */
  @Input()
  set schedule(schedule: string) {
    this._schedule = schedule;
    if (schedule === '') {
      return;
    }
    this.embedCode = null;
    this.ready = false;
    this.progressService.showSpinner();
    const code: any = this.embedGeneratorService.generate({
      ad: this.ad,
      debug: this._debug,
      properties: {
        environment: this.getEnvironment(this.ad.creative.format.name),
        adserver: 'Ad Manager'
      },
      overrides: {
        schedule
      },
      extras: this._extras
    });
    if (code instanceof Observable) {
      code.subscribe((tag) => {
        this.embedCode = tag;
        this.ready = true;
        this.progressService.hideSpinner();
      });
    } else {
      setTimeout(() => {
        this.embedCode = code;
        this.ready = true;
        this.progressService.hideSpinner();
      });
    }
  }

  /**
   * Debug Setter
   * @param debug
   */
  @Input()
  set debug(debug: boolean) {
    this._debug = debug;
    this.embedCode = null;
    this.ready = false;
    this.progressService.showSpinner();
    const code = this.embedGeneratorService.generate({
      ad: this.ad,
      debug,
      properties: {
        environment: this.getEnvironment(this.ad.creative.format.name),
        adserver: 'Ad Manager'
      },
      overrides: {
        schedule: this.schedule
      },
      extras: this._extras
    });
    if (code instanceof Observable) {
      code.subscribe((tag) => {
        this.embedCode = tag;
        this.ready = true;
        this.progressService.hideSpinner();
      });
    } else {
      setTimeout(() => {
        this.embedCode = code;
        this.ready = true;
        this.progressService.hideSpinner();
      });
    }
  }

  /**
   * Debug Getter
   */
  get debug(): boolean {
    return this._debug;
  }

  /**
   * Extras Setter
   * @param extras
   */
  @Input()
  set extras(extras: any) {
    this._extras = extras;
    if (_.isEmpty(extras)) {
      return;
    }
    this.embedCode = null;
    this.ready = false;
    this.progressService.showSpinner();
    const code = this.embedGeneratorService.generate({
      ad: this.ad,
      debug: this._debug,
      properties: {
        environment: this.getEnvironment(this.ad.creative.format.name),
        adserver: 'Ad Manager'
      },
      overrides: {
        schedule: this._schedule
      },
      extras
    });
    if (code instanceof Observable) {
      code.subscribe((tag) => {
        this.embedCode = tag;
        this.ready = true;
        this.progressService.hideSpinner();
      });
    } else {
      setTimeout(() => {
        this.embedCode = code;
        this.ready = true;
        this.progressService.hideSpinner();
      });
    }
  }

  /**
   * Extras Getter
   */
  get extras(): any {
    return this._extras;
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
