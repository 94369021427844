import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import * as _ from 'lodash-es';
import { EnvironmentService } from '../../../common/services/environment.service';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';

@Component({
  selector: 'og-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
  form: FormGroup;
  private subs: Subscription[] = [];
  private lastVisited: string;
  messagePerErrorCode = {
    loginfailed: 'Invalid credentials'
  };
  waiting: boolean = false;
  errors = [];
  loginUrl: string = 'https://management.originplatform.com/login';
  serving: boolean = window.location.hostname === 'localhost';
  selection: string = '';

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private environmentService: EnvironmentService
  ) {
    this.loginUrl = this.environmentService.getPlatformUrl() + 'login';
    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
    const token = this.route.snapshot.queryParamMap.get('token');
    this.lastVisited = this.returnPreviousUrl();

    if (token !== null && token !== '') {
      this.authService.setToken(token);
    }

    setTimeout(() => {
      if (this.authService.isAuthenticated()) {
        if (this.lastVisited !== '') {
          this.router.navigate([this.lastVisited]);
        } else {
          this.router.navigate(['/dashboard']);
        }
      }
    });
  }

  /**
   * Show alert
   * @param msg
   * @param type
   * @private
   */
  _alert(msg: string, type: string) {
    this.snackBar.open(msg, type, {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  /**
   * Logins User
   */
  login() {
    const self = this;
    const val = this.form.value;
    self.waiting = true;

    if (val.username && val.password) {
      self.subs[0] = self.authService.login(val.username, val.password).subscribe(
        () => {
          self._alert('User Logged in!', 'Dismiss');

          if (self.lastVisited !== '') {
            self.router.navigate([self.lastVisited]);
          } else {
            self.router.navigate(['/dashboard']);
          }
        },
        (response) => {
          self._alert(response.error.error, 'Dismiss');
          self.waiting = false;
        }
      );
    }
  }

  /**
   * Returns to previous url
   */
  returnPreviousUrl() {
    let lastVisited = localStorage.getItem('lastVisited');

    if (lastVisited === null || lastVisited.indexOf('login') > -1) {
      localStorage.setItem('lastVisited', '');
      lastVisited = '/dashboard';
    }

    return lastVisited;
  }

  /**
   * Opens the forgot password modal
   */
  openModal() {
    const dialogRef: MatDialogRef<ForgotPasswordComponent, any> = this.dialog.open(
      ForgotPasswordComponent,
      {
        width: '500px',
        height: '250px',
        panelClass: 'forgot-password',
        backdropClass: 'forgot-password__backdrop'
      }
    );
    const onPasswordReset: EventEmitter<any> = dialogRef.componentInstance.onPasswordRecovery;

    this.subs[1] = onPasswordReset.subscribe(() => {
      this.dialog.closeAll();
    });
  }
  /**
   * On destroy
   */
  ngOnDestroy() {
    _.each(this.subs, (s) => {
      if (typeof s !== 'undefined') {
        s.unsubscribe();
      }
    });
  }
}
