import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const env = environment.production ? 'production' : 'development';
console.log('[Origin Platform] Bundle: ' + env.toUpperCase());

if (environment.production) {
  try {
    enableProdMode();
    bootstrap();
  } catch (exception) {
    console.error(
      'BUGFIX: calling isDevMode() in imports before enableProdMode() throws exception - https://github.com/angular/angular-cli/issues/8340#\n',
      exception
    );
  }
} else {
  const styles = document.createElement('script');
  styles.src = 'styles-' + env + '.js';
  styles.onload = bootstrap;
  document.body.appendChild(styles);
}

function bootstrap() {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
}
