import { Component, ViewContainerRef, OnInit } from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  RouteConfigLoadStart,
  RouteConfigLoadEnd
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { ProgressIndicatorService } from './common/services/progress-indicator.service';
import 'hammerjs';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RouteChangeNotifyComponent } from './public/components/route-change-notify/route-change-notify.component';

@Component({
  selector: 'og-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  location: String;
  shouldHide: boolean;
  navStart: Observable<NavigationStart>;
  navEnd: Observable<NavigationEnd>;
  moduleLoad: Observable<RouteConfigLoadStart>;
  moduleLoadEnd: Observable<RouteConfigLoadEnd>;
  routeNotify: MatSnackBar;
  // https://loiane.com/2017/08/angular-hide-navbar-login-page/

  constructor(
    public router: Router,
    private vcRef: ViewContainerRef,
    private progressIndicator: ProgressIndicatorService,
    private _snackbar: MatSnackBar
  ) {
    // observable for navigation start.
    this.navStart = router.events.pipe(
      filter((evt) => evt instanceof NavigationStart)
    ) as Observable<NavigationStart>;
    // observable for navigation end.
    this.navEnd = router.events.pipe(
      filter((evt) => evt instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;
    // observable for module load start.
    this.moduleLoad = router.events.pipe(
      filter((evt) => evt instanceof RouteConfigLoadStart)
    ) as Observable<RouteConfigLoadStart>;
    // observable for module load end.
    this.moduleLoadEnd = router.events.pipe(
      filter((evt) => evt instanceof RouteConfigLoadEnd)
    ) as Observable<RouteConfigLoadEnd>;
  }

  ngOnInit() {
    this.navStart.subscribe((routeData) => {
      // If navigation is not ad-maker. Remove class if exists.
      if (!routeData.url.toString().includes('ad-maker')) {
        document.body.classList.remove('ad-maker__active');
      }

      this._snackbar.openFromComponent(RouteChangeNotifyComponent, {
        duration: 100000,
        verticalPosition: 'bottom',
        horizontalPosition: 'center'
      });
      this.progressIndicator.reset();
      this.location = routeData.url.toString();
      if (this.location.includes('reset-password')) {
        this.shouldHide = true;
      } else {
        this.shouldHide = false;
      }
    });

    // on Navigation end
    this.navEnd.subscribe((routeEnd) => {
      this._snackbar.dismiss();
      // If navigation is ad-maker, add a class to set overflow hidden to prevent
      // scrollabrs appearing when modal is closed.
      if (routeEnd.url.toString().includes('ad-maker')) {
        document.body.classList.add('ad-maker__active');
      }
    });

    // on Module loaded (lazy-load components/route only)
    this.moduleLoadEnd.subscribe((moduleData) => {
      this._snackbar.dismiss();
    });
  }
}
