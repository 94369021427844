<div class="panel">
    <div class="panel__title">
        <span>Event Monitor</span>
        <mat-icon (click)="close()">minimize</mat-icon>
    </div>
    <div class="panel__container">
        <div class="panel__container__topbar flex-set flex--content-between">
            <div class="flex--column">
                <table class="mat-table">
                    <tr>
                        <th colspan="4" class="mat-header-cell">Summary</th>
                    </tr>
                    <tr>
                        <td class="mat-cell">Impression</td>
                        <td class="mat-cell">
                            <mat-icon *ngIf="summary.impression === 1" class="small-icon"
                                >done</mat-icon
                            >
                            <mat-icon *ngIf="summary.impression === 0" class="small-icon"
                                >close</mat-icon
                            >
                        </td>

                        <td class="mat-cell">Interactions</td>
                        <td class="mat-cell">
                            <b>{{ summary.interactions }}</b>
                        </td>
                    </tr>
                    <tr>
                        <td class="mat-cell">Load Time</td>
                        <td class="mat-cell">
                            <b>{{ summary.load_time }}s</b>
                        </td>

                        <td class="mat-cell">Clicks</td>
                        <td class="mat-cell">
                            <b>{{ summary.clicks }}</b>
                        </td>
                    </tr>
                    <tr>
                        <td class="mat-cell">Time Spent</td>
                        <td class="mat-cell">
                            <b *ngIf="summary.timespent !== -1">{{ summary.timespent }}s</b>
                            <b *ngIf="summary.timespent === -1"> - </b>
                        </td>

                        <td class="mat-cell">Errors</td>
                        <td class="mat-cell">
                            <b>{{ summary.errors }}</b>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="flex--column">
                <mat-slide-toggle
                    *ngIf="type === 'video'"
                    class="panel__container__topbar__btn"
                    [(ngModel)]="imaEvents"
                    >IMA Events</mat-slide-toggle
                >
                <button
                    mat-stroked-button
                    [ngClass]="{
                        panel__container__topbar__btn: true,
                        'pause-btn': !paused,
                        'continue-btn': paused
                    }"
                    (click)="toggle()"
                >
                    <ng-container *ngIf="paused"
                        ><mat-icon>play_circle_outline</mat-icon> Continue</ng-container
                    >
                    <ng-container *ngIf="!paused"
                        ><mat-icon>pause_circle_outline</mat-icon> Pause</ng-container
                    >
                </button>
                <button
                    mat-stroked-button
                    class="panel__container__topbar__btn clear-btn"
                    (click)="clear()"
                >
                    <mat-icon>delete_sweep</mat-icon> Clear
                </button>
            </div>
        </div>

        <div class="panel__container__table" *ngIf="!imaEvents">
            <table class="panel__container__logs" mat-table [dataSource]="logs">
                <!-- State Column -->
                <ng-container matColumnDef="state">
                    <mat-header-cell *matHeaderCellDef> State </mat-header-cell>
                    <mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.state.length > 10 ? element.state : null"
                        [ngClass]="{
                            'monitor__cell--state': true,
                            'monitor__cell--overflow': element.state.length > 10
                        }"
                        >{{ element.state }}</mat-cell
                    >
                </ng-container>

                <!-- State Column -->
                <ng-container matColumnDef="page">
                    <mat-header-cell *matHeaderCellDef> Page </mat-header-cell>
                    <mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.page.length > 10 ? element.page : null"
                        [ngClass]="{
                            'monitor__cell--page': true,
                            'monitor__cell--overflow': element.page.length > 10
                        }"
                        >{{ element.page }}</mat-cell
                    >
                </ng-container>

                <!-- Event Column -->
                <ng-container matColumnDef="event">
                    <mat-header-cell *matHeaderCellDef> Event </mat-header-cell>
                    <mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.event.length > 10 ? element.event : null"
                        [ngClass]="{
                            'monitor__cell--event': true,
                            'monitor__cell--overflow': element.event.length > 10
                        }"
                        >{{ element.event | maxlength: 10 }}</mat-cell
                    >
                </ng-container>

                <!-- Attribute Column -->
                <ng-container matColumnDef="attr">
                    <mat-header-cell *matHeaderCellDef> Attribute </mat-header-cell>
                    <mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.attr.length > 10 ? element.attr : null"
                        [ngClass]="{
                            'monitor__cell--attr': true,
                            'monitor__cell--overflow': element.attr.length > 10
                        }"
                        >{{ element.attr | maxlength: 10 }}</mat-cell
                    >
                </ng-container>

                <!-- Attribute Value Column -->
                <ng-container matColumnDef="attrLabel">
                    <mat-header-cell *matHeaderCellDef> Attribute Label </mat-header-cell>
                    <mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.attrLabel.length > 10 ? element.attrLabel : null"
                        [ngClass]="{
                            'monitor__cell--attrLabel': true,
                            'monitor__cell--overflow': element.attrLabel.length > 10
                        }"
                        >{{ element.attrLabel | maxlength: 10 }}</mat-cell
                    >
                </ng-container>

                <!-- Component Id Column -->
                <ng-container matColumnDef="componentId">
                    <mat-header-cell *matHeaderCellDef> Component Id </mat-header-cell>
                    <mat-cell
                        *matCellDef="let element"
                        [matTooltip]="
                            element.componentId && element.componentId.length > 10
                                ? element.componentId
                                : null
                        "
                        [ngClass]="{
                            'monitor__cell--componentId': true,
                            'monitor__cell--overflow':
                                element.componentId && element.componentId.length > 10
                        }"
                        >{{ element.componentId | maxlength: 10 }}</mat-cell
                    >
                </ng-container>

                <!-- Attribute Value Column -->
                <ng-container matColumnDef="attrValue">
                    <mat-header-cell *matHeaderCellDef> Attribute Value </mat-header-cell>
                    <mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.attrValue.length > 10 ? element.attrValue : null"
                        [ngClass]="{
                            'monitor__cell--attrValue': true,
                            'monitor__cell--overflow': element.attrValue.length > 10
                        }"
                        >{{ element.attrValue | maxlength: 10 }}</mat-cell
                    >
                </ng-container>

                <!-- Is Click Column -->
                <ng-container matColumnDef="isClick">
                    <mat-header-cell *matHeaderCellDef> Click </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="monitor__cell--isClick">
                        <mat-icon *ngIf="element.isClick">done</mat-icon>
                    </mat-cell>
                </ng-container>

                <!-- Is Interaction Column -->
                <ng-container matColumnDef="isInteraction">
                    <mat-header-cell *matHeaderCellDef> Interaction </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-icon *ngIf="element.isInteraction">done</mat-icon>
                    </mat-cell>
                </ng-container>

                <!-- No Records Row -->
                <ng-container matColumnDef="noRecord">
                    <td mat-footer-cell *matFooterCellDef class="panel__container__no-logs">
                        No records found.
                    </td>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

                <mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    [ngClass]="{
                        'mat-row--error': row.withError,
                        'mat-row--engagement': row.isEngagement
                    }"
                ></mat-row>

                <ng-template *ngIf="logs.length === 0">
                    <tr mat-footer-row *matFooterRowDef="['noRecord']"></tr>
                </ng-template>
            </table>
        </div>

        <div class="panel__container__notes" *ngIf="!imaEvents">
            <small>
                <span class="is-eng"></span> This background color indicates that this event it's an
                engagement.
            </small>
        </div>

        <!-- IMA Google Events -->
        <div class="panel__container__table" *ngIf="imaEvents">
            <table
                class="panel__container__logs panel__container__logs--google"
                mat-table
                [dataSource]="googleLogs"
            >
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef>Type</th>
                    <td mat-cell *matCellDef="let element">{{ element.type }}</td>
                </ng-container>

                <!-- Value Column -->
                <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef>Value</th>
                    <td mat-cell *matCellDef="let element">{{ element.value }}</td>
                </ng-container>

                <!-- No Records Row -->
                <ng-container matColumnDef="noRecord">
                    <td mat-footer-cell *matFooterCellDef class="panel__container__no-logs">
                        No records found.
                    </td>
                </ng-container>

                <mat-header-row *matHeaderRowDef="googleColumns; sticky: true"></mat-header-row>

                <mat-row *matRowDef="let row; columns: googleColumns"></mat-row>

                <ng-template *ngIf="googleLogs.length === 0">
                    <tr mat-footer-row *matFooterRowDef="['noRecord']"></tr>
                </ng-template>
            </table>
        </div>
    </div>
</div>
