import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'og-eg-rm-interstitial',
  templateUrl: './interstitial.component.html'
})
export class EmbedInterstitialComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
