import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { AdService } from '../../services/ad.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import { AdUnit } from '../../../models/ad.model';
import * as _ from 'lodash-es';
import * as moment from 'moment';
import { logger } from '../../../common/services/logger.service';
import { PreviewService } from './preview.service';

@Component({
  selector: 'og-preview',
  templateUrl: './preview.component.html'
})
export class PreviewComponent implements OnInit, OnDestroy {
  @ViewChild('deviceContainer', {
    static: true
  })
  deviceContainer: ElementRef;
  @ViewChild('iframe', {
    static: true
  })
  iframe: ElementRef;
  // General
  id: number = null;
  private sub: Array<any> = [];
  ready: boolean = false;
  // Ad Unit
  ad: AdUnit = {
    active: null,
    id: null,
    version: null,
    brand: {
      id: null,
      name: null
    },
    category: {
      id: null,
      name: null
    },
    company: {
      id: null,
      name: null
    },
    product: {
      id: null,
      name: null
    },
    creative: {
      format: {
        id: null,
        name: null,
        type: {
          id: null,
          name: null
        }
      },
      json: null,
      sizing: {
        device_type: null
      }
    },
    jiraid: null,
    title: null,
    updated_at: null
  };
  header: string = 'fixed';
  video: string = 'autoplay';
  // Options
  phoneSize: string = 'large';
  browserBars: boolean = false;
  devicePanel: boolean = false;
  contextPanel: boolean = false;
  previewOptionsPanel: boolean = false;
  eventMonitor: boolean = false;
  orientation: boolean = false;
  debug: boolean = true;
  // Event
  resizingData: any;
  // Config
  iframeHeight: number = window.outerHeight;
  marginTop: number = 0;
  // Context
  schedule: string = '';
  adPosition: string = 'top';
  device: string = '';
  context: any = {};

  constructor(
    private adService: AdService,
    private router: Router,
    private route: ActivatedRoute,
    private progressService: ProgressIndicatorService,
    private previewService: PreviewService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    localStorage.removeItem('showcase_access_token');

    this.previewService.linkRequested.subscribe(
      (res) => this.onLinkRequested(),
      (err) => {}
    );
  }

  ngOnInit() {
    const self = this;
    const windowHeight = window.innerHeight;

    if (windowHeight < 800) {
      self.phoneSize = 'small';
    } else if (windowHeight >= 900 && windowHeight <= 974) {
      self.phoneSize = 'medium';
    } else if (windowHeight >= 975) {
      self.phoneSize = 'large';
    }

    // Override schedule and debug thru url
    if (!_.isUndefined(self.route.snapshot.queryParamMap['params'])) {
      if (!_.isUndefined(self.route.snapshot.queryParamMap['params'].schedule)) {
        self.schedule = self.route.snapshot.queryParamMap.get('schedule');
        logger('info', 'Override from Url').log('Schedule:', self.schedule)();
      }
      if (!_.isUndefined(self.route.snapshot.queryParamMap['params'].debug)) {
        self.debug = JSON.parse(self.route.snapshot.queryParamMap.get('debug'));
        logger('info', 'Override from Url').log('Debug:', self.debug)();
      }
      if (!_.isUndefined(self.route.snapshot.queryParamMap['params'].header)) {
        self.header = self.route.snapshot.queryParamMap.get('header');
        logger('info', 'Override from Url').log('Header:', self.header)();
      }
      if (!_.isUndefined(self.route.snapshot.queryParamMap['params'].device)) {
        self.device = self.route.snapshot.queryParamMap.get('device');
        logger('info', 'Override from Url').log('Device:', self.device)();
      }
      if (!_.isUndefined(self.route.snapshot.queryParamMap['params'].position)) {
        self.adPosition = self.route.snapshot.queryParamMap.get('position');
        logger('info', 'Override from Url').log('Device:', self.adPosition)();
      }
      if (!_.isUndefined(self.route.snapshot.queryParamMap['params'].backgroundImage)) {
        self.context.bgImage = decodeURIComponent(
          self.route.snapshot.queryParamMap.get('backgroundImage')
        );
        logger('info', 'Override from Url').log('Background Image:', self.context.bgImage)();
      }
      if (!_.isUndefined(self.route.snapshot.queryParamMap['params'].video)) {
        self.video = decodeURIComponent(self.route.snapshot.queryParamMap.get('video'));
        logger('info', 'Override from Url').log('Video:', self.video)();
      }
    }

    if (self.isMobile()) {
      setTimeout(() => {
        self.router.navigate(['standalone', self.route.snapshot.params['id']]);
      });
    }

    window.document.body.style.overflow = 'hidden';
    window.document.body.style.minWidth = '488px';

    self.progressService.showBar();

    self.sub[0] = self.route.params.subscribe((params) => {
      self.id = +params['id'];

      self.sub[1] = self.adService.get(self.id).subscribe((data) => {
        self.ad = data.data;
        self.progressService.hideBar();

        if (typeof self.ad.creative === 'undefined') {
          self.router.navigate(['/not-found']);
        } else {
          self.setDevice();
          self.setContainerHeight();

          if (
            this.ad.creative.format.name === 'Interscroller' ||
            this.ad.creative.format.name === 'Universal Miniscroller' ||
            this.ad.creative.format.name === 'Native Banner' ||
            this.ad.creative.format.name === 'Video Interscroller' ||
            this.ad.creative.format.name === 'Vertical In-article' ||
            this.ad.creative.format.name === 'Square In-article' ||
            this.ad.creative.format.name === 'Landscape In-article'
          ) {
            this.adPosition = 'middle';
            this.header = 'relative';
          }

          if (this.ad.creative.format.name === 'Overture') {
            this.header = 'relative';
          }

          self.adService.adLoaded.emit(data);
          self.ready = true;
        }
      });
    });
  }

  /**
   * Returns the iframe url
   */
  getIframeUrl() {
    let url =
      '/#/standalone/' +
      this.id +
      '?debug=' +
      this.debug +
      '&header=' +
      this.header +
      '&video=' +
      this.video;

    if (this.schedule !== '') {
      url += '&schedule=' + this.schedule;
    }
    if (this.adPosition !== '') {
      url += '&position=' + this.adPosition;
    }

    return url;
  }

  /**
   * Assigns the correct device depending on ad data
   */
  setDevice(payload?) {
    if (!_.isUndefined(payload) && !_.isUndefined(payload.deviceType)) {
      this.device = payload.deviceType;
    }

    if (this.device === '' && !_.isUndefined(this.ad.creative.sizing.device_type)) {
      this.device = this.ad.creative.sizing.device_type;
    } else {
      if (this.ad.title.toLowerCase().indexOf('mobile') > -1) {
        this.device = 'phone';
      }

      if (
        this.ad.creative.format.name === 'Video Interscroller' ||
        this.ad.creative.format.name === 'Vertical In-article' ||
        this.ad.creative.format.name === 'Square In-article' ||
        this.ad.creative.format.name === 'Landscape In-article' ||
        this.ad.creative.format.name === 'Universal Miniscroller'
      ) {
        this.device = 'phone';
      }
    }

    if (this.device === 'phone' || this.device === 'tablet') {
      this.setIframeHeight();
    }
  }

  /**
   * Assigns the iframe height depending on selection
   */
  setIframeHeight() {
    // Portrait & Large
    if (!this.orientation && this.phoneSize === 'large') {
      if (this.browserBars) {
        this.iframeHeight = 628;
      } else {
        this.iframeHeight = 736;
      }

      // Portrait & Medium
    } else if (!this.orientation && this.phoneSize === 'medium') {
      if (this.browserBars) {
        this.iframeHeight = 559;
      } else {
        this.iframeHeight = 667;
      }

      // Portrait & Small
    } else if (!this.orientation && this.phoneSize === 'small') {
      if (this.browserBars) {
        this.iframeHeight = 460;
      } else {
        this.iframeHeight = 568;
      }

      // Landscape & Large
    } else if (this.orientation && this.phoneSize === 'large') {
      this.iframeHeight = 370;

      // Landscape & Medium
    } else if (this.orientation && this.phoneSize === 'medium') {
      this.iframeHeight = 331;

      // Landscape & Small
    } else {
      this.iframeHeight = 232;
    }

    this.setContainerHeight();
  }

  /**
   * Assigns the container height depending on window
   */
  setContainerHeight() {
    const newHeight = this.deviceContainer.nativeElement.clientHeight;

    if (window.innerHeight < newHeight) {
      document.body.style.minHeight = newHeight + 45 + 'px';
    }
  }

  /**
   * Changes the orientation
   */
  toggleOrientation() {
    this.orientation = !this.orientation;
    this.checkBrowserBars();
    this.setIframeHeight();
  }

  /**
   * Updates margin according to browser bars value
   */
  checkBrowserBars() {
    if (this.browserBars && !this.orientation) {
      this.marginTop = 54;
    } else {
      this.marginTop = 0;
    }
  }

  /**
   * Changes browser bars value
   */
  toggleBrowserBars() {
    this.browserBars = !this.browserBars;
    this.checkBrowserBars();
    this.setIframeHeight();
  }

  /**
   * Changes phone size
   * @param s
   */
  changePhoneSize(s) {
    this.phoneSize = s;
    this.setIframeHeight();
  }

  /**
   * Listens to resize assigning value to data
   * @param e
   */
  onResizing(e) {
    this.resizingData = e;
  }

  /**
   * Assigns the schedule according to the date provided
   * @param date
   */
  setSchedule(date) {
    const epoch = moment(date).startOf('day').unix();
    let activeScheduleIndex = 'default';

    _.each(this.ad.creative.json.schedules, (schedule, index) => {
      if (
        typeof schedule.dates !== 'undefined' &&
        epoch >= schedule.dates.startDate &&
        epoch <= schedule.dates.endDate
      ) {
        activeScheduleIndex = index;
      }
    });

    this.schedule = activeScheduleIndex;
  }

  /**
   * Assigns extras according to the format data
   * @param extras
   */
  setExtras(extras = {}) {
    if (_.isEmpty(extras)) {
      return;
    }

    if (!_.isUndefined(extras['data']['bgImage'])) {
      this.context['bgImage'] = extras['data']['bgImage'];
    }

    this.iframe.nativeElement.contentWindow.postMessage(
      {
        source: 'PLATFORM',
        type: 'command',
        command: 'ogChangeExtras',
        extras
      },
      window.location.origin
    );
  }

  /**
   * Detect if current device is a mobile
   * @return boolean
   */
  isMobile() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Checks if ad unit is a video ad unit
   * @param ad
   * @returns
   */
  isVideoAd(ad) {
    if (
      (ad.creative.format.name === 'Video' && ad.creative.format.type.name === 'video') ||
      ad.creative.format.type.name === 'interactivevideo'
    ) {
      return true;
    }

    return false;
  }

  /**
   * On Iframe Loaded assign context extras
   */
  onIframeLoaded() {
    if (!_.isEmpty(this.context)) {
      setTimeout(() => {
        this.setExtras({
          data: this.context
        });
      }, 1500);
    }
  }

  /**
   * On Link Requested, generate Link
   */
  onLinkRequested() {
    let link = window.location.href.split('?')[0];
    let params = {
      debug: this.debug,
      position: this.adPosition,
      device: this.device,
      header: this.header
    };

    if (this.schedule !== '') {
      params['schedule'] = this.schedule;
    }

    if (!_.isUndefined(this.context['bgImage']) && !_.isEmpty(this.context['bgImage'])) {
      params['backgroundImage'] = encodeURIComponent(this.context['bgImage']);
    }

    link +=
      '?' +
      Object.entries(params)
        .map(([key, val]) => `${key}=${val}`)
        .join('&');

    this.previewService.linkGenerated.emit(link);
  }

  /**
   * On destroy
   */
  ngOnDestroy() {
    window.document.body.style.overflow = null;
    window.document.body.style.minWidth = null;

    _.each(this.sub, (s) => {
      s.unsubscribe();
    });
  }
}
