import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { HelpService } from '../../services/help.service';
import { ActivatedRoute } from '@angular/router';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import { forkJoin, Subscription } from 'rxjs';
import * as _ from 'lodash-es';

interface EventsAttributesRow {
  event: string;
  attribute: string;
  label: string;
  click: boolean;
  int: boolean;
}

@Component({
  selector: 'og-help-metrics',
  templateUrl: './help-metrics.component.html',
  encapsulation: ViewEncapsulation.None
})
export class HelpMetricsComponent implements OnInit, OnDestroy {
  definitions: Array<{
    id: string;
    description: string;
  }> = new Array<{
    id: string;
    description: string;
  }>();
  adVersion: string = 'v3';
  private subs: Subscription[] = [];
  // Attributes & Events table
  attrHeaderColumns = [
    {
      colName: 'event',
      colText: 'EVENT'
    },
    {
      colName: 'attribute',
      colText: 'ATTRIBUTE'
    },
    {
      colName: 'label',
      colText: 'LABEL'
    },
    {
      colName: 'click',
      colText: 'CLICK'
    },
    {
      colName: 'int',
      colText: 'INT.'
    }
  ];
  displayedColumnsEvents = ['event', 'attribute', 'label', 'click', 'int'];
  attrTableData: Array<EventsAttributesRow>;
  private events = [];

  constructor(
    private helpService: HelpService,
    private progressService: ProgressIndicatorService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    const that = this;
    const observers: any[] = [];

    if (typeof that.activatedRoute.snapshot.queryParams['version'] !== 'undefined') {
      that.subs[1] = that.activatedRoute.queryParams.subscribe((param: any) => {
        if (typeof param['version'] !== 'undefined') {
          that.adVersion = 'v' + param['version'];
          that.adVersionChanged();
        } else {
          that.adVersion = 'v3';
          that.adVersionChanged();
        }
      });
    }

    that.progressService.showBar();

    observers.push(that.helpService.getMetricsHelp());
    observers.push(that.helpService.getEvents());

    that.subs[0] = forkJoin(observers).subscribe((results) => {
      that.parseDefinitions(results[0]['data']['definitions']);
      that.events['v2'] = results[0]['data']['attributes_and_events']['v2'];
      that.parseEvents(that.events['v2']);
      that.events['v3'] = results[1]['data'];
      that.parseEvents(that.events['v3']);
      that.progressService.hideBar();
    });

    window.onscroll = () => {
      that.scrollFunction();
    };
  }

  /**
   * On ad version changed
   */
  adVersionChanged() {
    this.parseEvents(this.events[this.adVersion]);
  }

  /**
   * Enables scroll to
   */
  scrollFunction() {
    if (document.body.scrollTop > 1600 || document.documentElement.scrollTop > 1600) {
      document.getElementById('go2Top').style.display = 'block';
    } else {
      document.getElementById('go2Top').style.display = 'none';
    }
  }

  /**
   * Goes to top of page
   */
  go2Top() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  /**
   * Parses definitions
   * @param definitions
   */
  parseDefinitions(definitions) {
    for (let def in definitions) {
      this.definitions.push({
        id: def,
        description: definitions[def]
      });
    }
  }

  /**
   * Parses events
   * @param events
   */
  parseEvents(events) {
    this.attrTableData = new Array<EventsAttributesRow>();

    for (let ev in events) {
      this.parseAttributes(ev, events[ev]);
    }
  }

  /**
   * Parses attributes
   * @param evName
   * @param attributesObj
   */
  parseAttributes(evName, attributesObj) {
    let isFirst = true;

    for (let attr in attributesObj) {
      const newAttr: EventsAttributesRow = {
        event: isFirst ? evName : '',
        attribute: attr,
        label: attributesObj[attr].l,
        click: attributesObj[attr].c,
        int: attributesObj[attr].i
      };
      this.attrTableData.push(newAttr);
      isFirst = false;
    }
  }

  /**
   * Parses id name
   * @param val
   */
  parseToIdName(val: string): string {
    return 'pod_' + val.replace(/\s/g, '');
  }

  /**
   * Scroll to
   * @param link
   */
  scrollTo(link: string) {
    try {
      document.querySelector('#' + link).scrollIntoView();
    } catch (e) {}
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    _.each(this.subs, (s) => {
      s.unsubscribe();
    });
  }
}
