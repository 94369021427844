import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'og-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {
  user = null;

  constructor(private authService: AuthService) {
    this.user = authService.user();
  }

  ngOnInit() {}
}
