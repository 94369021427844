<div
    [ngClass]="{
        'floating-menu backdrop': true,
        'floating-menu--inactive': !isVisible,
        'floating-menu--active': isVisible
    }"
>
    <div class="floating-menu__content">
        <div class="close-btn">
            <button (click)="closeMenu()" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="menu-list">
            <mat-accordion>
                <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>My account</mat-panel-title>
                    </mat-expansion-panel-header>
                    <ul>
                        <li>
                            <button (click)="accountCallbacks[0]()" mat-menu-item>
                                <mat-icon>account_circle</mat-icon>
                                My Profile
                            </button>
                        </li>
                        <li>
                            <button (click)="accountCallbacks[1]()" mat-menu-item>
                                <mat-icon>reply</mat-icon>
                                Logout
                            </button>
                        </li>
                    </ul>
                </mat-expansion-panel>
                <mat-expansion-panel *ngIf="canReports">
                    <mat-expansion-panel-header>
                        <mat-panel-title>Reports</mat-panel-title>
                    </mat-expansion-panel-header>
                    <ul>
                        <li>
                            <button (click)="goTo('/impressions', true)" mat-menu-item>
                                <mat-icon>trending_up</mat-icon>
                                Impressions
                            </button>
                        </li>
                        <li>
                            <button (click)="goTo('/benchmarks', true)" mat-menu-item>
                                <mat-icon>insights</mat-icon>
                                Benchmarks
                            </button>
                        </li>
                        <li *ngIf="canAll">
                            <button
                                class="menuSelect__item"
                                mat-menu-item
                                (click)="goToCurrentDate('audit')"
                            >
                                <mat-icon>stacked_bar_chart</mat-icon>
                                Audit
                            </button>
                        </li>
                    </ul>
                </mat-expansion-panel>
                <mat-expansion-panel *ngIf="canProducts || canUsers || canGroups">
                    <mat-expansion-panel-header>
                        <mat-panel-title>Manage</mat-panel-title>
                    </mat-expansion-panel-header>
                    <ul>
                        <li *ngIf="canProducts">
                            <button (click)="goTo('/products')" mat-menu-item>
                                <mat-icon>apps</mat-icon>
                                Products
                            </button>
                        </li>
                        <li *ngIf="canProducts">
                            <button (click)="goTo('/formats')" mat-menu-item>
                                <mat-icon>widgets</mat-icon>
                                <span>Formats</span>
                            </button>
                        </li>
                        <li *ngIf="canUsers">
                            <button (click)="goTo('/users')" mat-menu-item>
                                <mat-icon>person</mat-icon>
                                Users
                            </button>
                        </li>
                        <mat-divider *ngIf="canGroups"></mat-divider>
                        <li *ngIf="canAll">
                            <button (click)="goTo('/companies')" mat-menu-item>
                                <mat-icon>business</mat-icon>
                                Companies
                            </button>
                        </li>
                        <li *ngIf="canGroups">
                            <button (click)="goTo('/groups')" mat-menu-item>
                                <mat-icon>group</mat-icon>
                                Groups
                            </button>
                        </li>
                    </ul>
                </mat-expansion-panel>
                <mat-expansion-panel *ngIf="canAll">
                    <mat-expansion-panel-header>
                        <mat-panel-title>Purge</mat-panel-title>
                    </mat-expansion-panel-header>
                    <ul>
                        <li>
                            <button (click)="purgeCallbacks[0]()" mat-menu-item>
                                <mat-icon>sync</mat-icon>
                                Render Static Assets
                            </button>
                        </li>
                        <li>
                            <button (click)="purgeCallbacks[1]()" mat-menu-item>
                                <mat-icon>sync</mat-icon>
                                Hostname
                            </button>
                        </li>
                        <li>
                            <button (click)="purgeCallbacks[2]()" mat-menu-item>
                                <mat-icon>sync</mat-icon>
                                URL
                            </button>
                        </li>
                    </ul>
                </mat-expansion-panel>
                <mat-expansion-panel *ngIf="canAll">
                    <mat-expansion-panel-header>
                        <mat-panel-title>Debug</mat-panel-title>
                    </mat-expansion-panel-header>
                    <ul>
                        <li>
                            <button class="menuSelect__item" mat-menu-item (click)="linkTo('env')">
                                Environment
                            </button>
                        </li>
                        <li>
                            <button
                                class="menuSelect__item"
                                mat-menu-item
                                (click)="linkTo('logs/today')"
                            >
                                Today Logs | Platform
                            </button>
                        </li>
                        <li>
                            <button
                                class="menuSelect__item"
                                mat-menu-item
                                (click)="linkTo('logs/today', 'render')"
                            >
                                Today Logs | Render
                            </button>
                        </li>
                        <li>
                            <button
                                class="menuSelect__item"
                                mat-menu-item
                                (click)="linkTo('routes')"
                            >
                                Routes
                            </button>
                        </li>
                        <li>
                            <button
                                class="menuSelect__item"
                                mat-menu-item
                                (click)="linkTo('phpinfo')"
                            >
                                PHPInfo
                            </button>
                        </li>
                    </ul>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>Help</mat-panel-title>
                    </mat-expansion-panel-header>
                    <ul>
                        <li>
                            <button (click)="goTo('/help/metrics')" mat-menu-item>Metrics</button>
                        </li>
                        <li>
                            <button (click)="goTo('/help/products')" mat-menu-item>Products</button>
                        </li>
                        <li>
                            <button (click)="goTo('/help/features')" mat-menu-item>Features</button>
                        </li>
                        <li>
                            <button (click)="goTo('/help/api')" mat-menu-item>API</button>
                        </li>
                    </ul>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>About</mat-panel-title>
                    </mat-expansion-panel-header>
                    <ul>
                        <li>
                            <button (click)="goTo('/about/privacy', false, true)" mat-menu-item>
                                Privacy Policy
                            </button>
                        </li>
                        <li>
                            <button mat-menu-item (click)="aboutCallbacks('platform')">
                                Platform Version
                            </button>
                        </li>
                        <li>
                            <button mat-menu-item (click)="aboutCallbacks('render')">
                                Render Version
                            </button>
                        </li>
                    </ul>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>
