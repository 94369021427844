import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';
import { GroupGuardService as GroupGuard } from './auth/group-guard.service';

const privateRoutes: Routes = [
  // Private Routes
  // https://stackoverflow.com/questions/56375703/angular-8-lazy-loading-modules-error-ts1323-dynamic-import-is-only-supporte
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./private/components/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'companies',
    loadChildren: () =>
      import('./private/components/companies/company.module').then((m) => m.CompanyModule),
    canActivate: [AuthGuard, GroupGuard],
    data: {
      permission: 'all'
    }
  },
  {
    path: 'users',
    loadChildren: () => import('./private/components/users/user.module').then((m) => m.UserModule),
    canActivate: [AuthGuard, GroupGuard],
    data: {
      permission: 'manage.users'
    }
  },
  {
    path: 'products',
    loadChildren: () =>
      import('./private/components/products/product.module').then((m) => m.ProductModule),
    canActivate: [AuthGuard, GroupGuard],
    data: {
      permission: 'manage.products'
    }
  },
  {
    path: 'groups',
    loadChildren: () =>
      import('./private/components/groups/group.module').then((m) => m.GroupModule),
    canActivate: [AuthGuard, GroupGuard],
    data: {
      permission: 'manage.groups'
    }
  },
  {
    path: 'analytics',
    loadChildren: () =>
      import('./private/components/analytics/analytics.module').then((m) => m.AnalyticsModule),
    canActivate: [AuthGuard, GroupGuard],
    data: {
      permission: 'view.reports'
    }
  },
  {
    path: 'benchmarks',
    loadChildren: () =>
      import('./private/components/benchmarks/benchmarks.module').then((m) => m.BenchmarksModule),
    canActivate: [AuthGuard, GroupGuard],
    data: {
      permission: 'manage.reports'
    }
  },
  {
    path: 'impressions',
    loadChildren: () =>
      import('./private/components/audit-impressions/audit-impressions.module').then(
        (m) => m.AuditImpressionsModule
      ),
    canActivate: [AuthGuard, GroupGuard],
    data: {
      permission: 'manage.reports'
    }
  },
  {
    path: 'ad-maker',
    loadChildren: () =>
      import('./private/components/ad-maker/ad-maker.module').then((m) => m.AdMakerModule),
    canActivate: [AuthGuard, GroupGuard],
    data: {
      permission: 'manage.ads'
    }
  },
  {
    path: 'formats',
    loadChildren: () =>
      import('./private/components/formats/format.module').then((m) => m.FormatModule),
    canActivate: [AuthGuard, GroupGuard],
    data: {
      permission: 'manage.products'
    }
  },
  {
    path: 'performance',
    loadChildren: () =>
      import('./private/components/performance/performance.module').then(
        (m) => m.PerformanceModule
      ),
    canActivate: [AuthGuard, GroupGuard],
    data: {
      permission: 'manage.ads'
    }
  },
  {
    path: 'log-ad-reports',
    loadChildren: () =>
      import('./private/components/log-ad-reports/log-ad-reports.module').then(
        (m) => m.LogAdReportsModule
      ),
    canActivate: [AuthGuard, GroupGuard],
    data: {
      permission: 'all'
    }
  },
  {
    path: 'audit',
    loadChildren: () =>
      import('./private/components/audit/audit.module').then((m) => m.AuditModule),
    canActivate: [AuthGuard, GroupGuard],
    data: {
      permission: 'all'
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(privateRoutes, {
      useHash: true,
      onSameUrlNavigation: 'reload'
    })
  ]
})
export class PrivateRouting {}
