<div class="about-version">
    <div class="about-version__container" mat-dialog-content>
        <h2 mat-dialog-title>{{ data.type }} Version</h2>
        <mat-divider></mat-divider>
        <br />
        <mat-list *ngIf="data.type === 'Platform'">
            <mat-list-item>Deploy Id: {{ data.deploy.id }}</mat-list-item>
            <mat-list-item>Old Platform: {{ data.deploy.oldVersion }}</mat-list-item>
            <mat-list-item>Platform: {{ data.deploy.newVersion }}</mat-list-item>
        </mat-list>
        <mat-list *ngIf="data.type === 'Render'">
            <mat-list-item>Deploy Id: {{ data.deploy.id }}</mat-list-item>
            <mat-list-item>Main App: {{ data.deploy.apps.main }}</mat-list-item>
            <mat-list-item>Old Loader, Render & VPAID: {{ data.deploy.apps.old }}</mat-list-item>
            <mat-list-item>Render: {{ data.deploy.apps.render }}</mat-list-item>
            <mat-list-item>Loader: {{ data.deploy.apps.loader }}</mat-list-item>
            <mat-list-item>VPAID: {{ data.deploy.apps.vpaid }}</mat-list-item>
        </mat-list>
    </div>
    <mat-dialog-actions>
        <button mat-button mat-dialog-close color="warn">Close</button>
    </mat-dialog-actions>
</div>
