import { shareReplay, filter, tap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from '../models/user.model';
import { RESTService } from '../common/services/rest.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { RefreshTokenComponent } from './refresh-token/refresh-token.component';
import { logger } from '../common/services/logger.service';

const helper = new JwtHelperService();

export const ANONYMOUS_USER: User = {
  active: undefined,
  company: undefined,
  email: undefined,
  first_name: undefined,
  group: undefined,
  id: undefined,
  last_name: undefined,
  username: undefined
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private subject = new BehaviorSubject<User>(undefined);
  user$: Observable<User> = this.subject.asObservable().pipe(filter((user) => !!user));
  isLoggedIn$: Observable<boolean> = this.user$.pipe(map((user) => !!user.id));
  isLoggedOut$: Observable<boolean> = this.isLoggedIn$.pipe(map((isLoggedIn) => !isLoggedIn));
  cachedRequests: Array<HttpRequest<any>> = [];
  refreshTokenModal: any = null;

  constructor(private REST: RESTService, private router: Router, private modal: MatDialog) {
    this.subject.next(ANONYMOUS_USER);
  }

  /**
   * Assigns Token
   * @param token
   */
  public setToken(token) {
    localStorage.setItem('access_token', token);
    const decodedUser = helper.decodeToken(token).user;
    localStorage.setItem('u', JSON.stringify(decodedUser));
    this.setSession();
  }

  /**
   * Returns token
   */
  public getToken(): string {
    const token = localStorage.getItem('access_token');

    if (token !== null) {
      const decodedUser = helper.decodeToken(token).user;
      localStorage.setItem('u', JSON.stringify(decodedUser));
    }

    return token;
  }

  /**
   * Checks if user is authenticated
   */
  public isAuthenticated(): boolean {
    const token = this.getToken();
    const tokenExpired = helper.isTokenExpired(token);
    let isAuthenticated = true;

    if (
      this.router.url !== '/' &&
      this.router.url.indexOf('login') === -1 &&
      this.router.url !== '/not-found'
    ) {
      localStorage.setItem('lastVisited', this.router.routerState.snapshot.url);
    }

    if (token === null) {
      isAuthenticated = false;
    }

    if (token !== null && tokenExpired) {
      isAuthenticated = false;
    }

    this.subject.next(!isAuthenticated ? ANONYMOUS_USER : this.user());

    return isAuthenticated;
  }

  /**
   * Checks if user is allowed to a given permission
   * @param permission
   */
  public can(permission): boolean {
    const u = this.user();
    const permissions = u !== null ? u.group.permissions : null;

    // if not logged in
    if (u === null) {
      return false;
    }

    // Is Super Admin
    if (permissions.all) {
      return true;
    }

    // 'manage.ads'
    // 'manage.products'
    // 'manage.users'
    // 'manage.reports'
    // 'reactivate.ads'
    // 'view.reports'

    // Has Permission?
    if (permissions[permission]) {
      return true;
    }

    return false;
  }

  /**
   * Caches requests
   * @param request
   */
  public collectFailedRequest(request): void {
    this.cachedRequests.push(request);
  }

  /**
   * Retry fails requests
   */
  public retryFailedRequests(): void {
    // retry the requests. this method can
    // be called after the token is refreshed
  }

  /**
   * Returns authenticated user
   */
  public user(): User {
    return JSON.parse(localStorage.getItem('u'));
  }

  /**
   * Checks if user is super admin
   */
  public isSuperAdmin() {
    const u = this.user();

    if (
      typeof u.group.permissions.all !== 'undefined' ||
      u.group.name.indexOf('Super Admin') > -1
    ) {
      return true;
    }

    return false;
  }

  /**
   * Returns user
   * @param subject
   * @param response
   * @private
   */
  private getUser(subject, response) {
    if (typeof response !== undefined && typeof response.data !== undefined) {
      const access_token = response.data.access_token;
      localStorage.setItem('access_token', access_token);
      const decodedUser = helper.decodeToken(access_token).user;
      localStorage.setItem('u', JSON.stringify(decodedUser));
      subject.next(decodedUser);
    }
  }

  /**
   * Login
   * @param username
   * @param password
   */
  login(username: string, password: string) {
    return this.REST.post('auth/login', { username, password }).pipe(
      shareReplay(),
      tap((response) => {
        this.getUser(this.subject, response);
        this.setSession();
      })
    );
  }

  onLogout() {
    this.subject.next(ANONYMOUS_USER);
    localStorage.removeItem('access_token');
    localStorage.removeItem('u');
    localStorage.removeItem('showcase_access_token');
    delete window['ogSession'];
    setTimeout(() => {
      window.location.reload();
    });
  }

  /**
   * Logout
   */
  logout(): Observable<any> {
    return this.REST.post('auth/logout', null).pipe(
      shareReplay(),
      tap(() => {
        this.onLogout();
      })
    );
  }

  /**
   * Refreshes Token
   */
  refreshToken(): Observable<any> {
    return this.REST.post('auth/refresh', null).pipe(
      shareReplay(),
      tap((response) => {
        this.getUser(this.subject, response);
        this.setSession();
      })
    );
  }

  /**
   * Opens Modal to allow user to continue with
   * their session by refreshing token
   */
  private requestRefresh() {
    this.refreshTokenModal = this.modal
      .open(RefreshTokenComponent, {
        disableClose: true,
        width: '250px',
        data: {
          user: this.user(),
          token: this.getToken()
        }
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.refreshToken().subscribe(() => {
            logger('info', '').log('Refreshed Token')();
          });
        } else {
          this.refreshTokenModal = null;
          logger('info', '').log('Token Expired')();
          this.onLogout();
          this.router.navigate(['/login']);
        }
      });
  }

  /**
   * Begins session and schedules refresh request
   * according to expiration date
   */
  private setSession() {
    const currentDate = new Date();
    const expirationDate = new Date(helper.getTokenExpirationDate(this.getToken()));
    const diff = Math.abs(currentDate.getTime() - expirationDate.getTime());
    const minutesBefore = 5;
    const ms = diff - minutesBefore * 60000;

    window['ogSession'] = setTimeout(() => {
      const token = this.getToken();

      if (token !== null && !helper.isTokenExpired(token)) {
        this.requestRefresh();
      } else {
        logger('info', '').log('Token Expired')();
        this.onLogout();
        this.router.navigate(['/login']);
      }
    }, ms);
  }
}
