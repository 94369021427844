import { Injectable } from '@angular/core';

@Injectable()
export class ProgressIndicatorService {
  private _isBarVisible: boolean = false;
  public get isBarVisible(): boolean {
    return this._isBarVisible;
  }

  private _isSpinnerVisible: boolean = false;
  public get isSpinnerVisible(): boolean {
    return this._isSpinnerVisible;
  }

  constructor() {}

  showBar() {
    this._isBarVisible = true;
  }

  hideBar() {
    this._isBarVisible = false;
  }

  showSpinner() {
    this._isSpinnerVisible = true;
  }

  hideSpinner() {
    this._isSpinnerVisible = false;
  }

  reset() {
    this._isBarVisible = false;
    this._isSpinnerVisible = false;
  }
}
