<section class="profile">
    <div class="profile__container" fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="row">
            <mat-card>
                <mat-card-header>
                    <img mat-card-avatar class="profile__image" [src]="user.avatar" />
                    <mat-card-title>{{ user.first_name }} {{ user.lastname }}</mat-card-title>
                    <mat-card-subtitle>{{ user.username }}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <p><span>Company: </span> {{ user.company.name }}</p>
                    <p><span>Group: </span> {{ user.group.name }}</p>
                </mat-card-content>
            </mat-card>
        </div>
        <mat-dialog-actions fxLayout="row" class="profile__actions">
            <button mat-raised-button mat-dialog-close>Close</button>
        </mat-dialog-actions>
    </div>
</section>
