import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';

@Injectable()
export class NotificationService {
  private successMessageConfig = {
    duration: 3000,
    panelClass: 'success'
  };
  private warnMessageConfig = {
    duration: 3000,
    panelClass: 'warning'
  };
  private errorMessageConfig = {
    duration: 5000,
    panelClass: 'error'
  };

  constructor(private snackbar: MatSnackBar) {}

  /**
   * Transform message when errors object is found
   * @param msg
   */
  private transformMessage(msg) {
    let message = '';

    if (!_.isUndefined(msg.errors)) {
      let counter = 0;
      message =
        _.size(msg.errors) > 1
          ? 'The following fields are incorrect: '
          : 'The following field is incorrect: ';

      _.each(msg.errors, (error, key) => {
        counter++;
        message += key;

        if (counter === _.size(msg.errors)) {
          message += '.';
        } else {
          message += ', ';
        }
      });
    } else {
      message = msg;
    }

    return message;
  }

  /**
   * Creates alert
   * @param msg
   * @param isFull
   * @param msgType
   */
  notify(msg: string, isFull?: boolean, msgType?: string) {
    const message = this.transformMessage(msg);
    if (typeof isFull !== 'undefined' && isFull && typeof msgType !== 'undefined') {
      switch (msgType) {
        default:
          this.showSimpleMessage(message);
          break;
        case 'success':
          this.showFullMessage(message, this.successMessageConfig);
          break;
        case 'warning':
          this.showFullMessage(message, this.warnMessageConfig);
          break;
        case 'error':
          this.showFullMessage(message, this.errorMessageConfig);
          break;
      }
    } else {
      this.showSimpleMessage(message);
    }
  }

  /**
   * Creates alert for full message
   * @param msg
   * @param config
   */
  private showFullMessage(msg, config) {
    this.snackbar.open(msg, 'Close', {
      duration: config.duration,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['og-snack-full', config.panelClass]
    });
  }

  /**
   * Creates alert for a simple message
   * @param msg
   */
  private showSimpleMessage(msg) {
    this.snackbar.open(msg, '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'og-snack'
    });
  }
}
