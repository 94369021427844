<div class="log-date">
    <div class="log-date__container" mat-dialog-content>
        <h2 mat-dialog-title>{{ data.type | uppercase }} Log</h2>
        <mat-divider></mat-divider>
        <br />
        <mat-form-field appearance="fill">
            <mat-label>Choose a date</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="date" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <br />
        <button mat-raised-button color="primary" (click)="openLink()">Review</button>
        <br /><br /><br />
        <mat-divider></mat-divider>
    </div>
    <mat-dialog-actions>
        <button mat-button mat-dialog-close color="warn">Close</button>
    </mat-dialog-actions>
</div>
