import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash-es';

@Injectable()
export class EnvironmentService {
  baseUri: string =
    window.location.host.indexOf('local') >= 0 || window.location.host.indexOf('temp') >= 0
      ? environment.baseUri
      : '';
  env: string = 'prd';
  newFeatures: boolean = false;

  constructor() {
    this.getEnvironment();
  }

  /**
   * Returns the current environment
   */
  getEnvironment() {
    const host = window.location.host;

    if (host.indexOf('local') > -1 || host.indexOf('temp') > -1) {
      this.env = 'local';
      this.newFeatures = true;
    } else if (host.indexOf('dev') > -1) {
      this.env = 'dev';
      this.newFeatures = true;
    } else if (host.indexOf('stg') > -1) {
      this.env = 'stg';
      this.newFeatures = true;
    } else {
      this.env = 'prd';
      this.newFeatures = false;
    }

    return this.env;
  }

  /**
   * Returns the render url
   * according to the current env
   */
  getRenderUrl() {
    if (this.baseUri !== '') {
      return this.baseUri.replace('-management', '');
    }

    if (this.env === 'prd') {
      return window.location.origin.replace('management', 'www') + '/';
    }

    return window.location.origin.replace('-management', '') + '/';
  }

  /**
   * Returns the platform url
   * according to the current env
   */
  getPlatformUrl() {
    if (this.baseUri !== '') {
      return _.endsWith(this.baseUri, '/') ? this.baseUri : this.baseUri + '/';
    }

    return window.location.origin + '/';
  }

  /**
   * Returns boolean to know if the current environment supports
   * new features or not
   */
  withNewFeatures() {
    return this.newFeatures;
  }

  /**
   * Returns the cdn url
   * according to the current env
   */
  getCDNUrl() {
    if (this.baseUri !== '') {
      return this.baseUri.replace('-management', '-cdn');
    }

    if (this.env === 'prd') {
      return window.location.origin.replace('management', 'cdn') + '/';
    }

    return window.location.origin.replace('-management', '-cdn') + '/';
  }
}
