import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { RESTService } from '../../common/services/rest.service';
import { EnvironmentService } from '../../common/services/environment.service';

@Injectable({
  providedIn: 'root'
})
export class HelpService {
  constructor(
    private http: HttpClient,
    protected rest: RESTService,
    private env: EnvironmentService
  ) {}

  /**
   * Returns Plugins help (v2)
   */
  getPluginsHelp(): Observable<any> {
    return this.http.get(this.rest.baseUri + 'api/v1/e/help/features');
  }

  /**
   * Returns Tools help (v3)
   */
  getToolsHelp(): Observable<any> {
    return this.http.get(this.env.getRenderUrl() + 'api/v1/tools');
  }

  /**
   * Returns metrics help
   */
  getMetricsHelp(): Observable<any> {
    return this.http.get(this.rest.baseUri + 'api/v1/e/help/metrics');
  }

  /**
   * Returns Events Constant (v3)
   */
  getEvents(): Observable<any> {
    return this.http.get(this.env.getRenderUrl() + 'api/v1/events');
  }

  /**
   * Returns metrics products
   */
  getProducts(): Observable<any> {
    return this.http.get(this.rest.baseUri + 'api/v1/e/help/products');
  }

  /**
   * Returns Main API (v3)
   */
  getMainAPI(): Observable<any> {
    return this.http.get(this.env.getRenderUrl() + 'api/v1/docs');
  }

  /**
   * Returns Tools API (v3)
   */
  getToolsAPI(): Observable<any> {
    return this.http.get(this.env.getRenderUrl() + 'api/v1/tools/api');
  }
}
