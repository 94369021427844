import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash-es';
import { logger } from '../../services/logger.service';
import { VideoEventsService } from '../../services/video-events.service';

declare const canAutoplay: any;

@Component({
  selector: 'og-video-ad',
  templateUrl: './video-ad.component.html',
  styleUrls: ['./video-ad.component.scss']
})
export class VideoAdComponent implements OnInit, OnDestroy {
  @Input('xml') xml: string;
  @Input('video') video: string;
  private scripts: Array<{
    id: string;
    src: string;
    loaded: boolean;
  }> = [
    {
      id: 'can-autoplay_js',
      src: 'https://cdn.jsdelivr.net/npm/can-autoplay@3.0.0/build/can-autoplay.min.js',
      loaded: false
    },
    {
      id: 'videojs_js',
      src: 'https://cdnjs.cloudflare.com/ajax/libs/video.js/7.9.5/video.min.js',
      loaded: false
    },
    {
      id: 'ima3_js',
      src: 'https://imasdk.googleapis.com/js/sdkloader/ima3.js',
      loaded: false
    },
    {
      id: 'videojs_ads_js',
      src: 'https://cdnjs.cloudflare.com/ajax/libs/videojs-contrib-ads/6.9.0/videojs.ads.min.js',
      loaded: false
    },
    {
      id: 'videojs_ima_js',
      src: 'https://cdnjs.cloudflare.com/ajax/libs/videojs-ima/2.1.0/videojs.ima.min.js',
      loaded: false
    }
  ];
  private styles: Array<{
    id: string;
    href: string;
    loaded: boolean;
  }> = [
    {
      id: 'videojs_css',
      href: 'https://cdnjs.cloudflare.com/ajax/libs/video.js/6.7.4/video-js.min.css',
      loaded: false
    },
    {
      id: 'videojs_contrib-ads_css',
      href: 'https://cdnjs.cloudflare.com/ajax/libs/videojs-contrib-ads/6.9.0/videojs.ads.min.css',
      loaded: false
    },
    {
      id: 'videojs_ima_css',
      href: 'https://cdnjs.cloudflare.com/ajax/libs/videojs-ima/2.1.0/videojs.ima.min.css',
      loaded: false
    }
  ];
  ready: {
    loaded: boolean;
    init: boolean;
    player: boolean;
  } = {
    loaded: false,
    init: false,
    player: false
  };
  private options: any = {
    width: 736,
    height: 414,
    fluid: true,
    playsinline: true,
    preload: 'auto'
  };
  private autoplayAllowed: boolean = false;
  private autoplayRequiresMute: boolean = false;
  private startEvent: string = 'click';
  private player: any = null;

  constructor(private videoEventsService: VideoEventsService) {
    window.addEventListener('message', this.listen.bind(this), false);
  }

  ngOnInit() {
    const loaded = {
      scripts: false,
      styles: false
    };
    this.options.autoplay = this.video === 'autoplay';
    this.options.muted = this.video === 'autoplay';
    this.loadScripts(() => {
      loaded.scripts = true;
    });
    this.loadStyles(() => {
      loaded.styles = true;
    });
    const onReady = setInterval(() => {
      if (loaded.styles && loaded.scripts) {
        clearInterval(onReady);
        this.ready.loaded = true;
        setTimeout(() => {
          if (this.video === 'autoplay') {
            this.checkUnmutedAutoplaySupport();
          } else {
            this.initPlayer();
          }
        });
      }
    });
    this.ready.init = true;
  }

  /**
   * Listen to post messages
   * @param e
   */
  private listen(e: MessageEvent) {
    if (e.data.source === 'OG-VPAID' && e.data.type === 'command') {
      this.videoEventsService.addEvent('render', e.data.data);
    }

    if (e.type === 'message' && e.origin.indexOf('imasdk.googleapis.com') > -1) {
      const data = e.data.replace('ima://', '');
      this.videoEventsService.addEvent('google', JSON.parse(data));
    }
  }

  /**
   * Checks if it can play unmuted
   */
  private checkUnmutedAutoplaySupport() {
    canAutoplay
      .video({
        timeout: 100,
        muted: false
      })
      .then(({ result, error }) => {
        if (result === false) {
          // Unmuted autoplay is not allowed.
          this.checkMutedAutoplaySupport();
        } else {
          // Unmuted autoplay is allowed.
          this.autoplayAllowed = true;
          this.autoplayRequiresMute = false;
          this.initPlayer();
        }
      });
  }

  /**
   * Checks if can play muted
   */
  private checkMutedAutoplaySupport() {
    canAutoplay
      .video({
        timeout: 100,
        muted: true
      })
      .then(({ result, error }) => {
        if (result === false) {
          // Muted autoplay is not allowed.
          this.autoplayAllowed = false;
          this.autoplayRequiresMute = false;
        } else {
          // Muted autoplay is allowed.
          this.autoplayAllowed = true;
          this.autoplayRequiresMute = true;
        }
        this.initPlayer();
      });
  }

  /**
   * Initializes Ad Display Container
   */
  private initAdDisplayContainer() {
    this.player.ima.initializeAdDisplayContainer();
    this.player.removeEventListener(this.startEvent, this.initAdDisplayContainer.bind(this));
  }

  /**
   * Initializes Ad
   */
  private initAd() {
    logger('info', 'Player').log('Add to preview route ?video=taptoplay for removing autoplay')();

    if (this.xml !== '') {
      logger('info', '').log('Using xml: ', this.xml)();
    } else {
      logger('error', '').log('XML is empty')();
      return;
    }

    this.player.ima({
      id: 'video-adunit',
      // Hardcoding tag for testing, this needs to be replaced with this.xml
      // 'https://www.originplatform.com/video/vpaid/6681?debug=true',
      adTagUrl: this.xml,
      adsManagerLoadedCallback: this.initImaEvents.bind(this),
      debug: true
    });

    if (!this.options.autoplay) {
      if (
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/Android/i)
      ) {
        this.startEvent = 'touchend';
      }
      this.player.one(this.startEvent, this.initAdDisplayContainer.bind(this));
    }
  }

  /**
   * Initializes player
   */
  private initPlayer() {
    this.ready.player = true;

    setTimeout(() => {
      this.player = window['videojs'](document.querySelector('.video-js'), this.options);
      this.player.on('click', () => {
        this.player.muted(false);
      });

      this.initAd();
    });
  }

  /**
   * Init IMA Events for VAST/VPAID
   * @param e
   */
  private initImaEvents(e) {
    // GOOGLE EVENTS
    // https://github.com/googleads/videojs-ima/blob/master/examples/advanced/ads.js#L77
    // google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
    // google.ima.AdEvent.Type.CLICK,
    // google.ima.AdEvent.Type.COMPLETE,
    // google.ima.AdEvent.Type.FIRST_QUARTILE,
    // google.ima.AdEvent.Type.LOADED,
    // google.ima.AdEvent.Type.MIDPOINT,
    // google.ima.AdEvent.Type.PAUSED,
    // google.ima.AdEvent.Type.RESUMED,
    // google.ima.AdEvent.Type.STARTED,
    // google.ima.AdEvent.Type.THIRD_QUARTILE

    this.player.ima.addEventListener(window['google'].ima.AdEvent.Type.STARTED, () => {
      this.player.ima.isPlaying = true;
      if (this.video !== 'autoplay') {
        const btn = document.getElementById('video-adunit_ima-mute-div');
        btn.dispatchEvent(new Event('click'));
        setTimeout(() => {
          btn.dispatchEvent(new Event('click'));
        }, 300);
      }
    });
    this.player.ima.addEventListener(window['google'].ima.AdEvent.Type.COMPLETE, () => {
      this.player.ima.isPlaying = false;
    });
  }

  /**
   * Loads all necessary scripts
   * @param cb
   */
  private loadScripts(cb: Function) {
    const addScript = (i) => {
      if (_.isUndefined(this.scripts[i])) {
        return;
      }
      const script = document.createElement('script');
      script.src = this.scripts[i].src;
      script.id = this.scripts[i].id;
      script.addEventListener(
        'load',
        () => {
          this.scripts[i].loaded = true;
          const scriptsLoaded = _.filter(this.scripts, { loaded: true });
          if (scriptsLoaded.length === this.scripts.length) {
            cb();
          } else {
            addScript(i + 1);
          }
        },
        false
      );
      document.getElementsByTagName('head')[0].appendChild(script);
    };
    addScript(0);
  }

  /**
   * Loads all necessary styles
   * @param cb
   */
  private loadStyles(cb: Function) {
    _.forEach(this.styles, (st, i) => {
      const style = document.createElement('link');
      style.href = st.href;
      style.rel = 'stylesheet';
      style.id = st.id;
      style.addEventListener(
        'load',
        () => {
          this.styles[i].loaded = true;
          const stylesLoaded = _.filter(this.styles, { loaded: true });
          if (stylesLoaded.length === this.styles.length) {
            cb();
          }
        },
        false
      );
      document.getElementsByTagName('head')[0].appendChild(style);
    });
  }

  /**
   * on destroy
   */
  ngOnDestroy() {
    window.removeEventListener('message', this.listen.bind(this), false);

    if (this.player !== null) {
      this.player.dispose();
      this.player = null;
    }
  }
}
