import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'og-preview-device',
  templateUrl: './device.component.html'
})
export class DeviceComponent implements OnInit {
  @Output() closeDevice = new EventEmitter<boolean>();
  @Output() onDeviceChange = new EventEmitter<any>();
  @Output() onSizeChange = new EventEmitter<any>();
  @Input() currentDevice = 'desktop';
  @Input() deviceSize;
  @Input() isPortrait = false;

  /**
   * Constant for our device sizes.
   */
  private sizes = {
    phone: {
      portrait: {
        small: {
          width: 338,
          height: 568
        },
        medium: {
          width: 393,
          height: 667
        },
        large: {
          width: 432,
          height: 736
        }
      },
      landscape: {
        small: {
          width: 568,
          height: 232
        },
        medium: {
          width: 667,
          height: 331
        },
        large: {
          width: 736,
          height: 370
        }
      }
    },
    tablet: {
      portrait: {
        small: {
          width: 600,
          height: 1024
        },
        medium: {
          width: 768,
          height: 1024
        },
        large: {
          width: 800,
          height: 1280
        }
      },
      landscape: {
        small: {
          width: 1024,
          height: 600
        },
        medium: {
          width: 1024,
          height: 768
        },
        large: {
          width: 1280,
          height: 800
        }
      }
    }
  };

  constructor() {}

  ngOnInit() {}

  /**
   * Closes panel
   */
  close() {
    this.closeDevice.emit();
  }

  /**
   * Get initial selectedIndex depending on current device.
   */
  getSelectedIndex() {
    switch (this.currentDevice) {
      case 'phone':
        return 0;
      case 'tablet':
        return 1;
      case 'desktop':
      default:
        return 2;
    }
  }

  /**
   * Set the device preview according to selected option.
   */
  onDeviceSelected(evt) {
    switch (evt) {
      case 0:
        this.onDeviceChange.emit({ deviceType: 'phone' });
        break;
      case 1:
        this.onDeviceChange.emit({ deviceType: 'tablet' });
        break;
      case 2:
        this.onDeviceChange.emit({ deviceType: 'desktop' });
        break;
      default:
        break;
    }
  }

  /**
   * Toggle device size.
   */
  onSizeSelected(evt) {
    this.onSizeChange.emit({ size: evt.value });
  }

  /**
   * Gets the device viewport & displays it on pixels.
   * @private
   * @return device dimetions/viewport
   */
  getCurrentSize() {
    const orientation = this.isPortrait ? 'landscape' : 'portrait';

    if (typeof this.currentDevice === 'undefined' || this.currentDevice === '') {
      this.currentDevice = 'desktop';
    }

    if (this.currentDevice !== 'desktop') {
      return (
        this.sizes[this.currentDevice][orientation][this.deviceSize].width +
        ' x ' +
        this.sizes[this.currentDevice][orientation][this.deviceSize].height
      );
    } else {
      return window.innerWidth + ' x ' + window.innerHeight;
    }
  }
}
