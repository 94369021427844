<br />
<div [formGroup]="form" class="sub-form">
    <div class="flex--row mar-5-15">
        <h3 class="sub-title">Width Behavior</h3>
        <mat-radio-group formControlName="widthBehavior">
            <mat-radio-button value="fullscreen">Fullscreen Width</mat-radio-button>
            <mat-radio-button value="container">Container Width</mat-radio-button>
        </mat-radio-group>
    </div>
</div>
