<section class="embed-generator">
    <div class="flex-set flex--content-between flex--align-items-center sub-header-flexed">
        <div class="flex--column">
            <h3 class="flex--row">Embed Generator</h3>

            <ul class="flex--row embed-generator__info" *ngIf="ready">
                <li>
                    <span class="data-label">Creative name:</span>
                    <span class="data-item">{{ ad.title }}</span>
                </li>
                <li>
                    <span class="data-label">Creative ID:</span>
                    <span class="data-item">{{ ad.id }}</span>
                </li>
                <li>
                    <span class="data-label">Ad format:</span>
                    <span class="data-item">{{ ad.creative.format.name }}</span>
                </li>
                <li>
                    <span class="data-label">Product type:</span>
                    <span class="data-item">{{ ad.product.name }}</span>
                </li>
            </ul>
        </div>
    </div>

    <form [formGroup]="form" *ngIf="ready">
        <div
            class="flex-set flex--align-content-stretch embed-generator__steps flex--content-around"
        >
            <div class="flex--column flex--align-self-start">
                <h2 class="flex--row">(1)</h2>
                <br />
                <mat-form-field class="flex--row">
                    <mat-select placeholder="Environment" formControlName="environment">
                        <mat-option *ngFor="let env of environments" [value]="env.value">{{
                            env.label
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="flex--row" *ngIf="ad.creative.format.type.name === 'display'">
                    <div [ngSwitch]="ad.creative.format.name">
                        <og-eg-d-banner
                            *ngSwitchCase="'Banner'"
                            [creative]="ad.creative"
                        ></og-eg-d-banner>
                        <og-eg-d-expandable-banner
                            *ngSwitchCase="'Expandable Banner'"
                            [creative]="ad.creative"
                        ></og-eg-d-expandable-banner>
                        <og-eg-d-reveal
                            *ngSwitchCase="'Reveal'"
                            [creative]="ad.creative"
                        ></og-eg-d-reveal>
                        <og-eg-d-collapsable-banner
                            *ngSwitchCase="'Collapsable Banner'"
                            [creative]="ad.creative"
                        ></og-eg-d-collapsable-banner>
                    </div>
                </div>

                <div class="flex--row" *ngIf="ad.creative.format.type.name === 'richmedia'">
                    <div [ngSwitch]="ad.creative.format.name">
                        <og-eg-rm-banner
                            *ngSwitchCase="'Banner'"
                            [creative]="ad.creative"
                        ></og-eg-rm-banner>
                        <og-eg-rm-expandable-banner
                            *ngSwitchCase="'Expandable Banner'"
                            [creative]="ad.creative"
                        ></og-eg-rm-expandable-banner>
                        <og-eg-rm-interscroller
                            *ngSwitchCase="'Interscroller'"
                            (formChange)="onSubFormChanged($event)"
                            [creative]="ad.creative"
                        ></og-eg-rm-interscroller>
                        <og-eg-rm-interstitial
                            *ngSwitchCase="'Interstitial'"
                            [creative]="ad.creative"
                        ></og-eg-rm-interstitial>
                        <og-eg-rm-reveal
                            *ngSwitchCase="'Reveal'"
                            [creative]="ad.creative"
                        ></og-eg-rm-reveal>
                        <og-eg-rm-universal-banner
                            *ngSwitchCase="'Universal Banner'"
                            [creative]="ad.creative"
                        ></og-eg-rm-universal-banner>
                        <og-eg-rm-universal-expandable
                            *ngSwitchCase="'Universal Expandable'"
                            [creative]="ad.creative"
                        ></og-eg-rm-universal-expandable>
                        <og-eg-rm-universal-miniscroller
                            *ngSwitchCase="'Universal Miniscroller'"
                            [creative]="ad.creative"
                        ></og-eg-rm-universal-miniscroller>
                        <og-eg-rm-universal-reveal
                            *ngSwitchCase="'Universal Reveal'"
                            [creative]="ad.creative"
                        ></og-eg-rm-universal-reveal>
                        <og-eg-rm-overture
                            *ngSwitchCase="'Overture'"
                            [creative]="ad.creative"
                        ></og-eg-rm-overture>
                        <og-eg-rm-collapsable-banner
                            *ngSwitchCase="'Collapsable Banner'"
                            [creative]="ad.creative"
                        ></og-eg-rm-collapsable-banner>
                        <og-eg-rm-native-banner
                            *ngSwitchCase="'Native Banner'"
                            [creative]="ad.creative"
                        ></og-eg-rm-native-banner>
                    </div>
                </div>

                <div
                    class="flex--row"
                    *ngIf="
                        ad.creative.format.type.name === 'video' &&
                        ad.creative.format.name !== 'Video'
                    "
                >
                    <div [ngSwitch]="ad.creative.format.name">
                        <og-eg-v-vertical-in-article
                            *ngSwitchCase="'Vertical In-article'"
                            (formChange)="onSubFormChanged($event)"
                            [creative]="ad.creative"
                        ></og-eg-v-vertical-in-article>
                        <og-eg-v-square-in-article
                            *ngSwitchCase="'Square In-article'"
                            (formChange)="onSubFormChanged($event)"
                            [creative]="ad.creative"
                        ></og-eg-v-square-in-article>
                        <og-eg-v-landscape-in-article
                            *ngSwitchCase="'Landscape In-article'"
                            (formChange)="onSubFormChanged($event)"
                            [creative]="ad.creative"
                        ></og-eg-v-landscape-in-article>
                        <og-eg-v-video-interscroller
                            *ngSwitchCase="'Video Interscroller'"
                            (formChange)="onSubFormChanged($event)"
                            [creative]="ad.creative"
                        ></og-eg-v-video-interscroller>
                    </div>
                </div>
            </div>
            <div class="flex--column flex--align-self-start">
                <h2 class="flex--row">(2)</h2>
                <br />
                <mat-form-field class="flex--row">
                    <mat-select placeholder="Ad Server" formControlName="adserver">
                        <mat-option *ngFor="let server of adservers" [value]="server.value">{{
                            server.label
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div
            class="embed-generator__trafficking flex-set flex--align-items-center"
            *ngIf="ready && properties.adserver !== ''"
            [ngSwitch]="properties.adserver"
        >
            <div class="flex--column full-width">
                <h1 class="embed-generator__trafficking__header flex--row">
                    <span>Trafficking instructions</span>
                </h1>

                <!--
                  We are only supporting DFP for now,
                  In case we begin to support more ad servers
                  we will need to add more components
                  into the  adservers folder
              -->
                <div class="flex--row" *ngIf="!isVideoAd(ad)">
                    <og-as-ad-manager
                        *ngSwitchCase="'Ad Manager'"
                        [producttype]="ad.creative.format.type.name"
                    ></og-as-ad-manager>
                </div>

                <div *ngIf="embedCode !== ''" class="flex--row embed-generator__trafficking__code">
                    <div class="flex--row">
                        <button
                            class="embed-generator__trafficking__code__download"
                            (click)="download()"
                            title="Click to download embed code"
                            mat-button
                        >
                            <mat-icon>cloud_download</mat-icon>
                        </button>
                        <button
                            *ngIf="embedCode !== '' && isVideoAd(ad)"
                            mat-button
                            title="VAST Inspector"
                            (click)="validateEmbed()"
                        >
                            <mat-icon>find_replace</mat-icon>
                        </button>
                        <button
                            class="embed-generator__trafficking__code__copy"
                            (click)="copy()"
                            title="Click to copy embed code to clipboard"
                            mat-button
                        >
                            <mat-icon>content_copy</mat-icon>
                        </button>
                    </div>

                    <div class="flex--row">
                        <pre>
                            <code>{{ embedCode }}</code>
                        </pre>
                    </div>
                </div>
                <div
                    *ngIf="embedCode !== '' && isVideoAd(ad)"
                    class="flex--row embed-generator__trafficking__notes"
                >
                    <p>
                        Please ensure included macros are replaced by the ad server to avoid
                        breaking the
                        <strong>{{ properties.environment }}</strong> tag URL.
                    </p>
                </div>
            </div>
        </div>
    </form>
</section>
