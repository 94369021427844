import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class VideoEventsService {
  eventAdded: EventEmitter<any> = new EventEmitter();
  summary: any = {
    render: [],
    google: []
  };

  /**
   * Add Event
   * @param type
   * @param data
   */
  addEvent(type, data) {
    this.summary[type].push(data);
    this.eventAdded.emit({
      type,
      data
    });
    parent.postMessage(
      {
        source: 'OG-PLATFORM',
        type: 'command',
        data: {
          type,
          data
        }
      },
      '*'
    );
  }
}
