import { Injectable, EventEmitter } from '@angular/core';
import { RESTService } from '../../common/services/rest.service';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AdService {
  adLoaded = new EventEmitter<any>();

  constructor(
    protected rest: RESTService,
    private http: HttpClient
  ) {}

  /**
   * Returns ad unit
   * @param id
   */
  get(id: number): Observable<any> {
    return this.http.get(this.rest.baseUri + 'api/v1/e/preview/ad/' + id).pipe(
      tap((response) => {
        return response.data;
      })
    );
  }
}
