import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class RESTService {
  baseUri: string = window.location.hostname.indexOf('local') >= 0 ? environment.baseUri : '';

  constructor(private http: HttpClient) {}

  get(endpoint) {
    return this.http.get(this.baseUri + 'api/v1/i/' + endpoint);
  }

  post(endpoint, params) {
    return this.http.post(this.baseUri + 'api/v1/i/' + endpoint, params);
  }

  put(endpoint, params) {
    return this.http.put(this.baseUri + 'api/v1/i/' + endpoint, params);
  }

  patch(endpoint, params) {
    return this.http.patch(this.baseUri + 'api/v1/i/' + endpoint, params);
  }

  delete(endpoint) {
    return this.http.delete(this.baseUri + 'api/v1/i/' + endpoint);
  }
}
