import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'og-as-ad-manager',
  templateUrl: './ad-manager.component.html'
})
export class AdManagerComponent implements OnInit {
  @Input('producttype') producttype: any;

  constructor() {}

  ngOnInit() {}
}
