import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'og-eg-rm-universal-expandable',
  templateUrl: './universal-expandable.component.html'
})
export class EmbedUniversalExpandableComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
