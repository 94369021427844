import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RESTService } from '../../common/services/rest.service';

@Injectable()
export class PurgeService {
  constructor(protected rest: RESTService) {}

  entity: string = 'purge';

  purgeHostname(): Observable<any> {
    return this.rest.post(this.entity, {});
  }

  purgeRender(): Observable<any> {
    return this.rest.post(this.entity + '/render', {});
  }

  purgeUrl(url): Observable<any> {
    return this.rest.post(this.entity + '/url', {
      url: url
    });
  }
}
