import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'og-eg-d-reveal',
  templateUrl: './reveal.component.html'
})
export class EmbedRevealComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
