import { Component, OnDestroy, OnInit } from '@angular/core';
import { HelpService } from '../../services/help.service';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import { Subscription } from 'rxjs';
import * as _ from 'lodash-es';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'og-help-products',
  templateUrl: './help-products.component.html'
})
export class HelpProductsComponent implements OnInit, OnDestroy {
  adVersion: string = 'v3';
  types;
  private subs: Subscription[] = [];

  constructor(
    private _helpService: HelpService,
    private progressService: ProgressIndicatorService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    const self = this;
    self.progressService.showBar();

    if (typeof self.activatedRoute.snapshot.queryParams['version'] !== 'undefined') {
      self.subs[1] = self.activatedRoute.queryParams.subscribe((param: any) => {
        if (typeof param['version'] !== 'undefined') {
          self.adVersion = 'v' + param['version'];
        } else {
          self.adVersion = 'v3';
        }
      });
    }

    self.subs[0] = self._helpService.getProducts().subscribe((response) => {
      self.types = response.data;
      self.progressService.hideBar();
    });
  }

  /**
   * Returns types filtered by ad version
   * @param types
   */
  getTypes(types) {
    return _.filter(types, { version: this.adVersion });
  }

  /**
   * Returns Map for products
   * @param products
   */
  getProducts(products) {
    return _.map(products);
  }

  /**
   * Parses Id to Name
   * @param val
   */
  private parseToIdName(val: string): string {
    return 'pod_' + val.replace(/\s/g, '');
  }

  ngOnDestroy() {
    _.each(this.subs, (s) => {
      s.unsubscribe();
    });
  }
}
