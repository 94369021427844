import { BrowserModule } from '@angular/platform-browser';
import { NgModule, enableProdMode } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { OverlayContainer } from '@angular/cdk/overlay';
import { SortablejsModule } from 'ngx-sortablejs';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { AuthService } from './auth/auth.service';
import { AuthGuardService } from './auth/auth-guard.service';
import { GroupGuardService } from './auth/group-guard.service';
import { TokenInterceptor } from './auth/token.interceptor';
import { OriginCommonModule } from './common/common.module';
import { PublicModule } from './public/public.module';
import { NotificationService } from './common/services/notification.service';
import { ProgressIndicatorService } from './common/services/progress-indicator.service';
import { CONFIG } from './common/config';
import { logger } from './common/services/logger.service';
import { PublicRouting } from './public-routing.module';
import { PrivateRouting } from './private-routing.module';
import { RefreshTokenComponent } from './auth/refresh-token/refresh-token.component';

@NgModule({
  declarations: [AppComponent, RefreshTokenComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    JwtModule,
    FlexLayoutModule,
    FormsModule,
    SortablejsModule.forRoot({
      animation: 150
    }),
    OriginCommonModule,
    PublicModule,
    PrivateRouting,
    PublicRouting
  ],
  providers: [
    AuthService,
    NotificationService,
    AuthGuardService,
    GroupGuardService,
    ProgressIndicatorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  entryComponents: [RefreshTokenComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(overlayContainer: OverlayContainer) {
    let debug = true;
    overlayContainer.getContainerElement().classList.add('og-overlay');

    if (environment.production) {
      debug = false;
    }

    if (window.location.href.indexOf('debug=true') > -1) {
      debug = true;
    }

    CONFIG.DEBUG = debug;
    logger('debug', '').log('DEBUG mode:', CONFIG.DEBUG)();

    window['ogPlatform'] = true;
  }
}
