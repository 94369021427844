import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '../../../common/services/environment.service';
import { RESTService } from '../../../common/services/rest.service';
import { logger } from '../../../common/services/logger.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class ShowcaseService {
  private hosts = {
    local: 'http://local-showcase.evolvemediallc.com',
    dev: 'https://dev-showcase.evolvemediallc.com',
    stg: 'https://stg-showcase.evolvemediallc.com',
    prd: 'https://showcase.evolvemediallc.com'
  };
  private host: string = 'https://showcase.evolvemediallc.com';
  private api: string = '/api/v1/external/';
  baseUri: string = '';
  token: string = localStorage.getItem('showcase_access_token');
  tokenReady: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private environmentService: EnvironmentService,
    private http: HttpClient,
    public rest: RESTService
  ) {
    this.host = this.hosts[environmentService.getEnvironment()];
    this.baseUri = this.host + this.api;
    if (localStorage.getItem('showcase_access_token') === null) {
      this.getCredentials().subscribe((data) => {
        const helper = new JwtHelperService();
        this.auth(helper.decodeToken(data['data']['showcase']).showcase);
      });
    } else {
      setTimeout(() => {
        this.tokenReady.emit(true);
      });
    }
  }

  /**
   * Returns Demo Url
   */
  getDemoUrl() {
    return this.host + '/#/demo/[DOMAIN]/[SITE_HASH]/c/[CREATIVE_HASH]';
  }

  private getCredentials() {
    return this.rest.get('services/showcase');
  }

  /**
   * Authenticates origin to connect with showcase
   * @param credentials
   */
  private auth(credentials) {
    const endpoint = 'access_token';
    this.http.post(this.baseUri + endpoint, credentials).subscribe(
      (response) => {
        this.token = response['access_token'];
        localStorage.setItem('showcase_access_token', this.token);
        this.tokenReady.emit(true);
      },
      (errorResponse) => {
        this.token = '';
        logger('error', '').error(
          'There was an error trying to connect to Showcase',
          errorResponse
        )();
        this.tokenReady.emit(false);
      }
    );
  }

  /**
   * Returns Creative if it exists
   * @param id
   */
  getCreative(id: number | null = null) {
    if (id === null) {
      return;
    }
    const endpoint = 'creatives/' + id;
    return this.http.get(this.baseUri + endpoint + '?token=' + this.token);
  }

  /**
   * Returns the latest 10 campaigns
   */
  getCampaigns() {
    const endpoint = 'campaigns/all?limit=none';
    return this.http.get(this.baseUri + endpoint + '&token=' + this.token);
  }

  /**
   * Returns the latest 10 sites
   */
  getSites() {
    const endpoint = 'sites/all?limit=none';
    return this.http.get(this.baseUri + endpoint + '&token=' + this.token);
  }

  /**
   * Saves the campaign
   * @param name
   * @param brand
   */
  saveCampaign(name = '', brand = '') {
    const endpoint = 'campaigns/new';
    return this.http.post(this.baseUri + endpoint + '?token=' + this.token, {
      name,
      brand
    });
  }

  /**
   * Saves creative on Showcase
   * @param params
   */
  saveCreative(
    params: {
      adId: number | null;
      brand: string;
      campaign_id: number | null;
      category: string;
      data: string;
      jira: string;
      platform: string;
      preview: string;
      product: string;
      title: string;
      user: string;
    } = {
      adId: null,
      brand: '',
      campaign_id: null,
      category: '',
      data: '',
      jira: '',
      platform: '',
      preview: '',
      product: '',
      title: '',
      user: ''
    }
  ) {
    const endpoint = 'creatives/new';
    return this.http.post(this.baseUri + endpoint + '?token=' + this.token, params);
  }
}
