<br />
<div [formGroup]="form" class="sub-form">
    <div class="flex--row">
        <h3 class="sub-title">Notice Bar</h3>
    </div>
    <div class="flex--row flex-set flex--content-between mar-15-v">
        <div class="flex--column">
            <mat-label class="label">Text Color:</mat-label>
        </div>
        <div class="flex--column">
            <input
                [ngStyle]="{ width: '30px' }"
                matTooltip="Choose a color"
                readonly
                class="colorpicker"
                [(colorPicker)]="textColor"
                [style.background]="textColor"
                (colorPickerChange)="form.get('textColor').setValue($event)"
                [cpUseRootViewContainer]="true"
            />
        </div>
    </div>
    <div class="flex--row flex-set flex--content-between mar-15-v">
        <div class="flex--column">
            <mat-label class="label">Bar Color:</mat-label>
        </div>
        <div class="flex--column">
            <input
                [ngStyle]="{ width: '30px' }"
                matTooltip="Choose a color"
                readonly
                class="colorpicker"
                [(colorPicker)]="barColor"
                [style.background]="barColor"
                (colorPickerChange)="form.get('barColor').setValue($event)"
                [cpUseRootViewContainer]="true"
            />
        </div>
    </div>
    <div class="flex--row mar-5-v">
        <mat-form-field class="full-width">
            <input type="text" matInput placeholder="Top Message" formControlName="topMessage" />
            <mat-error *ngIf="form.get('topMessage')?.invalid">
                {{ getControlError('topMessage') }}
            </mat-error>
        </mat-form-field>
    </div>
    <div class="flex--row flex-set flex--content-between mar-15-v">
        <div class="flex--column">
            <mat-label class="label">Background Color:</mat-label>
        </div>
        <div class="flex--column">
            <input
                [ngStyle]="{ width: '30px' }"
                matTooltip="Choose a color"
                readonly
                class="colorpicker"
                [(colorPicker)]="bgColor"
                [style.background]="bgColor"
                (colorPickerChange)="form.get('bgColor').setValue($event)"
                [cpUseRootViewContainer]="true"
            />
        </div>
    </div>
    <div class="flex--row mar-5-v">
        <mat-form-field class="full-width">
            <input type="text" matInput placeholder="Background Image" formControlName="bgImage" />
            <mat-error *ngIf="form.get('bgImage')?.invalid">
                {{ getControlError('bgImage', 'Only http/https & .jpg|.png|.gif are allowed.') }}
            </mat-error>
        </mat-form-field>
    </div>
    <div class="flex--row mar-5-v">
        <mat-form-field class="full-width">
            <input
                type="text"
                matInput
                placeholder="Bottom Message"
                formControlName="bottomMessage"
            />
            <mat-error *ngIf="form.get('bottomMessage')?.invalid">
                {{ getControlError('bottomMessage') }}
            </mat-error>
        </mat-form-field>
    </div>
    <div class="flex--row mar-5-15">
        <h3 class="sub-title">Position offset (in pixels)</h3>
    </div>
    <div class="flex--row mar-5-v">
        <mat-form-field class="full-width">
            <input
                type="text"
                matInput
                placeholder="Top Position"
                formControlName="positionOffsetTop"
            />
            <mat-error *ngIf="form.get('positionOffsetTop')?.invalid">
                {{ getControlError('positionOffsetTop', 'auto or number accepted only.') }}
            </mat-error>
        </mat-form-field>
    </div>
    <div class="flex--row mar-5-v">
        <mat-form-field class="full-width">
            <input
                type="text"
                matInput
                placeholder="Bottom Position"
                formControlName="positionOffsetBottom"
            />
            <mat-error *ngIf="form.get('positionOffsetBottom')?.invalid">
                {{ getControlError('positionOffsetBottom', 'auto or number accepted only.') }}
            </mat-error>
        </mat-form-field>
    </div>
    <div class="flex--row mar-5-15">
        <h3 class="sub-title">Width Behavior</h3>
        <mat-radio-group formControlName="widthBehavior">
            <mat-radio-button value="fullscreen">Fullscreen Width</mat-radio-button>
            <mat-radio-button value="container">Container Width</mat-radio-button>
        </mat-radio-group>
    </div>
</div>
