import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash-es';
import { JwtHelperService } from '@auth0/angular-jwt';

const helper = new JwtHelperService();

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService, private router: Router, public snackBar: MatSnackBar) {}

  /**
   * Opens Snack Bar with a given message
   * @param msg
   */
  private withMessage(msg = '') {
    this.snackBar.dismiss();
    this.snackBar.open(msg, 'Dismiss', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  /**
   * On token expired
   * @param request
   */
  private onTokenExpired(request) {
    this.auth.onLogout();
    this.router.navigate(['/login']).then(() => {
      this.withMessage('Session Expired');
    });
    this.auth.collectFailedRequest(request);
  }

  /**
   * Intercept Requests
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      request.url.indexOf('originplatform') === -1 &&
      !_.startsWith(request.url, 'api/v1/i') &&
      !_.startsWith(request.url, '/api/v1/i')
    ) {
      return next.handle(request);
    }

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getToken()}`
      }
    });

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
          }
        },
        (err: any) => {
          const tokenExpired = helper.isTokenExpired(this.auth.getToken());

          if (tokenExpired) {
            this.onTokenExpired(request);
            return;
          }

          if (err instanceof HttpErrorResponse) {
            switch (err.status) {
              case 401:
              case 403:
                if (err.error.error === 'token_expired' || err.error.error === 'Unauthenticated.') {
                  this.onTokenExpired(request);
                  return;
                } else {
                  if (this.auth.isAuthenticated()) {
                    this.router.navigate(['/dashboard']).then(() => {
                      this.withMessage('Unauthorized');
                    });
                  } else {
                    this.router.navigate(['/login']).then(() => {
                      this.withMessage('Unauthorized');
                    });
                  }
                }
                break;
              case 404:
                this.router.navigate(['/dashboard']).then(() => {
                  this.withMessage('Not Found');
                });
                break;
              case 524:
                this.withMessage('An error occur, please contact origintech@evolvemediallc.com');
                return;
              default:
                this.withMessage(err.error || err.message);
                return;
            }
          }
        }
      )
    );
  }
}
