import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy } from '@angular/core';
import { HelpService } from '../../services/help.service';
import * as showdown from 'showdown';
import { DomSanitizer } from '@angular/platform-browser';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import * as _ from 'lodash-es';
import { forkJoin, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'og-help-features',
  templateUrl: './help-features.component.html',
  encapsulation: ViewEncapsulation.None
})
export class HelpFeaturesComponent implements OnInit, OnDestroy {
  @ViewChild('text', { static: true }) text;
  data = [];
  links = {
    v2: [],
    v3: []
  };
  private subs: Subscription[] = [];
  adVersion = 'v3';

  constructor(
    private helpService: HelpService,
    private sanitizer: DomSanitizer,
    private progressService: ProgressIndicatorService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    if (typeof this.activatedRoute.snapshot.queryParams['version'] !== 'undefined') {
      this.subs[1] = this.activatedRoute.queryParams.subscribe((param: any) => {
        if (typeof param['version'] !== 'undefined') {
          this.adVersion = 'v' + param['version'];
        } else {
          this.adVersion = 'v3';
        }
      });
    }
    const that = this;
    const observers: any[] = [];
    that.progressService.showBar();

    observers.push(that.helpService.getPluginsHelp());
    observers.push(that.helpService.getToolsHelp());

    that.subs[0] = forkJoin(observers).subscribe(
      (results) => {
        const converter = new showdown.Converter();
        converter.setOption('tables', true);
        let tempDataV2 = '';
        let tempDataV3 = '';

        _.each(results[0]['data']['v2'], (plugin, index) => {
          tempDataV2 += converter.makeHtml(
            '<div class="document-item" markdown="1">' + plugin + '</div>'
          );
          that.links['v2'].push(index);
        });

        _.each(results[1]['data'], (plugin, index) => {
          tempDataV3 += converter.makeHtml(
            '<div class="document-item" markdown="1">' + plugin + '</div>'
          );
          that.links['v3'].push(index);
        });

        that.data['v2'] = that.sanitizer.bypassSecurityTrustHtml(tempDataV2);
        that.data['v3'] = that.sanitizer.bypassSecurityTrustHtml(tempDataV3);
      },
      // on error
      () => {},
      // on request complete
      () => {
        that.progressService.hideBar();
      }
    );

    window.onscroll = function () {
      that.scrollFunction();
    };
  }

  /**
   * Enables/disables scrolling
   */
  scrollFunction() {
    if (document.body.scrollTop > 600 || document.documentElement.scrollTop > 600) {
      document.getElementById('go2Top').style.display = 'block';
    } else {
      document.getElementById('go2Top').style.display = 'none';
    }
  }

  /**
   * Returns to top
   */
  go2Top() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  /**
   * Scrolls to anchor
   * @param link
   */
  scrollTo(link: string) {
    try {
      document.querySelector('#' + link).scrollIntoView();
    } catch (e) {}
  }

  /**
   * On destroy
   */
  ngOnDestroy() {
    _.each(this.subs, (s) => {
      if (!_.isUndefined(s)) {
        s.unsubscribe();
      }
    });
  }
}
