<div class="privacy-policy">
    <header class="flex-set flex--content-center flex--align-items-center">
        <h2>Privacy Policy</h2>
    </header>
    <section class="section section-intro">
        <h3>
            <p>
                EVOLVE PRIVACY POLICY This privacy policy was last modified on September 2, 2014
                (the “Effective Date”). Evolve Media, LLC (the “Company”), the owner and operator of
                the website www.evolvemediallc.com (the “Site”), takes your privacy very seriously.
                This privacy policy is intended to inform you about the information that is
                collected from you when you visit […]
            </p>
        </h3>
    </section>
    <section class="section section-policy">
        <h2>EVOLVE PRIVACY POLICY</h2>
        <p>This privacy policy was last modified on September 2, 2014 (the “Effective Date”).</p>
        <p>
            Evolve Media, LLC (the “Company”), the owner and operator of the website
            www.evolvemediallc.com (the “Site”), takes your privacy very seriously. This privacy
            policy is intended to inform you about the information that is collected from you when
            you visit the Site; how this information may be used and disclosed; how you can control
            the use and disclosure of your information; and how your information is protected. If
            you have any questions about this privacy policy, please contact our legal team at
            <a href="mailto:legal@evolvemediallc.com?subject=Hi!">legal@evolvemediallc.com</a>
        </p>
    </section>
    <section class="section section-info">
        <ol>
            <li>
                <a href="http://www.evolvemediallc.com/evolve/privacy-policy#scope" target="_blank"
                    >Scope</a
                >
            </li>
            <li>
                <a
                    href="http://www.evolvemediallc.com/evolve/privacy-policy#what-information"
                    target="_blank"
                    >What information the Site collects</a
                >
            </li>
            <li>
                <a
                    href="http://www.evolvemediallc.com/evolve/privacy-policy#how-we-use"
                    target="_blank"
                    >How we use the information that we collect</a
                >
            </li>
            <li>
                <a
                    href="http://www.evolvemediallc.com/evolve/privacy-policy#information-sharing"
                    target="_blank"
                    >Information sharing</a
                >
            </li>
            <li>
                <a
                    href="http://www.evolvemediallc.com/evolve/privacy-policy#your-choices"
                    target="_blank"
                    >Your choices</a
                >
            </li>
            <li>
                <a href="http://www.evolvemediallc.com/evolve/privacy-policy#access" target="_blank"
                    >Access</a
                >
            </li>
            <li>
                <a
                    href="http://www.evolvemediallc.com/evolve/privacy-policy#confidentiality-and-security"
                    target="_blank"
                    >Confidentiality and security</a
                >
            </li>
            <li>
                <a
                    href="http://www.evolvemediallc.com/evolve/privacy-policy#visitors"
                    target="_blank"
                    >Visitors from outside of the United States</a
                >
            </li>
            <li>
                <a
                    href="http://www.evolvemediallc.com/evolve/privacy-policy#under-13"
                    target="_blank"
                    >Children under 13</a
                >
            </li>
            <li>
                <a
                    href="http://www.evolvemediallc.com/evolve/privacy-policy#changes"
                    target="_blank"
                    >Changes to the privacy policy</a
                >
            </li>
            <li>
                <a
                    href="http://www.evolvemediallc.com/evolve/privacy-policy#questions"
                    target="_blank"
                    >Questions and suggestions</a
                >
            </li>
        </ol>
    </section>
    <section class="section section-scope">
        <strong><p>1. Scope</p></strong>
        <p>
            This privacy policy applies only to the Site. This policy does not apply to information
            collected through any other website or to the practices of companies that the Company
            does not control. Please note that the Site may contain links to other websites. For
            example, if you click on an advertisement on the Site and link to another website, then
            this privacy policy will not apply to any information collected on that website. We are
            not responsible for the privacy practices of other websites, and we recommend that you
            read the privacy policies of each website that you visit.
        </p>
    </section>
    <section class="section section-info-collect">
        <strong><p>2. What information the Site collects</p></strong>
        <p class="disclaimer">Information you provide directly to us:</p>
        <p>
            You are not required to provide information directly to us in order to view the Site.
            However, when you use certain Site functions, such as when you register for certain
            services, access certain content or features, or directly contact the Site, we may ask
            you to provide information, including:
        </p>
        <ul>
            <li>contact information, such as name and email address;</li>
            <li>username and password;</li>
            <li>
                information posted in community discussions and other interactive online features;
            </li>
            <li>search queries conducted on the Site; and</li>
            <li>correspondence you send to us.</li>
        </ul>
        <p class="disclaimer">
            Information automatically collected when you visit and interact with the Site:
        </p>
        <p>
            When you visit and interact with the Site, certain information may be collected
            automatically, including:
        </p>
        <ul>
            <li>your computer’s Internet Protocol (IP) address;</li>
            <li>your browser type and operating system;</li>
            <li>
                the web pages you were visiting immediately before and after you came to the Site;
            </li>
            <li>activities within community discussions;</li>
            <li>
                web pages and advertisements that you view, and links that you click on, within the
                Site;
            </li>
            <li>
                your bandwidth speed and information about the software programs that are installed
                on your computer;
            </li>
            <li>aggregated data about email click-through rates and user video viewing;</li>
            <li>standard server log information; and</li>
            <li>
                information collected through HTML cookies, Flash cookies, web beacons, and similar
                technologies. For more information, please see our discussion of
                <a
                    href="http://www.evolvemediallc.com/terms-of-service/cookies-and-tracking"
                    target="_blank"
                >
                    cookies and other tracking technologies </a
                >.
            </li>
        </ul>
        <p class="disclaimer">Information we collect from other sources:</p>
        <p>
            We may access information about you from third-party sources and platforms (such as
            Google Analytics Demographics and Interest Reporting, social networking sites,
            databases, online marketing firms, and ad targeting firms), including:
        </p>
        <ul>
            <li>
                if you access third-party social networking services (such as Facebook Connect or
                Twitter) through the Site, your username and connection lists for those services;
            </li>
            <li>demographic data, such as age range, gender, and interests;</li>
            <li>
                advertisement interaction and viewing data, such as ad click-through rates and
                information about how many times you viewed a particular ad; and
            </li>
            <li>
                unique identifiers, including mobile device identification numbers, that can
                identify the physical location of such devices in accordance with applicable law.
            </li>
        </ul>
        <p>
            Please note that the Site may combine the information that we collect with information
            we obtain from third-party sources.
        </p>
    </section>
    <section class="section section-info-usage">
        <strong><p>3. How we use the information that we collect</p></strong>
        <p class="disclaimer">Email Communications:</p>
        <p>
            We may use the information that we collect to send you e-mail communications, such as
            information about your account or changes to the Site, promotional messages about our
            own or our marketing partners’ products and services, and our email newsletters.
        </p>
        <p class="disclaimer">Advertising:</p>
        <p class="spacer-bottom">
            In order to provide our readers with free access to our content, we display
            advertisements on the Site, many of which are targeted based on information about you.
            For example, using information collected through cookies, web beacons, and other
            sources, we may use demographic data or information about your online activities or
            interests to display targeted advertising that may be relevant to your preferences.
            Through this process, advertisers reach Site visitors who are most interested in their
            products, and you see advertising for products or services in which you may be
            interested. For more information on how cookies and other technologies work, please see
            our discussion of
            <a
                href="http://www.evolvemediallc.com/terms-of-service/cookies-and-tracking"
                target="_blank"
            >
                cookies and other tracking technologies.
            </a>
        </p>
        <p>
            Third-party advertisers and advertising platforms also may serve targeted ads on the
            Site. For more information, please see our section on
            <a
                href="http://www.evolvemediallc.com/terms-of-service/third-party-advertising"
                target="_blank"
            >
                third-party online advertising.
            </a>
            Please remember that the information practices of third-party advertisers or platforms
            collecting data on our Site are not covered by this privacy policy.
        </p>
        <p class="disclaimer">User Accounts:</p>
        <p>
            To provide you with an open, but also civil, discussion forum, we track user involvement
            in our community discussions. For example, our forum moderators monitor discussions and
            delete comments that we deem inappropriate in our forums. For more information on this
            process, please see the Site’s community rules, forum rules or comment policies about
            comment moderation guidelines.
        </p>
        <p class="disclaimer">Request Fulfillment:</p>
        <p>
            We may use the information that we collect to fulfill your requests for products,
            services, and information. For example, we may use your contact information to respond
            to your customer service requests or to enable you to participate in features on the
            Site such as surveys, polls, sweepstakes, and message boards.
        </p>
        <p class="disclaimer">Statistical Analysis:</p>
        <p>
            In order to learn more about how our Site is used, we aggregate and analyze the data
            that we collect. We may use this information, for example, to monitor and analyze use of
            the Site, to increase our Site’s functionality, and to better tailor our content and
            design to suit our visitors’ needs.
        </p>
        <p class="disclaimer">Enforcement:</p>
        <p class="spacer-bottom">
            We may use the information that we collect to prevent illegal activities, to enforce the
            Site’s
            <a href="http://www.evolvemediallc.com/terms-of-service" target="_blank">
                Terms and Conditions
            </a>
            , and to otherwise protect our rights and the rights of our users.
        </p>
        <p>
            By using the Site, you consent to the uses identified above and any other use of
            information identified in this privacy policy. In addition, we may use the information
            that we collect for any other purposes disclosed to you at the time we collect your
            information or pursuant to your consent.
        </p>
    </section>
    <section class="section section-info-sharing">
        <strong><p>4. Information sharing</p></strong>
        <p>
            The Company values your privacy, and shares information about our users only under
            certain circumstances. We will make information about you available to other companies,
            applications, or people in the circumstances listed below:
        </p>
        <ul>
            <li>
                We may share aggregated information or information that does not directly identify
                you with third parties to help us develop content, services, and advertising that we
                hope you will find of interest. Please note that we do not share contact information
                with third parties that advertise on the Site unless you opt-in or otherwise agree
                to have such information shared with or otherwise disclosed to a third party for
                marketing purposes.
            </li>
            <li>
                We may employ third parties to perform Site-related services, including database
                management, maintenance services, analytics, marketing, data processing, and email
                and text message distribution. These third parties have access to your information
                only to perform these tasks on our behalf.
            </li>
            <li>
                If you choose to engage in public activities on the site, such as posting comments
                on community message boards, any information you submit can be read, collected, or
                used by others. Please exercise caution when deciding to disclose any personal
                information in public activities or submissions.
            </li>
            <li>
                We may share information about you in the event that the Site is acquired by or
                merged with another company or a similar corporate transaction takes place.
            </li>
            <li>
                We may share information about you to investigate, prevent, or take action regarding
                illegal activities, suspected fraud, situations involving potential threats to the
                physical safety or other rights and interests of any person, violations of the
                Site’s
                <a href="http://www.evolvemediallc.com/terms-of-service" target="_blank">
                    Terms and Conditions
                </a>
                , or as otherwise required by law.
            </li>
            <li>
                We may share information about you to respond to subpoenas, search warrants,
                judicial proceedings, court orders, legal process, or other law enforcement
                measures, to establish or exercise our legal rights, or to defend against legal
                claims.
            </li>
            <li>
                In addition to the scenarios identified above, we may share information about you
                for any other purposes disclosed to you at the time we collect your information or
                pursuant to your consent.
            </li>
        </ul>
        <p>
            Once we share your information with another party, the information received by that
            party becomes subject to the other party’s privacy policies. Please note that third
            parties may independently collect data about you, including your IP address and
            information about the websites you visit and the links you click, through cookies,
            clicks on links, or other means when you visit or view ads on the Site. For more
            information, see our section on
            <a
                href="http://www.evolvemediallc.com/terms-of-service/cookies-and-tracking"
                target="_blank"
            >
                cookies and other tracking technologies
            </a>
            and
            <a
                href="http://www.evolvemediallc.com/terms-of-service/third-party-advertising"
                target="_blank"
            >
                third-party online advertising
            </a>
            .
        </p>
    </section>
    <section class="section-choices">
        <strong><p>5. Your choices</p></strong>
        <p class="disclaimer">Deactivating your account</p>
        <p>
            If the Site functionality permits, you can deactivate your account at any time by
            visiting the preferences page for your profile or delete any content you provide from
            the Site, though we may retain a copy of any deactivated accounts or deleted content for
            compliance purposes. When you deactivate your account, your user profile will be
            disabled, but your public comments will remain on the site. The Site has no
            responsibility to take down, remove, or edit any of your public activities or any
            submissions that are a result of your public activities. For more information, please
            review our
            <a href="http://www.evolvemediallc.com/terms-of-service" target="_blank">
                Terms and Conditions
            </a>
            .
        </p>
        <p class="disclaimer">Cookies and other tracking technologies</p>
        <p>
            As discussed in the sections on
            <a
                target="_blank"
                href="http://www.evolvemediallc.com/terms-of-service/cookies-and-tracking"
            >
                cookies and other tracking technologies
            </a>
            and
            <a
                target="_blank"
                href="http://www.evolvemediallc.com/terms-of-service/third-party-advertising"
            >
                third-party online advertising
            </a>
            , some information is collected about you automatically through cookies and other
            tracking technologies.
        </p>
        <ul>
            <li>
                <i>Cookies. </i>
                You can change your browser settings to reject all cookies, accept only certain
                cookies, or notify you when a cookie is set. Please note that you may need to renew
                these settings if you delete your cookies after these preferences are made. These
                settings also may not apply if you are using a different computer or Internet
                browser. To opt out of having information collected by third party ad networks,
                advertising agencies, advertisers, and audience segment providers used for
                behavioral advertising, to obtain information about the technologies these third
                parties might use, and to view information regarding their privacy policies, please
                visit
                <a href="http://www.aboutads.info/choices" target="_blnak">
                    http://www.aboutads.info/choices
                </a>
                if you are located in the United States,
                <a href="http://www.youronlinechoices.com/" target="_blank">
                    www.youronlinechoices.com
                </a>
                if you are located in Europe,
                <a href="http://youradchoices.ca/choices" target="_blank">
                    youradchoices.ca/choices
                </a>
                if you are located in Canada, or
                <a href="http://www.youronlinechoices.com.au/" target="_blank">
                    www.youronlinechoices.com.au
                </a>
                if you are located in Australia.
            </li>
            <li>
                <i>Flash Cookies</i>. You can control how Flash cookies are used by visiting the
                Adobe Macromedia site, located
                <a
                    href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
                    target="_blank"
                >
                    here </a
                >. Adobe enables you to allow some Flash cookies from certain websites, limit Flash
                cookies’ storage capacity, and disallow Flash cookies altogether. In addition, if
                you use Mozilla Firefox for your Internet browser, you can download the
                <a
                    href="https://addons.mozilla.org/en-US/firefox/addon/betterprivacy/"
                    target="_blank"
                >
                    BetterPrivacy add-on
                </a>
                to automatically delete your Flash cookies every time you close your browser.
            </li>
        </ul>
        <p>
            If you opt out of these technologies, you will continue to see advertising displayed on
            the Site, but the advertising may not be targeted to your interests. Please remember
            that we do not have access to, or control over, advertisers’ or service providers’
            cookies, and the information practices of third parties are not covered by this privacy
            policy. For more information on the use of cookies in online advertising, please see the
            section on
            <a
                target="_blank"
                href="http://www.evolvemediallc.com/terms-of-service/third-party-advertising"
            >
                third-party online advertising
            </a>
            and visit the
            <a target="_blank" href="http://www.networkadvertising.org/choices/">
                NAI’s website.
            </a>
        </p>
        <p class="disclaimer">Do Not Track</p>
        <p>
            We do not currently take actions to respond to Do Not Track signals because a uniform
            technological standard has not yet been developed. We continue to review new
            technologies and may adopt a standard once one is created. To learn about Do Not Track,
            please click
            <a target="_blank" href="http://www.allaboutdnt.com/"> here. </a>
        </p>
        <p class="disclaimer">California Residents</p>
        <p>
            If you are a California resident, you may request information about our practices
            related to disclosing your personal information to certain third parties and/or opt-out
            of our sharing of your personal information with unaffiliated third parties for the
            third parties’ direct marketing purposes. Please send your request (along with your full
            name, e-mail address, postal address, and the subject line labeled “Your California
            Privacy Rights”) by e-mail to
            <a
                target="_blank"
                href="mailto:legal@evolvemediallc.com?subject=Your%20California%20Privacy%20Rights"
            >
                legal@evolvemediallc.com </a
            >.
        </p>
    </section>
    <section class="section section-access">
        <strong><p>6. Access</p></strong>
        <p>
            If you choose to share personal information with us, you may update or amend this
            information by contacting us at
            <a target="_blank" href="mailto:legal@evolvemediallc.com?subject=Hi!">
                legal@evolvemediallc.com.
            </a>
            Please include in your email the name of the Site to which you provided the information
            you wish to amend.
        </p>
    </section>
    <section class="section section-confidential">
        <strong><p>7. Confidentiality and security</p></strong>
        <p class="spacer-bottom">
            We limit access to contact information about you to employees or service providers who
            we believe reasonably need to come into contact with that information to provide
            products or services to you or in order to do their jobs. We also have adopted
            commercially reasonable technical, physical, and administrative procedures to help
            protect information about you from loss, misuse, and alteration.
        </p>
        <p>
            Please note that no data transmission or storage can be guaranteed to be 100% secure. We
            want you to feel confident using the Site, but we cannot ensure or warrant the security
            of any information you transmit to us.
        </p>
    </section>
    <section class="section section-visitors">
        <strong><p>8. Visitors from outside the United States</p></strong>
        <p>
            The Site’s offices are located in the United States. If you visit the site from another
            country, please be aware that information you provide to us or that we obtain as a
            result of your use of the Site may be processed in and transferred to the United States
            and will be subject to U.S. law. U.S. privacy and data protection laws may not be
            equivalent to the laws in your country of residence. By using the Site or providing us
            with your information, you consent to the collection, transfer, storage, and processing
            of information to and in the United States.
        </p>
    </section>
    <section class="section section-children">
        <strong><p>9. Children under 13</p></strong>
        <p class="spacer-bottom">
            Protecting the privacy of young children is especially important. The Site is designed
            to be of service and interest to users ages 13 and older. If you are a minor over the
            age of 13, you may only use this Site with the permission and under the supervision of
            your parent or guardian. If you are a minor under the age of 13, you are not allowed to
            provide any personal information to the Company, on the Site or through any other
            service provided by the Company. Please do not send any information about yourself to
            us, including your name, address, telephone number, or email address.
        </p>
        <p class="spacer-bottom">
            The Company does not knowingly collect or solicit personal information from anyone under
            the age of 13 or knowingly allow such persons to register. In the event that we learn
            that we have collected personal information from a child under age 13 without
            verification of parental consent, we will delete that information as quickly as
            possible.
        </p>
        <p>
            If you believe that we might have any information from or about a child under 13, please
            contact us at
            <a target="_blank" href="mailto:legal@evolvemediallc.com?subject=Hi!">
                legal@evolvemediallc.com
            </a>
        </p>
    </section>
    <section class="section section-changes">
        <strong><p>10. Changes to the privacy policy</p></strong>
        <p>
            We reserve the right to change this policy at any time. Please check this page
            periodically for changes. Your continued use of our Site following the posting of
            changes to these terms will constitute acceptance of those changes. Information
            collected prior to the time any change is posted will be used according to the rules and
            law that applied at the time the information was collected.
        </p>
    </section>
    <section class="section section-questions">
        <strong><p>11. Questions and suggestions</p></strong>
        <p class="spacer-bottom">
            If you have questions about this privacy policy, please contact our legal team at
            <a target="_blank" href="mailto:legal@evolvemediallc.com?subject=Hi!">
                legal@evolvemediallc.com
            </a>
            , or send mail to:
        </p>
        <ul>
            <li>Evolve Media, LLC</li>
            <li>5140 W. Goldleaf Circle, Third Floor</li>
            <li>Los Angeles, CA 90056</li>
            <li>Attn: General Counsel</li>
        </ul>
    </section>
    <footer class="footer">
        <div class="logo-section">
            <div class="main-logo"></div>
        </div>
        <div class="copy-section">
            <div class="upper-copy">
                <p>
                    <a target="_blank" href="https://www.evolvemediallc.com/privacy/"
                        >PRIVACY POLICY</a
                    >
                </p>
                <span> | </span>
                <p>
                    <a target="_blank" href="https://www.evolvemediallc.com/terms/"
                        >TERMS OF SERVICE</a
                    >
                </p>
            </div>
            <div class="lower-copy">
                <p>©2019 All Rights Reserved. EVOLVE MEDIA, LLC</p>
            </div>
        </div>
    </footer>
</div>
