import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'og-p-d-reveal',
  templateUrl: './reveal.component.html'
})
export class PreviewRevealComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
