<br />
<div [formGroup]="form" class="sub-form">
    <div class="flex--row">
        <h3 class="sub-title">Overrides</h3>
    </div>
    <div class="flex--row flex-set flex--content-between mar-15-v">
        <mat-form-field class="full-width">
            <input type="text" matInput placeholder="Name" formControlName="name" />
            <mat-error *ngIf="form.get('name')?.invalid">
                {{ getControlError('name') }}
            </mat-error>
        </mat-form-field>
    </div>
</div>
