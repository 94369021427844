<div class="header-report">
    <div class="header-report__content flex-set flex--content-between flex--align-items-center">
        <h3>Metrics Help</h3>
        <mat-form-field>
            <mat-select
                [(ngModel)]="adVersion"
                (selectionChange)="adVersionChanged()"
                placeholder="Ad Version"
            >
                <mat-option value="v2">Version 2</mat-option>
                <mat-option value="v3">Version 3</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

<div class="help help-container metrics-help">
    <div id="text" class="text">
        <div class="products">
            <div class="document-item">
                <h3><a name="definitions" id="definitions"></a>Definitions</h3>
                <ul>
                    <li *ngFor="let def of definitions">
                        <strong>{{ def.id }}: </strong>
                        <span [innerHTML]="def.description"></span>
                    </li>
                </ul>
            </div>
            <div class="document-item">
                <h3>
                    <a name="events" id="events"></a>
                    Attributes & Events
                </h3>
                <table class="" mat-table [dataSource]="attrTableData" class="table-events">
                    <ng-container matColumnDef="event">
                        <th mat-header-cell *matHeaderCellDef>EVENT</th>
                        <td mat-cell *matCellDef="let element">{{ element.event }}</td>
                    </ng-container>

                    <ng-container matColumnDef="attribute">
                        <th mat-header-cell *matHeaderCellDef>ATTRIBUTE</th>
                        <td mat-cell *matCellDef="let element">{{ element.attribute }}</td>
                    </ng-container>

                    <ng-container matColumnDef="label">
                        <th mat-header-cell *matHeaderCellDef>LABEL</th>
                        <td mat-cell *matCellDef="let element">{{ element.label }}</td>
                    </ng-container>

                    <ng-container matColumnDef="click">
                        <th mat-header-cell *matHeaderCellDef>CLICK</th>
                        <td mat-cell *matCellDef="let element">
                            <i *ngIf="element.click" class="fa fa-check" aria-hidden="true"></i>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="int">
                        <th mat-header-cell *matHeaderCellDef>INT.</th>
                        <td mat-cell *matCellDef="let element">
                            <i
                                *ngIf="element.int"
                                [matTooltip]="
                                    element.int === -1 ? 'Interaction only for Martini units' : null
                                "
                                [ngClass]="{
                                    'fa fa-check interaction-icon': true,
                                    'is-red': element.int === -1
                                }"
                                aria-hidden="true"
                            ></i>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsEvents"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsEvents"></tr>
                </table>
            </div>
        </div>
    </div>
</div>

<button (click)="go2Top()" id="go2Top" class="back-to-top" title="Go to top">
    <span class="fa fa-arrow-up"></span><span> Go to top</span>
</button>

<link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css"
/>
